import React, {useState, useEffect} from 'react';
import '../App.css';
import $ from 'jquery';
import ResponsibilitiesImage from '../images/responsibilities.svg';
import SkillsImage from '../images/skills.svg';
import { Tabs, TextLabel, StackingLayout, FlexLayout, Title, Paragraph,
FlexItem, UnorderedList } from '@nutanix-ui/prism-reactjs';

import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';


const SkillTabs = (props) =>   {

  const[activeTab, setActiveTab] = useState(props.activekey);


  useEffect(() => {
    setActiveTab(props.activekey);
    if( props.activekey === 3) {
       document.getElementsByClassName('mobileskillsTab')[0].scrollBy(200, 0);
     } else if( props.activekey === 4) {
       document.getElementsByClassName('mobileskillsTab')[0].scrollBy(300, 0);
     } else if(props.activekey === 5) {
       document.getElementsByClassName('mobileskillsTab')[0].scrollBy(400, 0);
     } else if(props.activekey === 6) {
       document.getElementsByClassName('mobileskillsTab')[0].scrollBy(500, 0);
     }
  }, [props.activekey]);




    const uxResp = [
      <span className="sectionDescription" style={{color: '#FFFFFF'}}>Develop designs from early concepts to prototypes</span>,
      <span className="sectionDescription" style={{color: '#FFFFFF'}}>Applying design thinking to deliver end-to-end UX solutions with a focus on user needs and business goals</span>,
      <span className="sectionDescription" style={{color: '#FFFFFF'}}>Develop functional prototypes using the latest UX tools</span>,
      <span className="sectionDescription" style={{color: '#FFFFFF'}}>Work with UI development and backend engineering teams to ensure the effective execution of designs</span>
    ];
    const uxSkills = [
      <span className="sectionDescription" style={{color: '#FFFFFF'}}>Ability to identify and solve design problems</span>,
      <span className="sectionDescription" style={{color: '#FFFFFF'}}>Proficiency with UX design processes and methodologies</span>,
      <span className="sectionDescription" style={{color: '#FFFFFF'}}>Strong working knowledge of the latest wireframing or design and prototyping tools</span>,
      <span className="sectionDescription" style={{color: '#FFFFFF'}}>Strong presentation and communication skills for cross-team collaboration</span>
    ];

    const vdResp = [
      <span className="sectionDescription" style={{color: '#FFFFFF'}}>Create, maintain, and evolve visual design guidelines across Nutanix products</span>,
      <span className="sectionDescription" style={{color: '#FFFFFF'}}>Create functional components that echo Nutanix’s design philosophy through iconography, typography, color, motion, and illustration</span>,
      <span className="sectionDescription" style={{color: '#FFFFFF'}}>Partner with product designers to define the visual design strategy for the product, as well as work with other leads and cross-functional partners to drive consensus</span>,
      <span className="sectionDescription" style={{color: '#FFFFFF'}}>Drive product quality audits to ensure pixel-perfect implementation</span>
   ];
   const vdSkills = [
     <span className="sectionDescription" style={{color: '#FFFFFF'}}>Experience working with vector-based motion design and illustration tools</span>,
     <span className="sectionDescription" style={{color: '#FFFFFF'}}>Strong communication skills for collaboration with UX designers, developers, and Product Managers</span>,
     <span className="sectionDescription" style={{color: '#FFFFFF'}}>Experience working with a design system, from defining standards to maintaining and driving its consumption across teams</span>
   ];
    const researchResp = [
      <span className="sectionDescription" style={{color: '#FFFFFF'}}>Communicate and demonstrate the value of user research</span>,
      <span className="sectionDescription" style={{color: '#FFFFFF'}}>Define the product direction with research insights</span>,
      <span className="sectionDescription" style={{color: '#FFFFFF'}}>Define research protocols and plans for our product portfolio</span>,
      <span className="sectionDescription" style={{color: '#FFFFFF'}}>Challenge the status quo with attention to detail</span>
    ];

     const researchSkills = [
       <span className="sectionDescription" style={{color: '#FFFFFF'}}>Able to work effectively in a dynamic environment, juggle multiple projects, and prioritize</span>,
       <span className="sectionDescription" style={{color: '#FFFFFF'}}>Stay current on research trends and tools</span>,
       <span className="sectionDescription" style={{color: '#FFFFFF'}}>Have strong presentation and communication skills</span>,
       <span className="sectionDescription" style={{color: '#FFFFFF'}}>Ability to explain the rationale for design decisions within a larger context</span>
     ];
     const contentResp = [
       <span className="sectionDescription" style={{color: '#FFFFFF'}}>Influence all in-product UX copy</span>,
       <span className="sectionDescription" style={{color: '#FFFFFF'}}>Ensure thorough, consistent information usage</span>,
       <span className="sectionDescription" style={{color: '#FFFFFF'}}>Define and realize the content strategy across the product portfolio</span>
     ];
     const contentSkills = [
       <span className="sectionDescription" style={{color: '#FFFFFF'}}>Expertise with professional writing and publishing tools and techniques</span>,
       <span className="sectionDescription" style={{color: '#FFFFFF'}}>Able to educate stakeholders on content-design best practices</span>,
       <span className="sectionDescription" style={{color: '#FFFFFF'}}>Ability to communicate information design principles to all levels of an organization</span>,
       <span className="sectionDescription" style={{color: '#FFFFFF'}}>Ability to translate brand elements into UI copy, documentation, and product messaging</span>
     ];
     const engineerResp = [
       <span className="sectionDescription" style={{color: '#FFFFFF'}}>Front-end development and creation of prototypes</span>,
       <span className="sectionDescription" style={{color: '#FFFFFF'}}>Test design concepts</span>,
       <span className="sectionDescription" style={{color: '#FFFFFF'}}>Build and maintain platforms to expose prototypes both internally and externally</span>,
       <span className="sectionDescription" style={{color: '#FFFFFF'}}>Produce and maintain React component libraries according to the Design System</span>
     ];
     const engineerSkills = [
       <span className="sectionDescription" style={{color: '#FFFFFF'}}>Familiarity with the language of basic UX guidelines, as well as the technical requirements to build a digital product</span>,
       <span className="sectionDescription" style={{color: '#FFFFFF'}}>Should have a deep understanding of programming languages and libraries, frameworks, package managers, version control, debugging, and testing</span>,
       <span className="sectionDescription" style={{color: '#FFFFFF'}}>Ability to improve accessibility in the UI using Semantic & Accessibility Standards</span>,
       <span className="sectionDescription" style={{color: '#FFFFFF'}}>Should have a high-level understanding of User Research, Concept Design, Usability tests, and User journey analysis</span>
     ];
     const operationsResp = [
       <span className="sectionDescription" style={{color: '#FFFFFF'}}>Facilitating and creating new processes for design execution</span>,
       <span className="sectionDescription" style={{color: '#FFFFFF'}}>Facilitating team building and culture</span>,
       <span className="sectionDescription" style={{color: '#FFFFFF'}}>Budget and resource planning</span>,
       <span className="sectionDescription" style={{color: '#FFFFFF'}}>Resource planning and information management</span>
     ];
     const operationSkills = [
       <span className="sectionDescription" style={{color: '#FFFFFF'}}>Good people skills and empathy</span>,
       <span className="sectionDescription" style={{color: '#FFFFFF'}}>Ability to manage time well</span>,
       <span className="sectionDescription" style={{color: '#FFFFFF'}}>Clarity of thought and intent</span>,
       <span className="sectionDescription" style={{color: '#FFFFFF'}}>Good written communication skills</span>,
       <span className="sectionDescription" style={{color: '#FFFFFF'}}>Able to gather data in a lightweight manner and translate them into operational insights</span>
     ];



    const data = [
      {
        title: 'User Experience',
        key: 1
      },
      {
        title: 'Visual Design',
        key: 2
      },
      {
        title: 'Research',
        key: 3
      },
      {
        title: 'Content Design',
        key: 4
      },
      {
        title: 'UX Engineering',
        key: 5
      },
      {
        title: 'Design Ops',
        key: 6
      }
    ];




  function renderTitle() {
     switch(activeTab) {
       case 1: return 'UX Designer';
       break;
       case 2: return 'Visual Designer';
       break;
       case 3: return 'UX Researcher';
       break;
       case 4: return 'Content Strategist';
       break;
       case 5: return 'UX Engineer';
       break;
       case 6: return 'Design Operations Manager';
       break;
     }
  }
  function renderFirstSection() {
     switch(activeTab) {
       case 1: return 'The User Experience designer is responsible for designing and improving the overall experience of Nutanix’s 20+ product portfolio. UX designers work closely with Product Managers and development teams to bring concepts to life and often define the direction of the product.';
       break;
       case 2: return 'The Visual Designer is responsible for progressing Nutanix’s visual language across the product portfolio. They have extensive knowledge and experience in visual design, with a strong track record in a multi-disciplinary design environment.';
       break;
       case 3: return 'User Experience Researchers (UXR) take the ownership to lead B2B research projects by collaborating with a cross-functional team of designers, UI developers, backend engineers, sales engineers, and the marketing team.';
       break;
       case 4: return 'The content strategist works closely with the product design team and uses human-focused design methodologies to determine how best to deliver the right information -when, where, and how customers want it.';
       break;
       case 5: return 'A UX engineer drives innovative user experiences across our portfolio and is as comfortable with UX Designers as with UI Engineers. They mainly work at the intersection of design and technology.';
       break;
       case 6: return 'A Design Operations Manager functions in a high-impact cross-functional role, and is the operational heartbeat of the entire team. They handle the tracking and synthesis of execution across various projects globally.';
       break;
     }
  }
  function renderSecondSection() {
     switch(activeTab) {
       case 1: return 'They are very enthusiastic about complex problem-solving.';
       break;
       case 2: return 'Visual designers are encouraged to innovate and think outside of the box—the ultimate aim of the above endeavors is to bring a delightful experience to our products.';
       break;
       case 3: return 'Aligning themselves with the product and business goals, the UXRs conduct generative and evaluative research and actively communicate actionable insights that enhance our enterprise users experience and define our products future roadmap. ';
       break;
       case 4: return 'They influence all in-product UX copy, terminology, voice/tone, and product design strategy';
       break;
       case 5: return 'In addition to exploring and testing new design concepts, they also use their technical expertise to assist engineers in optimizing the implementation of those design concepts and components.';
       break;
       case 6: return 'They also coordinate with various release managers and other stakeholders to ensure high-quality design delivery.';
       break;
     }
  }
  function renderResponsibilities() {
    let skills;

    switch(activeTab) {
      case 1: return uxResp;
      break;
      case 2: return vdResp;
      break;
      case 3: return researchResp;
      break;
      case 4: return contentResp;
      break;
      case 5: return engineerResp;
      break;
      case 6: return operationsResp;
      break;

    }

  }

  function renderSkillSet() {
    let skills;

    switch(activeTab) {
      case 1: return uxSkills;
      break;
      case 2: return vdSkills;
      break;
      case 3: return researchSkills;
      break;
      case 4: return contentSkills;
      break;
      case 5: return engineerSkills;
      break;
      case 6: return operationSkills;
      break;

    }

  }

  function renderTabContent() {
    return (
      <FlexLayout flexDirection="column">
          <Title className="darkSectionTitle" style={{marginTop: '0px'}}>{renderTitle()}</Title>
          <Paragraph className="darkSectionDescription">{ renderFirstSection()}</Paragraph>
          <Paragraph className="darkSectionDescription">{ renderSecondSection()}</Paragraph>
          <Title className="darkSectionDescriptionBoldLarge" style={{color: '#FFFFFF'}}>What do we do?</Title>
          <Paragraph className="darkSectionDescription">The product building journey is a multi-stakeholder path, where we step in at multiple-points.</Paragraph>


          </FlexLayout>

     );

  }

  function handleTabClick(tabKey) {
    document.getElementsByClassName('mobileSkillsContainer')[0].scrollTo(0, 0);
    setActiveTab(tabKey);
  }






    return (
      <StackingLayout>
        <Tabs className="mobileskillsTab"
          style={{overflow: 'scroll'}}
          data={ data }
          onTabClick={ handleTabClick }
          activeKey={ activeTab }
          fullWidth={true}/>
        <FlexLayout style={{padding: '100px 16px 10px'}}>
          { renderTabContent() }
        </FlexLayout>


        <FlexLayout flexDirection="column" style={{padding: '0px 16px 70px'}}>
          <FlexLayout alignItems="center" itemSpacing="2px">
            <img src={ResponsibilitiesImage} />
            <Title className="darkSectionDescriptionBoldLarge" style={{color: '#AC9BFD'}}>Responsibilities Include</Title>
          </FlexLayout>
          <UnorderedList className="skillsList"  data={ renderSkillSet() } data-test-id="basic-usage" />
          <FlexLayout alignItems="center">
              <img src={SkillsImage} />
            <Title className="darkSectionDescriptionBoldLarge" style={{color: '#AC9BFD'}}>Complementary Skills</Title>
          </FlexLayout>
          <UnorderedList className="skillsList"  data={ renderSkillSet() } data-test-id="basic-usage" />
        </FlexLayout>
      </StackingLayout>
    );


}
export default SkillTabs;
