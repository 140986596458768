import React, {useRef, useEffect, useState} from 'react';
import './App.css';
import JoinUsImage from './images/joinUs.svg';

import { NavLink, withRouter, useHistory } from "react-router-dom";
import {
  Button,
  FlexItem,
  Title,
  StackingLayout,
  FlexLayout,
  Paragraph
} from '@nutanix-ui/prism-reactjs';


export default function JoinUs(){


const history = useHistory();

const redirectToCareers = (tabName) => {
    history.push(`/careers`);
  };

return (
  <StackingLayout>
  <FlexLayout className="joinUs" alignItems="center" itemSpacing="80px">
   <FlexLayout flexDirection="column" itemSpacing="40px">
   <Title size="h2" className="sectionTitle">Join the crew</Title>
   <Paragraph className="sectionDescription" style={{width: '80%'}}>Our ever-growing team is always on the lookout for fellow problem solvers and lovers of the craft.</Paragraph>
   <Button style={{alignSelf: 'flex-start', zIndex: '5000'}} className="linkButton" id="joinUsExploreButton" onClick={redirectToCareers}>Explore Open Roles <div className="buttonArrow"></div></Button>

   </FlexLayout>
    <img src={JoinUsImage}  style={{height: '400px'}}/>

   </FlexLayout>
   </StackingLayout>
);



}
