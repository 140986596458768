import React, {useRef, useEffect} from 'react';
import '../App.css';
import lottie from 'lottie-web';
import '@lottiefiles/lottie-player';
import {create} from '@lottiefiles/lottie-interactivity';
import ArrowImage from '../images/purpleArrow.svg';
import {
  Menu,
  MenuItem,
  Dropdown,
  Link,
  Tabs,
  Divider,
  TextLabel,
  FlexItem,
  StackingLayout,
  FlexLayout,
  NavBarLayout,
  ShareArrowIcon,
  AlertIcon,
  Paragraph,
  Badge,
  Button,
  Title,
  Progress,
  ThemeManager
} from '@nutanix-ui/prism-reactjs';

export default function OpeningEntry(props) {



  return (
    <FlexLayout flexDirection="column" itemSpacing="20px">
    <FlexLayout justifyContent="space-between" alignItems="center" style={{paddingTop: '20px'}}>
    <FlexLayout flexDirection="column" itemSpacing="2px">
       <Title size="h4" className="sectionDescriptionBold">{props.role}</Title>
       <Title size="h4" className="sectionDescriptionSmall">{props.location}</Title>
    </FlexLayout>
    <Link href={props.link} target="_blank" className="jobLinks">
    <FlexLayout itemSpacing="5px" alignItems="center" style={{alignSelf: 'flex-start'}}>
    <FlexItem className="linkExtraSmall" >View Details</FlexItem>
    {props.isMobile ? null :  <div className="openingArrow"></div>}
    </FlexLayout>
    </Link>

    </FlexLayout>
      <Divider />
    </FlexLayout>

  );
}
