import React, {useState, useEffect, useRef} from 'react';
import '../App.css';
import $ from 'jquery';
import lottie from 'lottie-web';
import juniorseniorData from '../animations/juniorSenior.json';
import juniorManagerData from '../animations/juniorManager.json';
import seniorManagerData from '../animations/seniorManager.json';
import managerSeniorData from '../animations/managerSenior.json';
import seniorJuniorData from '../animations/seniorJunior.json';
import managerJuniorData from '../animations/managerJunior.json';
import Line from '../images/line.svg';
import Connectors from '../images/Connectors.svg';
import SeniorICLadder from './SeniorICLadder';
import ManagementLadder from './ManagementLadder';

import OnHoverScrollContainer from "../careers/CustomScrollDiv";
import {
  Menu,
  MenuItem,
  Dropdown,
  Link,
  Tabs,
  Divider,
  TextLabel,
  FlexItem,
  StackingLayout,
  ChevronCutoutRightIcon,
  ChevronRightIcon,
  FlexLayout,
  NavBarLayout,
  ShareArrowIcon,
  ExternalLinkIcon,
  VerticalSeparator,
  AlertIcon,
  Paragraph,
  Badge,
  Button,
  Title,
  Progress,
  ThemeManager
} from '@nutanix-ui/prism-reactjs';

export default function CareerLadder() {

const [isseniorICExpanded, setSeniorICExpanded] = useState(false);
const [isManagementExpanded, setManagementExpanded] = useState(false);
const [activeStep, setActiveStep] = useState('Designer 1');
const [activeNode, setActiveNode] = useState(null);
let activeTab = useRef('junior');
let seniorExpanded = useRef(false);
let managementExpanded = useRef(false);
const scrollableDivRef = useRef(null);

let careerLottie = useRef(null);

const myRef = useRef(null);
const divRef = useRef(null);

const careerData = [
  {
    key: 'Designer 1',
    desc: 'You are in an early career stage as an individual contributor and are developing your general knowledge and skills.'
  },
  {
    key: 'Designer 2',
    desc: 'You can apply your knowledge, skills, and abilities to projects in a domain and are building a professional reputation within the company.'
  },
  {
    key: 'Designer 3',
    desc: 'You have a solid understanding of at least one product domain and have built a good reputation within the company in that domain. Additionally, you have the ability to execute a small set of Design projects outside your main product.'
  },
  {
    key: 'Designer 4',
    desc: 'You can demonstrate knowledge of more than one product domain and have the ability to design for highly complex briefs.'
  },
  {
    key: 'Sr.Designer',
    desc: 'You are able to demonstrate a comprehensive knowledge of multiple product domains and create user journeys that span them. In addition to executing designs that are often recognized for their innovation and creativity.'
  },
  {
    key: 'Staff Designer',
    desc: 'You are recognized as a product expert and known for your Design leadership within the product. You also have the expertise to generate customer insights and greatly influence a product roadmap.'
  },
  {
    key: 'Sr.Staff Designer',
    desc: 'You develop broad objectives for advancing Design and demonstrate the ability to identify and deliver on opportunities. You ensure Design is applied across products/functions in the company and its unique value is realized. You are also recognized for your leadership within the company.'
  },
  {
    key: 'Principal Designer',
    desc: 'You are known for major innovation and creativity and know how to define a problem and are able to lay out a multitude of possible solutions. As a Principal Designer, you work on diverse jobs and deal with highly complex issues. Your analysis guides decision-making for the product.'
  },

  {
    key: 'Design Manager',
    desc: 'You have a good understanding of various design functions within the company. You manage a small team of people and their relevant projects. Additionally, you know how to handle stakeholder expectations and cross-functional partnerships.'
  },

    {
      key: 'Sr.Design Manager',
      desc: 'You manage a team of people, and help shape the direction of the product. Your work also focuses on the culture, growth opportunities, and UX roadmaps.'
    },

      {
        key: 'Design Director',
        desc: 'You manage a large team of designers and managers. As a Design Director, you drive initiatives beyond product, align people across functions, and attract top talent to fill gaps. You have insights on cross-product journeys and can execute on a large number of threads across geos.'
      },

        {
          key: 'Sr.Design Director',
          desc: 'You manage a large team of Managers, Directors, and designers. You develop new leaders on the team to ensure leadership bench strength. You have a deep understanding of business and technical complexities and use that to guide strategic design and organizational decisions.'
        },

                {
                  key: 'Vice President',
                  desc: 'You are responsible for building, developing and leading an effective Design organization on a global scale. You influence the product strategy and ensure a clear experience direction along with an operational roadmap. You have the ability to leverage Design to create and operationalise new business opportunities and react to market shifts.'
                }


];
const handleKeyDown = (event, role, section) => {
const navItems = document.querySelectorAll(".focusable-tile");
const chevronItems = document.querySelectorAll(".focusable-chevron");
  const currentFocusIndex = Array.from(navItems).indexOf(document.activeElement);

  if (event.key === "ArrowRight") {
  event.preventDefault();
  if(!Array.from(chevronItems).find(element => element === document.activeElement)) {
  if (currentFocusIndex < navItems.length - 1) {
    let nextIndex = currentFocusIndex + 1;
    navItems[nextIndex].focus();
  }
}
} else if (event.key === "ArrowLeft") {
  event.preventDefault();

  if (currentFocusIndex > 0) {
    let prevIndex = currentFocusIndex - 1;
    navItems[prevIndex].focus();

  }

}  else if (event.key === "Tab") {
  event.preventDefault();
  if(event.shiftKey) {
    const tabButton = document.querySelector('.tabButton');
    tabButton.focus();
  } else {


   const sideQuestCards = document.querySelectorAll('.sideQuest');
   sideQuestCards[0].focus();
 }
 } else if(event.key === "ArrowDown") {
     if(Array.from(chevronItems).find(element => element === document.activeElement)) {
        event.preventDefault();
       let focusIndex = Array.from(chevronItems).indexOf(document.activeElement);
       let nextIndex = (focusIndex + 1);
       if(nextIndex < 2) {
       chevronItems[nextIndex].focus();
      }
     }
 } else if(event.key === "ArrowUp") {
     if(Array.from(chevronItems).find(element => element === document.activeElement)) {
        event.preventDefault();
       let focusIndex = Array.from(chevronItems).indexOf(document.activeElement);
       let nextIndex = (focusIndex - 1) % chevronItems.length;
       if(nextIndex > -1) {
       chevronItems[nextIndex].focus();
       }
     }
 }  else if(event.key === "Enter") {
   if(role === 'expandIC') {
      setExpanded('Senior IC');
      setTimeout(() => {
        const seniorDesignerTile = document.querySelector('#seniorDesigner');
        seniorDesignerTile.focus();
      }, 50);
   } else if(role === 'collapseIC') {
     setCollapsed('Senior IC');

     setTimeout(() => {

       const navItems = document.querySelector("#seniorICChevron");
       navItems.focus();
     }, 50);
   }  else if(role === 'expandManage') {
     setExpanded('Management');

     setTimeout(() => {

       const managerTile = document.querySelector("#manager");
       managerTile.focus();
     }, 50);
   } else if(role === 'collapseManage') {
     setCollapsed('Management');

     setTimeout(() => {

       const navItems = document.querySelector("#managementChevron");
       navItems.focus();
     }, 50);
   } else {
   setActive(event, role, section);
 }
 }
};
const handleTabPress = (event, section) => {
  const navItems = document.querySelectorAll('.tabButton');
  const currentFocusIndex = Array.from(navItems).indexOf(document.activeElement);

  if (event.key === "ArrowRight") {
  event.preventDefault();
  if (currentFocusIndex < navItems.length - 1) {
    let nextIndex = currentFocusIndex + 1;
    navItems[nextIndex].focus();
  }
} else if (event.key === "ArrowLeft") {
  event.preventDefault();
  if (currentFocusIndex > 0) {
    let prevIndex = currentFocusIndex - 1;
    navItems[prevIndex].focus();

  }
} else if (event.key === "Tab" && !event.shiftKey) {
   event.preventDefault();


    if(section === 'Junior') {
      setTimeout(() => {
        const seniorDesignerTile = document.querySelector('#designer1');
        seniorDesignerTile.focus();
      }, 50);
    } else if(section === 'Senior') {
      if(isseniorICExpanded) {
      setTimeout(() => {
        const seniorDesignerTile = document.querySelector('#seniorDesigner');
        seniorDesignerTile.focus();
      }, 50);
    } else {
      setTimeout(() => {
        const seniorDesignerTile = document.querySelector('#designer1');
        seniorDesignerTile.focus();
      }, 50);
    }
    } else if(section === 'Management') {
      if(isManagementExpanded) {
      setTimeout(() => {
        const seniorDesignerTile = document.querySelector('#manager');
        seniorDesignerTile.focus();
      }, 50);
    } else {
      setTimeout(() => {
        const seniorDesignerTile = document.querySelector('#designer1');
        seniorDesignerTile.focus();
      }, 50);
    }
    }


  }
};



useEffect(() => {
  document.getElementById('designer1').classList.add('active');
  setActiveNode(document.getElementById('designer1'));

  careerLottie.current = lottie.loadAnimation({
   container: myRef.current,
   renderer: "svg",
   loop: false,
   autoplay: false,
   animationData: juniorseniorData
 });

 const tabItems = document.querySelectorAll(".tabButton");



 tabItems.forEach((item) => {
   item.setAttribute("tabindex", "0");

 });


}, []);

useEffect(() => {
  const navItems = document.querySelectorAll(".focusable-tile");

  navItems.forEach((item) => {
    item.setAttribute("tabIndex", "0");
  });
}, [isseniorICExpanded, isManagementExpanded]);

function renderData() {
  let obj = careerData.find(o => o.key === activeStep);
  return obj.desc;

}

function setActive(e, val, tab) {
  document.getElementsByClassName('careerData')[0].classList.remove('active');

  void document.getElementsByClassName('careerData')[0].offsetWidth;
  document.getElementsByClassName('careerData')[0].classList.add('active');
  setActiveStep(val);

  let newTarget = e.target;
  if(!newTarget.classList.contains('flowChartTile')) {
    newTarget = e.target.parentElement;
  }
  newTarget.classList.add('active');

  setActiveNode((prev) => {
    if(prev && prev !== newTarget) {
      prev.classList.remove('active');
    }

    return newTarget;
  });
  showActiveTab(tab);
  setTimeout(function() {

   // Move the scroll to the expanded content
      const containerWidth = document.getElementsByClassName('careerLadder')[0].offsetWidth;
      const tileWidth = newTarget.offsetWidth;
      const tileLeft = newTarget.offsetLeft;
      const scrollLeft = tileLeft - (containerWidth - tileWidth) / 2;
      // Set the container's scrollLeft property to scroll to the focused tile
      document.getElementsByClassName('careerLadder')[0].scrollLeft = scrollLeft;
 }, 50);

}

function showActiveTab(tab) {

    if(tab === 'junior') {
      document.getElementsByClassName('juniorTab')[0].classList.add('active');
      document.getElementsByClassName('seniorTab')[0].classList.remove('active');
      document.getElementsByClassName('managementTab')[0].classList.remove('active');
      if(activeTab.current === 'senior') {

        careerLottie.current.destroy();
        careerLottie.current = lottie.loadAnimation({
         container: myRef.current,
         renderer: "svg",
         loop: false,
         autoplay: true,
         animationData: seniorJuniorData
       });

     } else if(activeTab.current === 'management') {

       careerLottie.current.destroy();
       careerLottie.current = lottie.loadAnimation({
        container: myRef.current,
        renderer: "svg",
        loop: false,
        autoplay: true,
        animationData: managerJuniorData
      });
     }
      activeTab.current = 'junior';
    } else if(tab === 'senior') {
      document.getElementsByClassName('juniorTab')[0].classList.remove('active');
      document.getElementsByClassName('seniorTab')[0].classList.add('active');
      document.getElementsByClassName('managementTab')[0].classList.remove('active');
      if(activeTab.current === 'junior') {
        careerLottie.current.destroy();
        careerLottie.current = lottie.loadAnimation({
         container: myRef.current,
         renderer: "svg",
         loop: false,
         autoplay: true,
         animationData: juniorseniorData
       });
     } else if(activeTab.current === 'management') {

       careerLottie.current.destroy();
       careerLottie.current = lottie.loadAnimation({
        container: myRef.current,
        renderer: "svg",
        loop: false,
        autoplay: true,
        animationData: managerSeniorData
      });
     }
     activeTab.current = 'senior';

    }else if(tab === 'management') {
      document.getElementsByClassName('juniorTab')[0].classList.remove('active');
      document.getElementsByClassName('seniorTab')[0].classList.remove('active');
      document.getElementsByClassName('managementTab')[0].classList.add('active');

      if(activeTab.current === 'junior') {
        careerLottie.current.destroy();
        careerLottie.current = lottie.loadAnimation({
         container: myRef.current,
         renderer: "svg",
         loop: false,
         autoplay: true,
         animationData: juniorManagerData
       });
     } else if(activeTab.current === 'senior') {
       careerLottie.current.destroy();
       careerLottie.current = lottie.loadAnimation({
        container: myRef.current,
        renderer: "svg",
        loop: false,
        autoplay: true,
        animationData: seniorManagerData
      });
     }
       activeTab.current = 'management';
    }
}

function setExpanded (val) {
  if(val === 'Senior IC') {
  setSeniorICExpanded(true);
  seniorExpanded.current = true;
  $('.seniorIC').animate({
    width: '900px'
  }, 'fast');


} else if (val === 'Management') {
  setManagementExpanded(true);
  managementExpanded.current = true;
  $('.management').animate({
    width: '1150'
  }, 'fast');
}
 document.getElementsByClassName('careerLadder')[0].classList.add('scrolling');
 setTimeout(function() {


 if(document.getElementsByClassName('careerLadder')[0].scrollLeft < 100) {
 document.getElementsByClassName('careerLadder')[0].scrollLeft = $('.seniorIC')[0].offsetLeft ;
}
}, 50);


}
function setCollapsed (val) {
  if(val === 'Senior IC') {
  if(isseniorICExpanded) {
  setSeniorICExpanded(false);

  seniorExpanded.current = false;
  $('.seniorIC').animate({
    width: '140px'
  }, 'fast');
}
} else if (val === 'Management') {
  if(isManagementExpanded) {
  setManagementExpanded(false);
  managementExpanded.current = false;
  $('.management').animate({
    width: '140px'
  }, 'fast');
}
}
 if(!seniorExpanded.current && !managementExpanded.current) {
 document.getElementsByClassName('careerLadder')[0].classList.remove('scrolling');
}
}

function changeColor(e) {
   e.target.style.background = '#333';
   if(e.target.querySelector('svg')) {
   e.target.querySelector('svg').style.color = "#FFF";
 }
}
function revertColor(e) {
   e.target.style.background = '#222';
   if(e.target.querySelector('svg')) {
   e.target.querySelector('svg').style.color = "#BCBCBD";
 }
}

function showExpanded(val) {
  if(val === 'Senior IC' || val === 'Management') {
  setExpanded(val);
  setTimeout(() => {
    if(val === 'Senior IC') {
    showActiveTab('senior');
    setCollapsed('Management');
    document.getElementById('seniorDesigner').classList.add('active');
    setActiveStep('Sr.Designer');
    setActiveNode((prev) => {
      if(prev) {
        prev.classList.remove('active');
      }

      return document.getElementById('seniorDesigner');
    });
  } else {
    setCollapsed('Senior IC');
      showActiveTab('management');
    document.getElementById('manager').classList.add('active');
    setActiveStep('Design Manager');
    setActiveNode((prev) => {
      if(prev) {
        prev.classList.remove('active');
      }

      return document.getElementById('manager');
    });
  }

}, "0");
} else {
  setCollapsed('Senior IC');
    showActiveTab('junior');
  setCollapsed('Management');
  setActiveStep('Designer 1');
  document.getElementById('designer1').classList.add('active');

  setActiveNode((prev) => {
    if(prev) {
      prev.classList.remove('active');
    }

    return document.getElementById('designer1');
  });
}
}



return (
  <FlexLayout flexDirection="column" itemSpacing="0px" style={{paddingTop: '40px'}}>
  <FlexLayout itemSpacing="40px" justifyContent="center" style={{background: '#1D1D1D', padding: '10px 40px'}}>
   <FlexLayout flexDirection="column" alignItems="center">
   <Button style={{background: '#1D1D1D', cursor:'pointer'}}  onClick={() => {showExpanded('Junior')}} className="tabButton" onKeyDown={(e) => handleTabPress(e, 'Junior')}><Title className="darkSectionDescription juniorTab active">Junior-Mid</Title></Button>
   </FlexLayout>

   <Button style={{background: '#1D1D1D', cursor:'pointer'}}  onClick={() => {showExpanded('Senior IC')}} className="tabButton" onKeyDown={(e) => handleTabPress(e, 'Senior')}><Title className="darkSectionDescription seniorTab">Senior IC</Title></Button>

   <Button style={{background: '#1D1D1D', cursor:'pointer'}}  onClick={() => {showExpanded('Management')}} className="tabButton" onKeyDown={(e) => handleTabPress(e, 'Management')}><Title className="darkSectionDescription managementTab">Management</Title></Button>
  </FlexLayout>
  <section>
  <FlexLayout itemSpacing="0px" alignItems="center" justifyContent="center" ref={scrollableDivRef} className="careerLadder" style={{ background: '#131313', padding: '60px 130px'}}>


    <FlexLayout className="flowChartTile focusable-tile" itemSpacing="10px" alignItems="center" justifyContent="center" id="designer1" onClick={(e) => setActive(e, 'Designer 1', 'junior')} onKeyDown={(e) => handleKeyDown(e, 'Designer 1', 'junior')}>

      <Title size="h3" className="sectionDescriptionBold" style={{color: '#BCBCBD', whiteSpace: 'nowrap'}}>Designer 1</Title>

    </FlexLayout>
    <img src={Line} />
    <FlexLayout className="flowChartTile focusable-tile" ref={divRef} itemSpacing="10px" alignItems="center" justifyContent="center" onClick={(e) => setActive(e, 'Designer 2', 'junior')} onKeyDown={(e) => handleKeyDown(e, 'Designer 2', 'junior')}>

      <Title size="h3" className="sectionDescriptionBold" style={{color: '#BCBCBD', whiteSpace: 'nowrap'}}>Designer 2</Title>

    </FlexLayout>
         <img src={Line} />
         <FlexLayout className="flowChartTile focusable-tile"  itemSpacing="10px" alignItems="center" justifyContent="center" onClick={(e) => setActive(e, 'Designer 3', 'junior')} onKeyDown={(e) => handleKeyDown(e, 'Designer 3', 'junior')}>

           <Title size="h3" className="sectionDescriptionBold" style={{color: '#BCBCBD', whiteSpace: 'nowrap'}}>Designer 3</Title>

         </FlexLayout>
              <img src={Line} />
              <FlexLayout className="flowChartTile focusable-tile"  itemSpacing="10px" alignItems="center" justifyContent="center" onClick={(e) => setActive(e, 'Designer 4', 'junior')} onKeyDown={(e) => handleKeyDown(e, 'Designer 4', 'junior')}>

                <Title size="h3" className="sectionDescriptionBold" style={{color: '#BCBCBD', whiteSpace: 'nowrap'}}>Designer 4</Title>

              </FlexLayout>

                 <img src={Connectors}/>

             <FlexLayout flexDirection="column">
             <FlexLayout className="flowChartSeniorTile seniorIC" itemSpacing="40px" style={{ alignSelf: 'flex-start', flexBasis: '100%'}}>

               <Title size="h3" className="sectionDescriptionBold" style={{ color: '#BCBCBD', alignSelf: 'center', marginRight: '10px',  whiteSpace: 'nowrap'}}>Senior IC</Title>
               {!isseniorICExpanded ?  <FlexItem style={{background: '#222', padding: '30px 7px', cursor: 'pointer', zIndex: '5000', marginLeft: '35px'}} onMouseEnter={(e) => changeColor(e)} onMouseLeave={(e) => revertColor(e)} onClick={() => {setExpanded('Senior IC')}} className="focusable-tile focusable-chevron" id="seniorICChevron" onKeyDown={(e) => {handleKeyDown(e, 'expandIC')}}><ChevronRightIcon style={{color: '#BCBCBD', height: '12px', width: '12px'}} /></FlexItem> : null}
               {isseniorICExpanded ? <SeniorICLadder setActive={setActive} clickHandler={setCollapsed} handleKeyDown={handleKeyDown}/> : null}
             </FlexLayout>
             <FlexLayout className="flowChartSeniorTile management" alignItems="center" style={{ alignSelf: 'flex-start',  flexBasis: '100%'}}>

               <Title size="h3" className="sectionDescriptionBold" style={{color: '#BCBCBD', alignSelf: 'center',  whiteSpace: 'nowrap'}}>Management</Title>
               {!isManagementExpanded ?  <FlexItem style={{background: '#222', padding: '30px 7px', cursor: 'pointer', marginLeft: '9px'}} onMouseEnter={(e) => changeColor(e)} onMouseLeave={(e) => revertColor(e)} onClick={() => {setExpanded('Management')}} className="focusable-tile focusable-chevron" id="managementChevron" onKeyDown={(e) => {handleKeyDown(e, 'expandManage')}}><ChevronRightIcon style={{color: '#BCBCBD',  height: '12px', width: '12px'}} className="focusable-tile"/></FlexItem> : null}
               {isManagementExpanded ? <ManagementLadder setActive={setActive} clickHandler={setCollapsed} handleKeyDown={handleKeyDown}/> : null}

             </FlexLayout>
             </FlexLayout>

  </FlexLayout>
  </section>
  <section>
   <FlexLayout justifyContent="space-between" alignItems="center" style={{background: '#FFFFFF', padding: '40px 60px'}}>
     <div ref={myRef}></div>
     <FlexLayout flexDirection="column" style={{width: '75%', height: '160px'}}>
       <Title className="sectionDescriptionMediumLarge" style={{color: '#7855FA'}}>{activeStep}</Title>

       <div className="sectionDescription careerData">{renderData()}</div>

      </FlexLayout>

   </FlexLayout>
   </section>


  </FlexLayout>
);


}
