import React from 'react';
import './Main.css';
import {
  FlexLayout,
  Paragraph,
  Title
} from '@nutanix-ui/prism-reactjs';
import HomeImage from './images/designSystemImage.svg';

export default function MobileFoundation() {




return (
  <FlexLayout flexDirection="column" itemSpacing="20px">
       <img src={HomeImage} className="fullBleedImage"/>
       <Title className="sectionTitle" style={{paddingTop: '80px'}}>Our Foundation</Title>
       <FlexLayout flexDirection="column" justifyContent="center">


      <Paragraph className="sectionDescription">Our full-stack design system helps us stay agile and iterate fast. It is our single source of truth and helps ensure quality and consistency throughout products.</Paragraph>
      <Paragraph className="sectionDescription">It includes our design principles, UI components, data-visualisation and illustration library, and content guidelines.</Paragraph>
      </FlexLayout>
  </FlexLayout>
);


}
