import React from 'react';
import './Careers.css';

import FAQQuestions from '../components/FAQQuestions';
import {
  Link,
  Divider,
  FlexItem,
  StackingLayout,
  ChevronDownIcon,
  Accordion,
  TextLabel,
  FlexLayout,
  Paragraph,
  Button,
  Title
} from '@nutanix-ui/prism-reactjs';

export default function MobileFAQ() {




return (
  <FlexLayout flexDirection="column">
    <Title className="sectionTitle"> Frequently Asked Questions</Title>
  
    <Title className="sectionDescriptionLarge" style={{ color: "#7855FA", paddingBottom: '10px' }}>General</Title>

         <FAQQuestions question="What does Nutanix do?" answer="Nutanix is a design-first hybrid cloud company. We provide an infrastructure platform on which the applications and services that power today’s increasingly digital world is built, deployed, and managed; and our job as designers is to bring consumer-grade simplicity and delight to the IT admins and developers."/>

         <Divider style={{borderColor: '#AEB1FF'}}/>

   <Title className="sectionDescriptionLarge" style={{ color: "#7855FA", paddingBottom: '10px', paddingTop: '10px' }}>Hiring</Title>

   <FAQQuestions question="What are you looking for in people?"
   answer="As a team, we look for people who are curious, humble, and able to understand complex problem areas. While hiring, we also look out for candidates who have good communication skills and are team players."/>


        <Divider style={{borderColor: '#AEB1FF'}}/>
        <FAQQuestions question="What is the hiring process?"
        answer="Our hiring process usually consists of 4 steps in which we want to learn more about you and your approach as a designer. This includes a portfolio review, a meeting with the entire team, a design task, and a meeting with the stakeholders."/>

     <Divider style={{borderColor: '#AEB1FF'}}/>
     <FAQQuestions question="What is the onboarding process?"
     answer="We onboard each new member of the team to a product. A buddy helps the Nubie get up to speed and navigate the job. Senior designers provide ample resources and additional knowledge-transfer sessions to accelerate understanding of the domain."/>


  <Divider style={{borderColor: '#AEB1FF'}}/>
    <Title className="sectionDescriptionLarge" style={{ color: "#7855FA", paddingBottom: '10px', paddingTop: '10px' }}>Career Growth</Title>
    <FAQQuestions question="What does career growth look like?"
    answer="We offer half-yearly reviews, periodical peer feedback, a well-structured career ladder, and free resources to support your growth. The environment in the Nutanix design team is quite supportive for any team member keeping in mind both personal and professional growth."/>
    <Divider style={{borderColor: '#AEB1FF'}}/>
     <FAQQuestions question="What kind of mentorship will I get?"
     answer="All new joiners and interns are guided by a mentor and a manager, who continue to support you as you grow. The design community at Nutanix is incredibly approachable, and you will receive help in understanding the domain, and getting access to relevant resources from senior designers. A buddy is also assigned to you, who helps navigate the work sphere in general. "/>
 <Divider style={{borderColor: '#AEB1FF'}}/>
 <Title className="sectionDescriptionLarge" style={{ color: "#7855FA", paddingBottom: '10px', paddingTop: '10px' }}>Geography and collaboration</Title>
 <FAQQuestions question="What is the work model?"
 answer="The company is functioning in a hybrid mode and we typically visit the office 2-3 days a week to collaborate and connect with our colleagues. "/>
 <Divider style={{borderColor: '#AEB1FF'}}/>
 <FAQQuestions question="How do you organize across geos? "
 answer="The Nutanix design team is spread across three geos: San Jose, Berlin, and Bangalore. We work on different products but brainstorm together frequently with weekly critique sessions and collaborate on different projects across teams "/>
 <Divider style={{borderColor: '#AEB1FF'}}/>
 <Title className="sectionDescriptionLarge" style={{ color: "#7855FA", paddingBottom: '10px', paddingTop: '10px' }}>Design Tools and System</Title>
 <FAQQuestions question="What tools do you use?"
 answer="As UX designers, we collectively use Figma for our wireframing, UI, and prototyping. There are also other tools in use from the Adobe suite for visual design and illustration. There is also Acrolinx, and Qualtrics depending on the role. Needless to say, our greatest asset is the mind! "/>
 <Divider style={{borderColor: '#AEB1FF'}}/>
 <FAQQuestions question="Do you work with a design system?"
 answer="Yes, we have a refined design system that is our single source of truth and helps us build high-quality design experiences. It includes different libraries and guidelines for UI, data visualization, illustration, design principles, and content. "/>
<Divider style={{borderColor: '#AEB1FF'}}/>







  </FlexLayout>
);


}
