import React, {useState} from 'react';
import '../App.css';
import Lottie from 'react-lottie';
import {
  Menu,
  MenuItem,
  Dropdown,
  Link,
  Tabs,
  Divider,
  TextLabel,
  FlexItem,
  StackingLayout,
  FlexLayout,
  NavBarLayout,
  ShareArrowIcon,
  ExternalLinkIcon,
  AlertIcon,
  Paragraph,
  Badge,
  Button,
  Title,
  Progress,
  ThemeManager
} from '@nutanix-ui/prism-reactjs';

export default function MobileSideQuestCard(props) {




return (
  <FlexLayout itemSpacing="20px" className="sideQuest">

    <img src={props.image} style={{width: '30px', alignSelf: 'center'}}/>
    <FlexLayout flexDirection="column" itemSpacing="5px">
     <Title className="sectionDescriptionBold">{props.title}</Title>

    <Paragraph className="sectionDescriptionSmall">{props.description}</Paragraph>
    </FlexLayout>
   </FlexLayout>



);


}
