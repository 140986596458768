import React from 'react';
import './App.css';
import  ArrowImage from './images/arrow.svg';
import SanJose from './images/sanJoseImage.svg';
import Bangalore from './images/bangaloreImage.svg';
import Berlin from './images/berlinImage.svg';
import { NavLink, withRouter } from "react-router-dom";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import {
  FlexLayout,
  Paragraph,
  Button,
  Title
} from '@nutanix-ui/prism-reactjs';

export default function MobileAbout() {




return (
  <FlexLayout flexDirection="column" itemSpacing="20px">
  <Carousel
     partialVisible={true}
     showDots={true}
     swipeable={true}
     draggable={true}
     ssr={true}
     keyBoardControl={true}
     itemClass="mobileTeamItems"
     containerClass="mobileTeamCarousel"
 removeArrowOnDeviceType={["tablet", "mobile"]}
 renderDotsOutside={true}
 dotListClass="articleCarouselDots"
   responsive={{
     desktop: {
       breakpoint: {
         max: 3000,
         min: 1024
       },
       items: 3,
       partialVisibilityGutter: 40
     },
     mobile: {
       breakpoint: {
         max: 464,
         min: 0
       },
       items: 1,
       partialVisibilityGutter: 60
     },
     tablet: {
       breakpoint: {
         max: 1024,
         min: 464
       },
       items: 1
     }
   }}

   deviceType="mobile"
 >
 <img src={Bangalore} style={{ width: '300px'}}/>
 <img src={Berlin} style={{ width: '300px'}}/>
 <img src={SanJose} style={{ width: '300px'}}/>
 </Carousel>
      <Title className="sectionTitle" style={{width: '80%', marginTop: '40px'}}>We are a Motley Crew</Title>
      <Paragraph className="sectionDescription"> Our growing team of 50+ designers across Bangalore, Berlin, and San José come in all shapes and sizes. We research, design UX, generate content, visualize data, illustrate, and do visual design to collaboratively craft exceptional experiences. </Paragraph>

      <Button style={{alignSelf: 'flex-start'}} className="linkButton"><NavLink to="/aboutUs">The People<img src={ArrowImage}/></NavLink></Button>





  </FlexLayout>
);


}
