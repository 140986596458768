import React, {useState, useEffect} from 'react';
import './App.css';
import  ArrowImage from './images/arrow.svg';
import  IntroImage from './images/introImage.svg';
import ArticleTile from './components/ArticleTiles';
import animation1 from './animations/Articles4.json';
import animation2 from './animations/Articles5.json';
import animation3 from './animations/Articles6.json';
import {
  FlexLayout,
  Paragraph,
  Title
} from '@nutanix-ui/prism-reactjs';
import axios from 'axios';
import cheerio from 'cheerio'; // For parsing HTML

export default function Tales(props) {

  const [articles, setArticles] = useState([]);


return (
  <FlexLayout flexDirection="column" itemSpacing="80px">
    <FlexLayout justifyContent="space-between">
      <Title size="h2" className="darkSectionTitle" style={{ width: '30%'}}>Tales from the field</Title>
      <Paragraph className="darkSectionDescription" style={{ width: '55%', marginLeft: '50px', marginTop: '10px', marginRight: '100px'}}>We share our thoughts on design with the community. Explore selected perspectives on the craft of design, case studies, and experiences from fellow designers at Nutanix.
</Paragraph>

    </FlexLayout>
    <article>
    <FlexLayout justifyContent="center">

     {props.articles.map((article) => (
         <ArticleTile key={article.link} image={article.img} title={article.title} description={article.content} link={article.link}/>
     ))}

    </FlexLayout>
    </article>





  </FlexLayout>
);


}
