import React, {useRef, useEffect} from 'react';
import '../App.css';
import lottie from 'lottie-web';
import animationData from '../animations/arrowArticleCard.json';
import ArticleImage from '../images/internshipArticleImage1.svg';
import ArrowImage from '../images/redirectArrow.svg';
import {
  Menu,
  MenuItem,
  Dropdown,
  Link,
  Tabs,
  Divider,
  TextLabel,
  FlexItem,
  StackingLayout,
  FlexLayout,
  NavBarLayout,
  ShareArrowIcon,
  ExternalLinkIcon,
  AlertIcon,
  Paragraph,
  Badge,
  Button,
  Title,
  Progress,
  ThemeManager
} from '@nutanix-ui/prism-reactjs';

export default function ArticleCard(props) {

  let internshipAnimation = useRef(null);

  const myRef = useRef(null);
  const internshipAnimationRef = useRef(null);

  useEffect(() => {

    internshipAnimation.current = lottie.loadAnimation({
     container: internshipAnimationRef.current,
     renderer: "svg",
     loop: false,
     autoplay: false,
     animationData: props.animation
   });


    return () => {
            internshipAnimation.current.destroy();

    };
  }, []);

  const playReverse = () => {

    internshipAnimation.current.setDirection(-1);
    internshipAnimation.current.play();

  };
  const playForward = () => {

    internshipAnimation.current.setDirection(1);
    internshipAnimation.current.play();


  };


return (
  <FlexLayout className="hiringArticleCard"  onClick={() => window.open(props.link, "_blank")} style={{cursor: 'pointer'}}onMouseEnter={playForward} onMouseLeave={playReverse}>
   <FlexLayout alignItems="stretch"><div ref={internshipAnimationRef}></div></FlexLayout>
   <FlexLayout flexDirection="column" className="hiringArticleTileDescription" itemSpacing="10px" justifyContent="center" >
     <FlexLayout>
     <Title size="h4" className="sectionDescriptionBold hiringArticleTitle" style={{color: '#C6BEFE', width: '80%'}}>{props.title}</Title>
     <Link href={props.link} target="_blank" className="articleArrowLink"><div class="articleCardArrow"></div></Link>
     </FlexLayout>
     <Paragraph className="sectionDescriptionArticleDesc hiringArticleDesc" style={{color: '#C6BEFE'}}>{props.desc}</Paragraph>

   </FlexLayout>


  </FlexLayout>
);


}
