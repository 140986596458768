import React from 'react';
import './Main.css';
import {
  TextLabel,
  FlexLayout,
  Paragraph,
  Title
} from '@nutanix-ui/prism-reactjs';

import Image from './images/principlesImage.svg';

import UpliftImage from './images/Uplift.svg';
import TrustImage from './images/EngenderTrust.svg';
import EmpathyImage from './images/Empathy.svg';
import FrictionImage from './images/Frictionless.svg';

export default function MobilePrinciples() {




return (
  <FlexLayout flexDirection="column" itemSpacing="20px">

      <Title className="sectionTitle">The Principles</Title>
      <Paragraph className="sectionDescription">Our guiding principles for product and design are cornerstones of the work we do at Nutanix. While helping us to stay focused on our vision and values, they also provide criteria for crafting the best experiences. We want to:</Paragraph>
      <img src={Image} style={{ position: 'relative', marginLeft: '-19px', marginRight: '-16px', paddingTop: '20px', paddingBottom: '20px' }}/>
      <FlexLayout>
      <FlexLayout flexDirection="column" style={{flex: '1 1 0px'}} itemSpacing="10px">
        <img src={UpliftImage} style={{height: '36px', width: '36px'}}/>
        <TextLabel className="brandColorText">Uplift</TextLabel>
        <Paragraph className="sectionDescriptionSmall">“Uplift the user to achieve more with minimal inputs and efforts in their day-day workflows.”</Paragraph>
      </FlexLayout>
      <FlexLayout flexDirection="column" style={{flex: '1 1 0px'}} itemSpacing="10px">
        <img src={TrustImage} style={{height: '36px', width: '36px'}}/>
        <TextLabel className="brandColorText" >Engender Trust</TextLabel>
        <Paragraph className="sectionDescriptionSmall" >“Engender trust with user by coupling tightly with their existing integrations in such workflows.”</Paragraph>
      </FlexLayout>
      </FlexLayout>
      <FlexLayout>
      <FlexLayout flexDirection="column" style={{flex: '1 1 0px'}} itemSpacing="10px">
        <img src={EmpathyImage} style={{height: '36px', width: '36px'}}/>
        <TextLabel className="brandColorText">Empathize</TextLabel>
        <Paragraph className="sectionDescriptionSmall">“Display empathy for the user by accounting their feedback, approach, context of usage.”</Paragraph>
      </FlexLayout>
      <FlexLayout flexDirection="column" style={{flex: '1 1 0px'}} itemSpacing="10px">
        <img src={UpliftImage} style={{height: '36px', width: '36px'}}/>
        <TextLabel className="brandColorText">Frictionless</TextLabel>
        <Paragraph className="sectionDescriptionSmall">“Create a frictionless path that blurs the seams in these workflows and eliminates their
triubles.troubles.”</Paragraph>
      </FlexLayout>

      </FlexLayout>


  </FlexLayout>
);


}
