import React, { Component, useEffect } from "react";
import { Link, animateScroll as scroll } from "react-scroll";
import '../App.css';
import Line from '../images/navLine.svg';

export default function NavBar(props) {
  const handleScroll = (event, sectionId) => {
    event.preventDefault();
    const section = document.getElementById(sectionId);
    if (section) {
      const viewportHeight = document.documentElement.clientHeight;
      const sectionPosition = section.getBoundingClientRect().top + window.pageYOffset;
      window.scrollTo({
        top: sectionPosition - (viewportHeight / 2),
        behavior: 'smooth'
      });
    }
  };
  useEffect(() => {
    const introLink = document.getElementById('foundationLink');
    const aboutLink = document.getElementById('principlesLink');
    const articleLink = document.getElementById('artefactsLink');

    introLink.addEventListener('click', (e) => handleScroll(e, 'foundationSection'));
    aboutLink.addEventListener('click', (e) => handleScroll(e, 'principlesSection'));
    articleLink.addEventListener('click', (e) => handleScroll(e, 'artefactsSection'));

    // Clean up event listeners on component unmount
    return () => {
      introLink.removeEventListener('click', (e) => handleScroll(e, 'foundationSection'));
      aboutLink.removeEventListener('click', (e) => handleScroll(e, 'principlesSection'));
      articleLink.removeEventListener('click', (e) => handleScroll(e, 'artefactsSection'));
    };
  }, []);
    return (
      <div className="nav" id="navbar">
          <nav>
          <img src={Line} className="navLine"/>
          <ul className="nav-items">
            <li className="nav-item firstItem"><Link
            activeClass="active"
            to="foundationSection"
            id="foundationLink"
            className="focusable-link"
            spy={true}
            containerId="containerElement"
            smooth={true}

            duration={500}>Foundation</Link></li>
            <li className="nav-item secondItemSystem" id="principles"><Link
            activeClass="active"
            to="principlesSection"
            id="principlesLink"
            spy={true}
            className="focusable-link"
            containerId="containerElement"
            smooth={true}

            duration={500}>Principles</Link></li>
            <li className="nav-item thirdItemCareers"><Link
            activeClass="active"
            to="artefactsSection"
            id="artefactsLink"
            className="focusable-link"
            spy={true}
            containerId="containerElement"
            smooth={true}

            duration={500}>Libraries</Link></li>
          </ul>
          </nav>
      </div>
    );

}
