import React from 'react';
import '../App.css';
import ArrowImage from '../images/arrow.svg';
import { NavLink } from "react-router-dom";
import {
  FlexLayout,
  Button,
  Title
} from '@nutanix-ui/prism-reactjs';

export default function SkillsJoinUs() {

return (
  <FlexLayout flexDirection="column" className="skillsJoinUs">

     <Title className="sectionDescriptionRegularLarge" style={{  color: '#FFFFFF'}}>We are always on the lookout for talented folks to join our evergrowing team at Nutanix.</Title>
     <Button style={{alignSelf: 'flex-start'}} className="linkButton"><NavLink to='/careers'>View Openings<img src={ArrowImage}/></NavLink></Button>
 </FlexLayout>





);


}
