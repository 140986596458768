import React from 'react';
import './App.css';
import { NavLink, withRouter } from "react-router-dom";
import { Link, animateScroll as scroll } from "react-scroll";
import JoinUsImage from './images/joinUs.svg';

import ArrowImage from './images/arrow.svg';
import {
  Title,
  Button,
  Paragraph,
  StackingLayout,
  FlexLayout
} from '@nutanix-ui/prism-reactjs';


export default function MobileJoinUs() {


return (
  <StackingLayout style={{ padding: '70px 16px 0px' }}>
  <FlexLayout flexDirection="column" justifyContent="center" alignItems="center" itemSpacing="30px" >
    <FlexLayout flexDirection="column">
    <Title className="sectionTitle">Join the crew</Title>
    <Paragraph className="sectionDescription">Our ever-growing team is always on the lookout for fellow problem solvers and lovers of the craft.</Paragraph>
      <Button style={{alignSelf: 'flex-start'}} className="linkButton"><NavLink to='/careers'>Explore Open Roles<img src={ArrowImage}/></NavLink></Button>
  </FlexLayout>
  <img src={JoinUsImage} style={{marginTop: '60px'}}/>
  </FlexLayout>
  </StackingLayout>
);


}
