import React, { useEffect, useRef } from 'react';
import './Main.css';
import lottie from 'lottie-web';
import animationData from '../animations/designSystemHome.json';
import Image from './images/principlesImage.svg';
import {
  Link,
  FlexLayout,
  TextLabel,
  Paragraph,
  Title
} from '@nutanix-ui/prism-reactjs';
import upliftAnimation from '../animations/Uplift.json';
import trustAnimation from '../animations/EngenderTrust.json';
import empathyAnimation from '../animations/Empathize.json';
import frictionAnimation from '../animations/Frictionless.json';
import SideQuestCard from '../components/SideQuestCard';



export default function Principles() {


return (
 <FlexLayout flexDirection="column">
 <FlexLayout style={{backgroundColor: '#161616', borderTopLeftRadius: '5px', borderTopRightRadius: '5px', paddingTop: '30px'}}>
  <FlexLayout flexDirection="column" style={{alignSelf: 'center', paddingLeft: '40px'}}>
  <Title size="h2" className="darkSectionTitleSmall">The Principles</Title>
  <Paragraph className="sectionDescription" style={{width: '85%', color: '#AAA'}}>Our guiding principles for product and design are cornerstones of the work we do at Nutanix. While helping us to stay focused on our vision and values, they also provide criteria for crafting the best experiences. We want to:</Paragraph>
  </FlexLayout>
  <img src={Image}/>
  </FlexLayout>

  <FlexLayout style={{paddingTop: '35px'}}>

      <SideQuestCard animationData={upliftAnimation} title="Uplift" description="Uplift users to achieve more with less complexity."/>

      <SideQuestCard animationData={trustAnimation} title="Build Trust" description="Build trust by focusing on customers' success first and coupling tightly with their existing integrations."/>

    <SideQuestCard animationData={frictionAnimation} title="Frictionless" description="Create frictionless experiences that create seamless workflows and eliminate noise."/>

    <SideQuestCard animationData={empathyAnimation} title="Empathize" description="Empathize to innovate and be intuitive when accumulating and incorporating feedback."/>
  </FlexLayout>
  </FlexLayout>
);


}
