import React, {useState, useEffect, useRef} from 'react';
import { Element, scroller } from 'react-scroll';
import Headroom from 'react-headroom';
import NavBar from './NavBar';
import Nav from '../Nav';
import Openings from './Openings';
import MobileOpenings from './MobileOpenings';
import Hiring from './Hiring';
import MobileHiring from './MobileHiring';
import Growth from './Growth';
import MobileGrowth from './MobileGrowth';
import FAQ from './FAQ';
import MobileFAQ from './MobileFAQ';
import Footer from '../Footer';
import MobileFooter from '../MobileFooter';

import './Careers.css';


import {
  StackingLayout,
  FlexLayout
} from '@nutanix-ui/prism-reactjs';


export default function CareersMain() {

  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }


  const isMobile = width <= 768;

    const isTablet = width >=1024 && width <=1350;
    const sectionFocused = useRef(false);

  const handleTabPress = (e) => {
      if (e.key === 'Tab' && !e.shiftKey) {
        const introLink = document.getElementById('openingsLink');
         e.preventDefault();
         introLink.focus();

        // Remove this event listener after it runs once

        sectionFocused.current = false;
      }
    };

  const handleKeyDown = (event, navItems) => {

    const currentFocusIndex = Array.from(navItems).indexOf(document.activeElement);
    const introLink = document.getElementById('openingsLink');
    if (event.key === "ArrowDown") {
      event.preventDefault();
      let nextIndex = (currentFocusIndex + 1) % navItems.length;
      navItems[nextIndex].focus();
    } else if (event.key === "ArrowUp") {
      event.preventDefault();
      let prevIndex = (currentFocusIndex - 1 + navItems.length) % navItems.length;
      navItems[prevIndex].focus();
    } else if (event.key === "Tab" && !event.shiftKey) {
       if(!sectionFocused.current) {
       event.preventDefault();
       const introButton = document.querySelectorAll('.openingFilter');
       introButton[0].focus();
     } else if(sectionFocused.current && introLink.classList.contains('active')) {
       event.preventDefault();
       const introButton = document.querySelector('.openingFilter');
       introButton.focus();
       sectionFocused.current = false;
     }
    }
  };

  const handleNavigation = (event, targetSection, focusedItem) => {
    if(event.key === "Enter") {
      sectionFocused.current = true;
      window.scroller = scroller;
      setTimeout(() => {
        scroller.scrollTo(targetSection, { duration: 1500,
    delay: 0,
    smooth: true,
    containerId: 'containerElement',
    offset: 50, });
   }, 50);
     } else if(event.key === 'Tab' && sectionFocused.current) {

        event.preventDefault();
        focusedItem.focus();
     }

  }

     useEffect(() => {

     window.addEventListener('resize', handleWindowSizeChange);

     if(!isMobile) {
     const navItems = document.querySelectorAll(".focusable-link");



     navItems.forEach((item) => {
       item.setAttribute("tabindex", "0");
       item.addEventListener("keydown", (event) => handleKeyDown(event, navItems));
     });
     const openingsLink = document.getElementById('openingsLink');

     openingsLink.addEventListener("keydown", function(event) {
      const rolesCard = document.querySelectorAll('.openingFilter');
      handleNavigation(event, 'openingsSection', rolesCard[0]);
      rolesCard[2].addEventListener('keydown', function(e) {
       if(sectionFocused.current){
         handleTabPress(e);
       }
       });
     });
     const hireLink = document.getElementById('hireLink');

     hireLink.addEventListener("keydown", function(event) {
      const rolesCard = document.querySelectorAll('.flip-card');
      handleNavigation(event, 'hireSection', rolesCard[0]);
      rolesCard[3].addEventListener('keydown', function(e) {
       if(sectionFocused.current){
         handleTabPress(e);
       }
       });
     });
     const careergrowthLink = document.getElementById('careergrowthLink');

     careergrowthLink.addEventListener("keydown", function(event) {
      const rolesCard = document.querySelectorAll('.tabButton');
      const sideQuest = document.querySelectorAll('.sideQuest');

      handleNavigation(event, 'careerGrowthSection', rolesCard[0]);
      sideQuest[3].addEventListener('keydown', function(e) {
       if(sectionFocused.current){
         handleTabPress(e);
       }
       });
     });
     const faqLink = document.getElementById('faqLink');

     faqLink.addEventListener("keydown", function(event) {
      const rolesCard = document.querySelectorAll('.faqAccordion .accordion-header .accordion-toggle');
      handleNavigation(event, 'faqSection', rolesCard[0]);
      rolesCard[rolesCard.length - 1].addEventListener('keydown', function(e) {
       if(sectionFocused.current){
         handleTabPress(e);
       }
       });
     });
   }
     return () => {
         window.removeEventListener('resize', handleWindowSizeChange);
     }


    }, []);





  return (

    <Element name="test7" className="element" id="containerElement" style={{
      position: 'relative',
      height: '100vh',
      overflow: 'scroll'
    }}>
      <Nav />

      <a href="#maincontent" className="skip-link" id="skipButton">Skip to main content</a>
    <StackingLayout className="mainContainer" id="maincontent" padding="20px" itemSpacing="0px">
    <StackingLayout itemSpacing="0px">



  <Headroom style={{
    webkitTransition: 'all .5s ease-in-out',
   mozTransition: 'all .5s ease-in-out',
    oTransition: 'all .5s ease-in-out',
    transition: 'all .5s ease-in-out'
}}>
<header>
 <NavBar />
   </header>
  </Headroom>
    </StackingLayout>
    <Element id="openingsSection">
      <section>
      {isMobile ? <MobileOpenings/> : <Openings/>}
      </section>
    </Element>
    <Element id="hireSection" style={{ background: '#131313'}}>
      <section>
      {isMobile ? <MobileHiring/> : <Hiring />}
      </section>
    </Element>
    <Element id="careerGrowthSection">
      <section>
      {isMobile ? <MobileGrowth/> : <Growth />}
      </section>
    </Element>
    <Element id="faqSection">
      <section>
      {isMobile ? <MobileFAQ/> : <FAQ />}
      </section>
    </Element>
    </StackingLayout>
      <footer>
      {isMobile ? <MobileFooter/> : <Footer />}
      </footer>
    </Element>


  );
}
