import React, { useState, useEffect, useRef } from 'react';
import './Main.css';
import {
  Link,
  StackingLayout,
  FlexLayout,
  Paragraph,
  Button,
  Title,
  Badge,
  FlexItem
} from '@nutanix-ui/prism-reactjs';
import ArtefactsImage from './images/artefactsImage.svg';
import IllustrationsImage from './images/illustrationsArtefacts.svg';
import VisualImage from './images/visualArtefacts.svg';
import ContentImage from './images/contentArtefacts.svg';


export default function Artefacts() {










return (
  <StackingLayout>
  <Title size="h2" className="darkSectionTitle" style={{paddingBottom: '80px'}}>Our Libraries</Title>
<FlexLayout flexDirection="column" className="artefacts" itemSpacing="80px">

  <FlexLayout justifyContent="space-between" alignItems="center" className="artefactDetails">
    <img src={ArtefactsImage} style={{width: '502px', height: '357px'}}/>
    <FlexLayout flexDirection="column" style={{width: '45%'}} >
      <FlexLayout alignItems="center">
      <Title size="h3" className="articleTitle" style={{color: '#FFFFFF'}}>UI System</Title>
        <FlexItem className="artefactsButton">Currently Internal</FlexItem>
      </FlexLayout>
      <Paragraph className="darkSectionDescription">Our UI components are the building blocks we use to create a consistent, high-quality experience across our 20+ product portfolio.
They are solid yet flexible, constantly evolving, and allow us to iterate fast.</Paragraph>


<Link href="https://ntnx-intranet--simpplr.vf.force.com/apex/simpplr__app?u=/site/a0x5d00000B4VTCAA3/content" target="_blank" className="artefactParentLink"><FlexLayout alignItems="center" itemSpacing="5px">
<FlexItem className="linkLarge artefactLink">User Interface Guidelines</FlexItem>
 <div className="articleArrow"></div>
</FlexLayout>
</Link>

    </FlexLayout>
  </FlexLayout>
  <FlexLayout justifyContent="space-between" alignItems="center" className="artefactDetails">
    <img src={IllustrationsImage} style={{width: '502px', height: '357px'}}/>
    <FlexLayout flexDirection="column" style={{width: '45%'}}>
      <Title size="h3" className="articleTitle" style={{color: '#FFFFFF'}}>Illustration System</Title>
      <Paragraph className="darkSectionDescription">Illustrations can simplify complex ideas and add an element of delight for customers. Our flexible illustration library allows us to build complex custom illustrations and caters to both, product and marketing purposes.</Paragraph>
<Link href="https://nutanix.design/illustrations/#/home/product/empty_states" target="_blank" className="artefactParentLink"><FlexLayout alignItems="center" itemSpacing="5px">
<FlexItem className="linkLarge artefactLink">Illustration Guidelines</FlexItem>
 <div className="articleArrow"></div>
</FlexLayout>
</Link>
    </FlexLayout>
  </FlexLayout>
  <FlexLayout justifyContent="space-between" alignItems="center" className="artefactDetails">
    <img src={VisualImage} style={{width: '502px', height: '357px'}}/>
    <FlexLayout flexDirection="column" style={{width: '45%'}}>
      <Title size="h3" className="articleTitle" style={{color: '#FFFFFF'}}>Viz. System</Title>
      <Paragraph className="darkSectionDescription">Displaying data helps our customers better understand their infrastructure and therefore is an integral part of our work. Our customisable library helps us meet our requirements by allowing us to display data in various formats.</Paragraph>
        <FlexItem className="artefactsButton" style={{alignSelf: 'flex-start'}}>Coming Soon !</FlexItem>
    </FlexLayout>
  </FlexLayout>
  <FlexLayout justifyContent="space-between" alignItems="center" className="artefactDetails">
    <img src={ContentImage} style={{width: '502px', height: '357px'}}/>
    <FlexLayout flexDirection="column" style={{width: '45%'}}>
      <FlexLayout alignItems="center">
      <Title size="h3" className="articleTitle" style={{color: '#FFFFFF'}}>Content System</Title>
        <FlexItem className="artefactsButton">Currently Internal</FlexItem>
        </FlexLayout>
      <Paragraph className="darkSectionDescription">Our content is the stepping stone for helping users understand a problem and accomplish a task.
As designers, these guidelines help us shape experiences with our brand voice and also ensure uniformity in terminology, structure.</Paragraph>
<Link href="https://ntnx-intranet--simpplr.vf.force.com/apex/simpplr__app?u=/site/a0x5d00000B4VTCAA3/page/a0v5d00000Jx0ksAAB" target="_blank" className="artefactParentLink"><FlexLayout alignItems="center" itemSpacing="5px">
<FlexItem className="linkLarge artefactLink">Content Guidelines</FlexItem>
<div className="articleArrow"></div>
</FlexLayout>
</Link>
    </FlexLayout>
  </FlexLayout>
    </FlexLayout>


  </StackingLayout>
);


}
