import React, {useState, useEffect, useRef} from 'react';
import lottie from 'lottie-web';
import $ from 'jquery';
import animationData from '../animations/Team.json';
import meetanimationData from '../animations/meetTeamAnimation.json';
import stakeholderanimationData from '../animations/stakeholderAnimation.json';
import workanimationData from '../animations/workReviewAnimation.json';
import intershipanimation1 from '../animations/InternshipArticle1.json';
import intershipanimation2 from '../animations/InternshipArticle2.json';
import ArrowImage from '../images/hiringArrow.svg';
import ArrowDownImage from './images/hiringArrowDown.svg';
import './Careers.css';
import ArticleCard from '../components/ArticleCard';
import {
  Link,
  FlexItem,
  StackingLayout,
  FlexLayout,
  Paragraph,
  Button,
  Title,
  VerticalSeparator
} from '@nutanix-ui/prism-reactjs';

export default function Hiring() {

  let cardHidden = false;
  let hiddenCardIndex = 0;


  const myRef = useRef(null);
  const myRef1 = useRef(null);
  const myRef2 = useRef(null);
  const myRef3 = useRef(null);
  let myLottie = useRef(null);
  let myLottie1 = useRef(null);
  let myLottie2 = useRef(null);
  let myLottie3 = useRef(null);

  useEffect(() => {
     myLottie.current = lottie.loadAnimation({
      container: myRef.current,
      renderer: "svg",
      loop: false,
      autoplay: false,
      animationData: animationData
    });
    myLottie1.current = lottie.loadAnimation({
     container: myRef1.current,
     renderer: "svg",
     loop: false,
     autoplay: false,
     animationData: meetanimationData
   });
   myLottie2.current = lottie.loadAnimation({
    container: myRef2.current,
    renderer: "svg",
    loop: false,
    autoplay: false,
    animationData: workanimationData
  });
  myLottie3.current = lottie.loadAnimation({
   container: myRef3.current,
   renderer: "svg",
   loop: false,
   autoplay: false,
   animationData: stakeholderanimationData
 });




    return () => {
      myLottie.current.destroy();
        myLottie1.current.destroy();
          myLottie2.current.destroy();
            myLottie3.current.destroy();

    };
  }, []);


  const playForward = (e, lottieRef, cardIndex) => {
      if(hiddenCardIndex !== cardIndex && lottieRef) {
      lottieRef.current.setDirection(1);
      lottieRef.current.play();
      $(e.currentTarget.querySelector('.hireTileTitle')).addClass('changeColor');

    }


  };
  const playReverse = (e, lottieRef, cardIndex) => {
    if(hiddenCardIndex !== cardIndex && lottieRef) {
    lottieRef.current.setDirection(-1);
    lottieRef.current.play();
    $(e.currentTarget.querySelector('.hireTileTitle')).removeClass('changeColor');

  }
  }

  const showCard = (e, cardIndex) => {

    $(e.currentTarget.querySelector('.hireTileTitle')).addClass('changeColor');

    $(e.currentTarget).removeClass('showCard');
    $(e.currentTarget).addClass('hideCard');
    $(e.currentTarget.querySelector('.animation')).addClass('hideAnimation');
    $(e.currentTarget).find('#hiringArrowImage').attr('src', ArrowDownImage);


    $(e.currentTarget.nextSibling).removeClass('hideDesc');
    $(e.currentTarget.nextSibling).addClass('showDesc');
    cardHidden = true;
    hiddenCardIndex = cardIndex;

  }
  const hideCard = (e) => {
    $(e.currentTarget.previousSibling).removeClass('hideCard');
    $(e.currentTarget.previousSibling).addClass('showCard');

        $(e.currentTarget.previousSibling.querySelector('.hireTileTitle')).removeClass('changeColor');
        $(e.currentTarget).removeClass('showDesc');
      $(e.currentTarget).addClass('hideDesc');

      $(e.currentTarget.previousSibling).find('#hiringArrowImage').attr('src', ArrowImage);



      cardHidden = false;
      hiddenCardIndex = 0;

  }

  const handleKeyDown = (event, cardIndex) => {
    if(event.key === 'Enter') {
      event.preventDefault();
      showCard(event, cardIndex);
    }
  }


return (
  <FlexLayout flexDirection="column">
    <FlexLayout itemSpacing="20px" alignItems="center">
    <Title size="h2" className="sectionTitle" style={{color: '#FFFFFF'}}>How we Hire</Title>
    <VerticalSeparator style={{height: '50px', backgroundColor: '#7855FA'}}/>
    <Paragraph className="sectionDescription" style={{width: '50%', color: '#FFFFFF'}}>Our hiring process consists of 4 steps in which we would love to learn more about you and your approach as a designer.</Paragraph>
    </FlexLayout>
    <section>
    <FlexLayout justifyContent="space-between" style={{paddingTop: '70px'}}>

    <div class="flip-card">
      <div class="flip-card-inner">
        <div class="flip-card-front" onMouseEnter={(e) => playForward(e, myLottie, 1)} onMouseLeave={(e) => playReverse(e, myLottie, 1)} onClick={(e) => showCard(e, 1)} style={{cursor: 'pointer', zIndex: '5000'}} tabIndex="0" onKeyDown={(e) => handleKeyDown(e, 1)}>
         <div ref={myRef}  className="animation"></div>
         <FlexLayout itemSpacing="10px">
         <Title size="h3" className="sectionDescriptionBoldLarge hireTileTitle" style={{width: '70%'}}>Review your work</Title>
         <FlexLayout alignItems="center" itemSpacing="15px" className="hireArrow">
         <VerticalSeparator style={{height: '45px', backgroundColor: '#333333'}}/>
         <img id="hiringArrowImage" src={ArrowImage} />
         </FlexLayout>
         </FlexLayout>
        </div>
        <div class="flip-card-back" onClick={(e) => hideCard(e)}>
        <div class="card-description" style={{height: '500px', position: 'absolute', paddingTop: '65px'}}>

        <Paragraph className="sectionDescription hireTileDescription">This involves a screening of your resume, followed by a chat about your work and reviewing your projects</Paragraph>
        </div>

        </div>
      </div>
    </div>
    <div class="flip-card">
      <div class="flip-card-inner">
        <div class="flip-card-front" onMouseEnter={(e) => playForward(e, myLottie1, 2)} onMouseLeave={(e) => playReverse(e, myLottie1, 2)} onClick={(e) => showCard(e, 2)} style={{cursor: 'pointer', zIndex: '5000'}} tabIndex="0" onKeyDown={(e) => handleKeyDown(e, 2)}>
         <div ref={myRef1}  className="animation"></div>
         <FlexLayout itemSpacing="10px">
         <Title size="h3" className="sectionDescriptionBoldLarge hireTileTitle" style={{width: '70%'}}>Meet the team</Title>
         <FlexLayout alignItems="center" itemSpacing="15px" className="hireArrow">
         <VerticalSeparator style={{height: '45px', backgroundColor: '#333333'}}/>
         <img id="hiringArrowImage" src={ArrowImage} />
         </FlexLayout>
         </FlexLayout>
        </div>
        <div class="flip-card-back" id="flipCardBack" onClick={(e) => hideCard(e)}>
        <div class="card-description" style={{height: '500px', position: 'absolute', paddingTop: '65px'}}>

        <Paragraph className="sectionDescription hireTileDescription lastTile" >We already like you and would love to get to know you better. Meet the hiring manager and fellow designers from the team to talk more about your portfolio.</Paragraph>
        </div>

        </div>
      </div>
    </div>
    <div class="flip-card">
      <div class="flip-card-inner">
        <div class="flip-card-front" onMouseEnter={(e) => playForward(e, myLottie2, 3)} onMouseLeave={(e) => playReverse(e, myLottie2, 3)} onClick={(e) => showCard(e, 3)} style={{cursor: 'pointer', zIndex: '5000'}} tabIndex="0" onKeyDown={(e) => handleKeyDown(e, 3)}>
         <div ref={myRef2}  className="animation"></div>
         <FlexLayout itemSpacing="10px">
         <Title size="h3" className="sectionDescriptionBoldLarge hireTileTitle" style={{width: '70%'}}>Work on a design task</Title>
         <FlexLayout alignItems="center" itemSpacing="15px" className="hireArrow">
         <VerticalSeparator style={{height: '45px', backgroundColor: '#333333'}}/>
         <img id="hiringArrowImage" src={ArrowImage} />
         </FlexLayout>
         </FlexLayout>
        </div>
        <div class="flip-card-back" onClick={(e) => hideCard(e)}>
        <div class="card-description" style={{height: '500px', position: 'absolute', paddingTop: '65px'}}>

        <Paragraph className="sectionDescription hireTileDescription lastTile" >Take the oportunity to put your analytical skills to the test. We're excited to see what you come up with!</Paragraph>
        </div>

        </div>
      </div>
    </div>
    <div class="flip-card">
      <div class="flip-card-inner">
        <div class="flip-card-front" onMouseEnter={(e) => playForward(e, myLottie3, 4)} onMouseLeave={(e) => playReverse(e, myLottie3, 4)} onClick={(e) => showCard(e, 4)} style={{cursor: 'pointer', zIndex: '5000'}} tabIndex="0" onKeyDown={(e) => handleKeyDown(e, 4)}>
         <div ref={myRef3}  className="animation"></div>
         <FlexLayout itemSpacing="10px">
         <Title size="h3" className="sectionDescriptionBoldLarge hireTileTitle" style={{width: '70%'}}>Meet the stakeholders</Title>
         <FlexLayout alignItems="center" itemSpacing="15px" className="hireArrow">
         <VerticalSeparator style={{height: '45px', backgroundColor: '#333333'}}/>
         <img id="hiringArrowImage" src={ArrowImage} />
         </FlexLayout>
         </FlexLayout>
        </div>
        <div class="flip-card-back" onClick={(e) => hideCard(e)}>
        <div class="card-description" style={{height: '500px', position: 'absolute', paddingTop: '65px'}}>

        <Paragraph className="sectionDescription hireTileDescription" >At this stage, we can already imagine you being part of our team. Meet the people you'll be working with!</Paragraph>
        </div>

        </div>
      </div>
    </div>




</FlexLayout>
</section>
<section>
<FlexLayout flexDirection="column" className="internshipContainer" style={{marginTop: '100px', position: 'relative', background: '#131313'}}>
  <Title size="h3" className="articleTitle" style={{color: '#FFFFFF'}}>Internships</Title>
  <Paragraph className="sectionDescription" style={{color: '#FFFFFF', width: '70%'}}>A design internship at Nutanix is the real deal. Trust us, you won't need to get a hot drink for someone other than yourself. Instead, you get to work on complex features critical to our products together with experienced designers.</Paragraph>
  <Paragraph className="sectionDescription" style={{color: '#FFFFFF', width: '70%'}}>Every year we offer two and six months student internship programs to hire bright talent. Read what former interns have to say about their experiences working with us.</Paragraph>
  <article>
  <FlexLayout itemSpacing="0px" style={{gap: '16px', paddingTop: '40px'}}>
  <ArticleCard animation={intershipanimation1} title="Keeping it Real: Our Nutanix Internship Experiences" desc="Internships are an invaluable part of one’s education on the road to becoming a UX…" link="https://medium.com/nutanix-design/keeping-it-real-our-nutanix-internship-experiences-e4bb34b8c7e0"/>
    <ArticleCard animation={intershipanimation2} title="Nine Weeks with the Design Team at Nutanix India" desc="Looking back at my internship as a UX designer with Nutanix, what it was like…" link="https://medium.com/ux-planet/nine-weeks-with-the-design-team-at-nutanix-india-3408d2f549f5"/>
  </FlexLayout>
  </article>

</FlexLayout>
</section>

  </FlexLayout>
);


}
