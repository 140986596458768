import React, {useEffect} from "react";
import { Link, animateScroll as scroll, Element, scrollSpy } from "react-scroll";
import Line from '../images/navLine.svg';
import '../App.css';
import { useHistory } from 'react-router-dom';

export default function NavBar(props) {

  const handleScroll = (event, sectionId) => {
    event.preventDefault();
    const section = document.getElementById(sectionId);
    if (section) {
      const viewportHeight = document.documentElement.clientHeight;
      const sectionPosition = section.getBoundingClientRect().top + window.pageYOffset;
      window.scrollTo({
        top: sectionPosition - (viewportHeight / 2),
        behavior: 'smooth'
      });
    }
  };

  // Attach event listeners on component mount
 useEffect(() => {
   const introLink = document.getElementById('peopleLink');
   const aboutLink = document.getElementById('rolesLink');
   const articleLink = document.getElementById('partnersLink');
   const joinUsLink = document.getElementById('growthLink');

   introLink.addEventListener('click', (e) => handleScroll(e, 'peopleSection'));
   aboutLink.addEventListener('click', (e) => handleScroll(e, 'rolesSection'));
   articleLink.addEventListener('click', (e) => handleScroll(e, 'partnersSection'));
   joinUsLink.addEventListener('click', (e) => handleScroll(e, 'growthSection'));

   // Clean up event listeners on component unmount
   return () => {
     introLink.removeEventListener('click', (e) => handleScroll(e, 'peopleSection'));
     aboutLink.removeEventListener('click', (e) => handleScroll(e, 'rolesSection'));
     articleLink.removeEventListener('click', (e) => handleScroll(e, 'partnersSection'));
     joinUsLink.removeEventListener('click', (e) => handleScroll(e, 'growthSection'));
   };
 }, []);
    return (
      <div className="nav" id="navbar">
          <nav>
          <img src={Line} className="navLine"/>
          <ul className="nav-items">
            <li className="nav-item firstItem"><Link
            activeClass="active"
            id="peopleLink"
            to="peopleSection"
            spy={true}
            className="focusable-link"
            containerId="containerElement"
            smooth={true}

            duration={500}
            >About Us</Link></li>

            <li className="nav-item secondItem" id="roles"><Link
            activeClass="active"
            id="rolesLink"
            to="rolesSection"
            spy={true}
            containerId="containerElement"
            className="focusable-link"
            smooth={true}

            duration={500}>Roles</Link></li>
            <li className="nav-item thirdItem" id="partners"><Link
            activeClass="active"
            to="partnersSection"
            id="partnersLink"
            className="focusable-link"
            spy={true}
            containerId="containerElement"
            smooth={true}

            duration={500}>Partners</Link></li>
            <li className="nav-item fourthItem" id="growth"><Link
            activeClass="active"
            id="growthLink"
            to="growthSection"
            className="focusable-link"
            spy={true}
            containerId="containerElement"
            smooth={true}

            duration={500}>Rituals</Link></li>
          </ul>
         </nav>
      </div>
    );

}
