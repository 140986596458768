import Page1 from '../pages/Page1';

// Virtual Infrastructure
import VMs from '../pages/virtualinfrastructure/VMs';
import Skills from '../pages/website/aboutUs/Skills';
import MobileSkills from '../pages/website/aboutUs/MobileSkills';
import CareersMain from '../pages/website/careers/CareersMain';
import DesignSystems from '../pages/website/designSystem/Main';
import AboutUs from '../pages/website/aboutUs/App';
import Roles from '../pages/website/aboutUs/Roles';


export default [{
  key: 'virtualinfrastructure',
  header: 'Virtual Infrastructure',
  pages: [ {
      to: '/careers',
      text: 'Careers',
      component: CareersMain
    },{
      to: '/designSystem',
      text: 'Design Systems',
      component: DesignSystems
    },{
      to: '/aboutUs/roles',
      text: 'Roles',
      component: Roles
    },{
      to: '/aboutUs',
      text: 'About Us',
      component: AboutUs
    },{
      to: '/skills',
      text: 'Skills',
      component: Skills
    },{
      to: '/mobileSkills',
      text: 'Skills',
      component: MobileSkills
    },{
    to: '/storage_containers',
    text: 'Storage Containers',
    component: Page1
  }, {
    to: '/catalog_items',
    text: 'Catalog Items',
    component: Page1
  }, {
    to: '/images',
    text: 'Images',
    component: Page1
  }, {
    to: '/categories',
    text: 'Categories',
    component: Page1
  }, {
    to: '/recoverable_entities',
    text: 'Recoverable Entities',
    component: Page1
  }, {
    to: '/subnets',
    text: 'Subnets',
    component: Page1
  }]
}, {
  key: 'policies',
  header: 'Policies',
  pages: [{
    to: '/security_policies',
    text: 'Security Policies',
    component: Page1
  }, {
    to: '/protection_policies',
    text: 'Protection Policies',
    component: Page1
  }, {
    to: '/recovery_plans',
    text: 'Recovery Plans',
    component: Page1
  }, {
    to: '/ngt_policies',
    text: 'NGT Policies',
    component: Page1
  }, {
    to: '/image_placement',
    text: 'Image Placement',
    component: Page1
  }]
}, {
  key: 'hardware',
  header: 'Hardware',
  pages: [{
    to: '/clusters',
    text: 'Clusters',
    component: Page1
  }, {
    to: '/hosts',
    text: 'Hosts',
    component: Page1
  }, {
    to: '/disks',
    text: 'Disks',
    component: Page1
  }]
}, {
  key: 'activity',
  header: 'Activity',
  pages: [{
    to: '/alerts',
    text: 'Alerts',
    component: Page1
  }, {
    to: '/events',
    text: 'Events',
    component: Page1
  }, {
    to: '/audits',
    text: 'Audits',
    component: Page1
  }, {
    to: '/tasks',
    text: 'Tasks',
    component: Page1
  }]
}, {
  key: 'operations',
  header: 'Operations',
  pages: [{
    to: '/analysis',
    text: 'Analysis',
    component: Page1
  }, {
    to: '/planning',
    text: 'Planning',
    component: Page1
  }, {
    to: '/reports',
    text: 'Reports',
    component: Page1
  }, {
    to: '/playbooks',
    text: 'Playbooks',
    component: Page1
  }, {
    to: '/action_gallery',
    text: 'Action Gallery',
    component: Page1
  }, {
    to: '/plays',
    text: 'Plays',
    component: Page1
  }]
}, {
  key: 'administration',
  header: 'Administration',
  pages: [{
    to: '/lcm',
    text: 'LCM',
    component: Page1
  }, {
    to: '/projects',
    text: 'Projects',
    component: Page1
  }, {
    to: '/roles',
    text: 'Roles',
    component: Page1
  }, {
    to: '/users',
    text: 'Users',
    component: Page1
  }, {
    to: '/availability_zones',
    text: 'Availability Zones',
    component: Page1
  }]
}, {
  key: 'services',
  header: 'Services',
  pages: [{
    to: '/calm',
    text: 'Calm',
    component: Page1
  }, {
    to: '/karbon',
    text: 'Karbon',
    component: Page1
  }, {
    to: '/objects',
    text: 'Objects',
    component: Page1
  }]
}];
