import React, { Component, useState, useEffect } from "react";
import { Link as ScrollLink, animateScroll as scroll } from "react-scroll";
import '../App.css';

import Line from '../images/navLine.svg';

export default function NavBar(props) {
  const handleScroll = (event, sectionId) => {
    event.preventDefault();
    const section = document.getElementById(sectionId);
    if (section) {
      const viewportHeight = document.documentElement.clientHeight;
      const sectionPosition = section.getBoundingClientRect().top + window.pageYOffset;

      window.scrollTo({
        top: sectionPosition - (viewportHeight / 2),
        behavior: 'smooth'
      });
    }

  };

  // Attach event listeners on component mount
 useEffect(() => {
   const introLink = document.getElementById('openingsLink');
   const aboutLink = document.getElementById('hireLink');
   const articleLink = document.getElementById('careergrowthLink');
   const joinUsLink = document.getElementById('faqLink');

   introLink.addEventListener('click', (e) => handleScroll(e, 'openingsSection'));
   aboutLink.addEventListener('click', (e) => handleScroll(e, 'hireSection'));
   articleLink.addEventListener('click', (e) => handleScroll(e, 'careerGrowthSection'));
   joinUsLink.addEventListener('click', (e) => handleScroll(e, 'faqSection'));

   // Clean up event listeners on component unmount
   return () => {
     introLink.removeEventListener('click', (e) => handleScroll(e, 'openingsSection'));
     aboutLink.removeEventListener('click', (e) => handleScroll(e, 'hireSection'));
     articleLink.removeEventListener('click', (e) => handleScroll(e, 'careerGrowthSection'));
     joinUsLink.removeEventListener('click', (e) => handleScroll(e, 'faqSection'));
   };
 }, []);

    return (
      <div className="nav" id="navbar">
          <nav>
          <img src={Line} className="navLine"/>
          <ul className="nav-items">
            <li className="nav-item firstItem"><ScrollLink
            activeClass="active"
            to="openingsSection"
            spy={true}
            id="openingsLink"
            className="focusable-link"
            containerId="containerElement"
            smooth={true}

            duration={500}>Openings</ScrollLink></li>
            <li className="nav-item secondItemCareers"><ScrollLink
            activeClass="active"
            to="hireSection"
            id="hireLink"
            spy={true}
            className="focusable-link"
            containerId="containerElement"
            smooth={true}

            duration={500}>How we hire</ScrollLink></li>
            <li className="nav-item thirdItemCareers"><ScrollLink
            activeClass="active"
            to="careerGrowthSection"
            spy={true}
            id="careergrowthLink"
            className="focusable-link"
            containerId="containerElement"
            smooth={true}

            duration={500}>Growth</ScrollLink></li>
            <li className="nav-item fourthItemCareers" id="faq"><ScrollLink
            activeClass="active"
            to="faqSection"
            spy={true}
            id="faqLink"
            className="focusable-link"
            containerId="containerElement"
            smooth={true}

            duration={500}>FAQs</ScrollLink></li>
          </ul>
          </nav>
      </div>
    );

}
