import React, {useEffect, useState} from 'react';
import '../App.css';
import ArrowImage from '../images/arrow.svg';
import {
  FlexLayout,
  Button,
  Title
} from '@nutanix-ui/prism-reactjs';

import { NavLink, withRouter, useHistory } from "react-router-dom";

export default function SkillsJoinUs() {

  const history = useHistory();
  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  const isTablet = width >=1024 && width <=1350;
  const redirectToAbout = (tabName) => {
      history.push(`/careers`);
    };

  useEffect(() => {



        window.addEventListener('resize', handleWindowSizeChange);
    return () => {
       window.removeEventListener('resize', handleWindowSizeChange);


    };
  }, []);


return (
  <FlexLayout className="skillsJoinUs" justifyContent="space-between">
     <FlexLayout flexDirection="column" itemSpacing="40px">
     <Title className="sectionDescriptionLarge" style={{ width: '80%', color: '#FFFFFF'}}>We are always on the lookout for talented folks to join our evergrowing team at Nutanix.</Title>
    <Button style={{alignSelf: 'flex-start', zIndex: '5000'}} className="linkButton"  onClick={redirectToAbout}>View Openings <div class="buttonArrow"></div></Button>
</FlexLayout>

   </FlexLayout>



);


}
