import React, {useState, useEffect} from 'react';
import '../App.css';
import { NavLink, withRouter, useLocation } from "react-router-dom";
import Nav from '../Nav';
import SkillTabs from '../components/SkillTabs';
import ThoughtsCards from '../components/ThoughtsCards';
import JackImage from '../images/jack.svg';
import NatashaImage from '../images/natasha.svg';
import SumitImage from '../images/sumit.svg';
import AromalImage from '../images/aromal.svg';
import JoinUs from './SkillsJoinUs';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import {
  Link,
  FlexItem,
  FlexLayout,
  Paragraph,
  Button,
  Title
} from '@nutanix-ui/prism-reactjs';


const Skills = (props) => {

  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

const isDesktop = width >= 2000;

useEffect(() => {


 window.addEventListener('resize', handleWindowSizeChange);
 return () => {
     window.removeEventListener('resize', handleWindowSizeChange);
 }

}, []);

return (
  <FlexLayout flexDirection="column">
    <SkillTabs activeKey={props.activeKey} />
    <FlexLayout flexDirection="column" className="skills" style={{padding: '0px 130px 100px'}}>

       <FlexLayout justifyContent="space-between" style={{paddingTop: '130px'}}>
        <Title className="sectionTitle" style={{width: '40%'}}>Thoughts from fellow designers</Title>
        <Paragraph className="sectionDescription"
                   style={{width: '40%', paddingTop: '30px'}}>Read what some of our designers have to say about their experience of working at Nutanix.</Paragraph>
       </FlexLayout>

    </FlexLayout>

    <FlexLayout className="thoughtsContainer">

     <FlexLayout>

    <div className="skillsTile"><ThoughtsCards  image={SumitImage} name="Sumit Dutta" position="Senior UX Researcher" href="https://www.linkedin.com/in/sumitduttauxanthropologist/" question="How do you define your research goals?" answer={<React.Fragment><span className="sectionDescriptionSmall">I define these goals based on what my team members are hoping to learn from specific research. These goals then help me formulate the right research questions that would eventually bring in answers</span></React.Fragment>} /></div>
    <div className="skillsTile"><ThoughtsCards image={AromalImage} name="V Aromal Ram" position="Designer 4" href="https://www.linkedin.com/in/aromalram/" question="How do you conceptualize when faced with a complex problem?" answer={<React.Fragment><span className="sectionDescriptionSmall">When faced with a complex problem, I try to break it down. This helps in understanding the various nuances at play. It's more like lego - where you understand the individual pieces by playing around and then you try piecing it together</span></React.Fragment>}/></div>

    <div className="skillsTile"><ThoughtsCards  image={SumitImage} name="Sumit Dutta" position="Senior UX Researcher" href="https://www.linkedin.com/in/rosenthaljacob/" question="What does UX research for enterprise design look like?" answer={<React.Fragment><span className="sectionDescriptionSmall">A researcher needs to familiarize themselves with enterprise specific vocabularies, avoid contacting the enterprise users too often about similar issues. Building relationships and maintaining the rapport with enterprise users also becomes important.</span></React.Fragment>} /></div>
    </FlexLayout>
    </FlexLayout>
  <FlexLayout flexDirection="column" style={{marginTop: '0px'}}>
<JoinUs />
</FlexLayout>
  </FlexLayout>
);


}

export default withRouter(Skills);
