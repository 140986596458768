import React, {useState, useEffect} from 'react';
import './Careers.css';
import HireTile1 from './images/review.svg';
import HireTile2 from './images/meetTeam.svg';
import HireTile3 from './images/designTask.svg';
import HireTile4 from './images/meetStakeholders.svg';

import ArrowImage from '../images/hiringArrow.svg';

import ArrowDownImage from './images/hiringArrowDown.svg';
import $ from 'jquery';
import ArticleImage1 from '../images/internshipArticleImage1.svg';
import ArticleImage2 from '../images/internshipArticleImage2.svg';

import ArticleCard from '../components/MobileArticleCard';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

import {
  Link,
  FlexLayout,
  Paragraph,
  Button,
  Title,
  VerticalSeparator
} from '@nutanix-ui/prism-reactjs';

export default function MobileHiring() {
  let cardHidden = false;

  const [currentStep, setStep] = useState(0);
  const leftButtonAction = (step) => setStep(step - 1);
const rightButtonAction = (step) => setStep(step + 1);


  function handleCardFlip (e) {
    e.currentTarget.classList.toggle('is-flipped');
  }
  const showCard = (e) => {

    $(e.currentTarget.querySelector('.hireTileTitle')).addClass('changeColor');
      $(e.currentTarget).removeClass('showCard');
    $(e.currentTarget).addClass('hideCard');
    $(e.currentTarget.querySelector('.animation')).addClass('hideAnimation');
    $(e.currentTarget).find('#hiringArrowImage').attr('src', ArrowDownImage);

    $(e.currentTarget.nextSibling).removeClass('hideDesc');
      $(e.currentTarget.nextSibling).addClass('showDesc');
      cardHidden = true;
  }
  const hideCard = (e) => {
    $(e.currentTarget.previousSibling).removeClass('hideCard');
    $(e.currentTarget.previousSibling).addClass('showCard');

        $(e.currentTarget.previousSibling.querySelector('.hireTileTitle')).removeClass('changeColor');
        $(e.currentTarget).removeClass('showDesc');
      $(e.currentTarget).addClass('hideDesc');
        $(e.currentTarget.previousSibling).find('#hiringArrowImage').attr('src', ArrowImage);
      cardHidden = false;

  }


return (
   <FlexLayout flexDirection="column" itemSpacing="20px">
     <Title className="darkSectionTitle">How we hire?</Title>
     <Paragraph className="darkSectionDescription">Our hiring process consists of 4 steps in which we would love to learn more about you and your approach as a designer.</Paragraph>

     <Carousel
        partialVisible={true}
        showDots={true}
        swipeable={true}
        draggable={true}
        ssr={true}
        keyBoardControl={true}
        containerClass="mobileHiringCarousel"
    removeArrowOnDeviceType={["tablet", "mobile"]}
    renderDotsOutside={true}
    dotListClass="articleCarouselDots"
      responsive={{
        desktop: {
          breakpoint: {
            max: 3000,
            min: 1024
          },
          items: 3,
          partialVisibilityGutter: 40
        },
        mobile: {
          breakpoint: {
            max: 464,
            min: 0
          },
          items: 1,
          partialVisibilityGutter: 100
        },
        tablet: {
          breakpoint: {
            max: 1024,
            min: 464
          },
          items: 1
        }
      }}

      deviceType="mobile"
    >

    <div class="flip-card">
      <div class="flip-card-inner">
        <div class="flip-card-front"  onClick={(e) => showCard(e)} style={{cursor: 'pointer', zIndex: '5000'}}>
         <img src={HireTile1} />
         <FlexLayout itemSpacing="10px">
         <Paragraph className="sectionDescriptionBoldLarge hireTileTitle" style={{width: '70%'}}>Review your work</Paragraph>
         <FlexLayout alignItems="center" itemSpacing="15px" className="mobileHireArrow">
         <VerticalSeparator style={{height: '45px', backgroundColor: '#333333'}}/>
         <img id="hiringArrowImage" src={ArrowImage} />
         </FlexLayout>
         </FlexLayout>
        </div>
        <div class="flip-card-back" onClick={(e) => hideCard(e)}>
        <div class="card-description" style={{height: '500px', position: 'absolute', paddingTop: '65px'}}>

        <Paragraph className="sectionDescription hireTileDescription">This involves a screening of your resume, followed by a chat about your work and reviewing your projects</Paragraph>
        </div>

        </div>
      </div>
    </div>
    <div class="flip-card">
      <div class="flip-card-inner">
        <div class="flip-card-front"  onClick={(e) => showCard(e)} style={{cursor: 'pointer', zIndex: '5000'}}>
        <img src={HireTile2}/>
        <FlexLayout itemSpacing="10px">
        <Paragraph className="sectionDescriptionBoldLarge hireTileTitle" style={{width: '70%'}}>Meet the team</Paragraph>
        <FlexLayout alignItems="center" itemSpacing="15px" className="mobileHireArrow">
        <VerticalSeparator style={{height: '45px', backgroundColor: '#333333'}}/>
        <img id="hiringArrowImage" src={ArrowImage} />
        </FlexLayout>
        </FlexLayout>
        </div>
        <div class="flip-card-back" onClick={(e) => hideCard(e)}>
        <div class="card-description" style={{height: '500px', position: 'absolute', paddingTop: '65px'}}>

        <Paragraph className="sectionDescription hireTileDescription lastTile" >We already like you and would love to get to know you better. Meet the hiring manager and fellow designers from the team to talk more about your portfolio.</Paragraph>
        </div>

        </div>
      </div>
    </div>
    <div class="flip-card">
      <div class="flip-card-inner">
        <div class="flip-card-front"  onClick={(e) => showCard(e)} style={{cursor: 'pointer', zIndex: '5000'}}>
         <img src={HireTile3} />
         <FlexLayout itemSpacing="10px">
         <Paragraph className="sectionDescriptionBoldLarge hireTileTitle" style={{whitespace: 'no-wrap', width: '70%'}}>Work on a design task</Paragraph>
         <FlexLayout alignItems="center" itemSpacing="15px" className="mobileHireArrow">
         <VerticalSeparator style={{height: '45px', backgroundColor: '#333333'}}/>
         <img id="hiringArrowImage" src={ArrowImage} />
         </FlexLayout>
         </FlexLayout>
        </div>
        <div class="flip-card-back" onClick={(e) => hideCard(e)}>
        <div class="card-description" style={{height: '500px', position: 'absolute', paddingTop: '65px'}}>

        <Paragraph className="sectionDescription hireTileDescription lastTile" >Take the oportunity to put your analytical skills to the test. We're excited to see what you come up with!</Paragraph>
        </div>

        </div>
      </div>
    </div>
    <div class="flip-card">
      <div class="flip-card-inner">
        <div class="flip-card-front"  onClick={(e) => showCard(e)} style={{cursor: 'pointer', zIndex: '5000'}}>
         <img src={HireTile4} />
         <FlexLayout itemSpacing="10px">
         <Paragraph className="sectionDescriptionBoldLarge hireTileTitle" style={{width: '70%'}}>Meet the stakeholders</Paragraph>
         <FlexLayout alignItems="center" itemSpacing="15px" className="mobileHireArrow">
         <VerticalSeparator style={{height: '45px', backgroundColor: '#333333'}}/>
         <img id="hiringArrowImage" src={ArrowImage} />
         </FlexLayout>
         </FlexLayout>
        </div>
        <div class="flip-card-back" onClick={(e) => hideCard(e)}>
        <div class="card-description" style={{height: '500px', position: 'absolute', paddingTop: '65px'}}>

        <Paragraph className="sectionDescription hireTileDescription" >At this stage, we can already imagine you being part of our team. Meet the people you'll be working with!</Paragraph>
        </div>

        </div>
      </div>
    </div>

     </Carousel>


     <Title className="darkSectionSmallTitle" style={{ paddingTop: '36px'}}>Internships</Title>
     <Paragraph className="darkSectionDescription">A design internship at Nutanix is the real deal. Trust us, you won't need to get a hot drink for someone other than yourself. Instead, you get to work on complex features critical to our products together with experienced designers.</Paragraph>
     <Paragraph className="darkSectionDescription" style={{marginBottom: '60px' }}>Every year we offer two and six months student internship programs to hire bright talent. Read what former interns have to say about their experiences working with us.</Paragraph>
     <ArticleCard image={ArticleImage1} title="Keeping it Real: Our Nutanix Internship Experiences" desc="Internships are an invaluable part of one’s education on the road to becoming a UX…" link="https://medium.com/nutanix-design/keeping-it-real-our-nutanix-internship-experiences-e4bb34b8c7e0"/>
     <ArticleCard image={ArticleImage2} title="Nine Weeks with the Design Team at Nutanix India" desc="Looking back at my internship as a UX designer with Nutanix, what it was like…" link="https://medium.com/ux-planet/nine-weeks-with-the-design-team-at-nutanix-india-3408d2f549f5"/>

   </FlexLayout>



);



}
