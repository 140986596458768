import React, {useState, useEffect, useRef} from 'react';
import '../App.css';
import $ from 'jquery';
import '../careers/Careers.css';
import lottie from 'lottie-web';
import GamesImage from '../images/games.svg';
import RecognitionImage from '../images/recognition.svg';
import InspirationImage from '../images/inspiration.svg';
import UpskillingImage from '../images/upskilling.svg';
import CocreationImage from '../images/cocreation.svg';

import GrowthCard from '../components/GrowthCard';
import {
  Link,
  FlexItem,
  FlexLayout,
  Paragraph,
  Button,
  Title
} from '@nutanix-ui/prism-reactjs';


export default function Growth() {



return (
  <FlexLayout flexDirection="column">
    <FlexLayout justifyContent="space-between">
      <Title  size="h2" className="darkSectionTitle">Growth comes from within</Title>
      <Paragraph className="darkSectionDescription" style={{width: '80%'}}>While external sources and inspiration is essential for our work, there is nothing quite like supporting and fostering the growth of our fellow designers.</Paragraph>
    </FlexLayout>
    <section>
    <div className="growthGrid">
      <GrowthCard image={GamesImage} title="Fun & Games" description="We frequently meet to unwind with music, games, and laughter that keeps us energized and connected in our hybrid work environment"/>
        <GrowthCard image={RecognitionImage} title="Recognizing Peers" description="Expressing gratitude is a big part of how we collaborate. We appreciate our colleagues with excellence awards that are often given across teams"/>
          <GrowthCard image={InspirationImage} title="Sharing Inspiration" description="We enjoy sharing stories, anecdotes, and our inspirations with each other. These are catalysts for engaging conversations and keeping that creative spark flaring"/>
            <GrowthCard image={CocreationImage} title="Co-creation Sessions" description="We meet every week to brainstorm, ideate, and think out loud about a particular problem statement presented by any designer on the team"/>
              <GrowthCard image={UpskillingImage} title="Workshops" description="Along with learning on the job, we are encouraged to hone our skills with formal courses and workshops"/>





    </div>
    </section>

  </FlexLayout>
);


}
