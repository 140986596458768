import React, {useState, useEffect} from 'react';
import './App.css';
import TwitterLogo from './images/twitter.svg';
import MediumLogo from './images/medium.svg';
import FooterLogo from './images/footerLogo.svg';
import FooterEndImage from './images/footerEnd.svg';

import { NavLink, withRouter } from "react-router-dom";
import {
  Link,
  TextLabel,
  ExternalLinkIcon,
  FlexLayout,
  Paragraph,
  Button,
  ChevronRightIcon
} from '@nutanix-ui/prism-reactjs';


export default function MobileFooter() {




return (
  <FlexLayout flexDirection="column" className="mobileFooter">
   <img src={FooterLogo} style={{ width: '40px', height: '40px'}}/>
   <TextLabel className="brandColorTextRegular">Explore</TextLabel>
   <FlexLayout flexDirection="column" itemSpacing="5px">
     <FlexLayout alignItems="center" itemSpacing="10px">
             <NavLink to="/aboutUs" className="darkSectionDescriptionSmallFooter">About Us</NavLink>
       <ChevronRightIcon style={{color: '#9D9D9D'}}/>
     </FlexLayout>
     <TextLabel className="darkSectionDescriptionSmallSubHeader" >People + Processes</TextLabel>
   </FlexLayout>
   <FlexLayout flexDirection="column" itemSpacing="5px">
     <FlexLayout alignItems="center" itemSpacing="10px">
       <NavLink to="/designSystem" className="darkSectionDescriptionSmallFooter">Design System</NavLink>
       <ChevronRightIcon style={{color: '#9D9D9D'}}/>
     </FlexLayout>
     <TextLabel className="darkSectionDescriptionSmallSubHeader" >Guides + Frameworks</TextLabel>
   </FlexLayout>

   <FlexLayout flexDirection="column" itemSpacing="5px">
     <FlexLayout alignItems="center" itemSpacing="10px">
           <NavLink to="/careers" className="darkSectionDescriptionSmallFooter">Careers</NavLink>
       <ChevronRightIcon style={{color: '#9D9D9D'}}/>
     </FlexLayout>
     <TextLabel className="darkSectionDescriptionSmallSubHeader" >Openings in team</TextLabel>
   </FlexLayout>
   <FlexLayout flexDirection="column" itemSpacing="5px">
     <FlexLayout alignItems="center" itemSpacing="10px">
             <Link href="https://www.nutanix.com/" target="_blank" className="darkSectionDescriptionSmallFooter">About Nutanix</Link>
       <ExternalLinkIcon style={{color: '#9D9D9D', height: '10px'}}/>
     </FlexLayout>
     <TextLabel className="darkSectionDescriptionSmallSubHeader" >Our Products & Solutions</TextLabel>
   </FlexLayout>

   <TextLabel className="brandColorTextRegular">Follow us</TextLabel>
   <FlexLayout>
     <Link href="https://twitter.com/NutanixDesign" target="_blank"><img src={TwitterLogo} style={{width: '18px', height: '18px'}}/></Link>

     <Link href="https://medium.com/nutanix-design" target="_blank"><img src={MediumLogo} style={{width: '18px', height: '18px'}}/></Link>
   </FlexLayout>
   <img src={FooterEndImage} style={{width: '208px', height: '23px'}}/>
  </FlexLayout>
);


}
