import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import ReactGA from 'react-ga';
import {
  HashRouter
} from 'react-router-dom';

import { Provider } from 'react-redux';
import globalStore from './stores/globalStore';
import gaProperty from './analytics/gaProperty';
import { localStorageProp } from './data/initialValues';

import 'whatwg-fetch';
import 'url-search-params-polyfill';

globalStore.subscribe(() => {
  localStorage.setItem(localStorageProp, JSON.stringify(globalStore.getState()));
});

if (gaProperty) {
  console.log('GA Property Name --- ', gaProperty.name);
  ReactGA.initialize(gaProperty.prop);
}

ReactDOM.render(
  <Provider store={ globalStore }>
    <HashRouter>
      <App />
    </HashRouter>
  </Provider>,
  document.getElementById('root')
);
