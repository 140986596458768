import React, {useState, useRef, useEffect} from 'react';
import '../App.css';
import lottie from 'lottie-web';
import {
  FlexLayout,
  Paragraph,
  Button,
  Title
} from '@nutanix-ui/prism-reactjs';

export default function GrowthCard(props) {
  useEffect(() => {
    Array.from(document.getElementsByClassName('growthCard')).forEach((item, i) => {

      item.addEventListener('focus', function () {
           item.classList.add('growthCardHover');
      });
      item.addEventListener('blur', function () {
           item.classList.remove('growthCardHover');
      });
    });

  });

return (
  <FlexLayout flexDirection="column" tabIndex="0" alignItems="flex-start" itemSpacing="10px" className="growthCard" >

     <img src={props.image} className="growthImage"/>
     <Title size="h3" className="growthCardTitle">{props.title}</Title>

    <Paragraph className="growthCardDesc">{props.description}</Paragraph>

   </FlexLayout>



);


}
