import React from 'react';
import '../App.css';
import Arrow from '../images/lightPurpleArrow.svg';
import {
  Menu,
  MenuItem,
  Dropdown,
  Link,
  Tabs,
  Divider,
  TextLabel,
  FlexItem,
  StackingLayout,
  FlexLayout,
  NavBarLayout,
  ShareArrowIcon,
  ExternalLinkIcon,
  AlertIcon,
  Paragraph,
  Badge,
  Button,
  Title,
  Progress,
  ThemeManager
} from '@nutanix-ui/prism-reactjs';

export default function MobileArticleCard(props) {




return (
  <FlexLayout flexDirection="column" style={{background: '#1D1D1D', borderRadius: '4px'}}>
   <img src={props.image} />
   <FlexLayout flexDirection="column" itemSpacing="0px" style={{padding: '20px'}}>
      <Paragraph className="lightbrandColorText" >{props.title}</Paragraph>
      <Paragraph style={{color: '#C6BEFE'}} className="darkSectionDescriptionSmall">{props.desc}</Paragraph>
      <Link href={props.link} target="_blank"><img src={Arrow} style={{width: '20px', height: '14px', marginTop: '10px'}}/></Link>
    </FlexLayout>



  </FlexLayout>
);


}
