import React from 'react';
import '../App.css';
import '../careers/Careers.css';
import ArticleImage from '../images/internshipArticleImage1.svg';
import {
  Menu,
  MenuItem,
  Dropdown,
  Link,
  Tabs,
  Divider,
  TextLabel,
  FlexItem,
  StackingLayout,
  FlexLayout,
  NavBarLayout,
  ShareArrowIcon,
  ExternalLinkIcon,
  AlertIcon,
  Paragraph,
  Accordion,
  ChevronDownIcon,
  Badge,
  Button,
  Title,
  Progress,
  ThemeManager
} from '@nutanix-ui/prism-reactjs';

export default function FAQQuestions(props) {




return (
  <StackingLayout style={{paddingLeft: '10px'}}>
  <Accordion titleProps={{ className:"faqQuestionsText" }}  className="faqAccordion" renderToggleItem={ (props) => (

    <Link { ...props }>
    <ChevronDownIcon style={{ color: 'black', height: '12px', width: '12px'}}/>
  </Link>

) }title={props.question}>
 <Paragraph className="sectionDescriptionSmall faqAnswers" forceMultiLineHeight={ true }>
 {props.answer}
 </Paragraph>
 </Accordion>
 </StackingLayout>
);


}
