import React, {useState, useRef, useEffect} from "react";
import { NavLink, Link, withRouter } from "react-router-dom";
import './App.css';
import lottie from 'lottie-web';
import animationData from './animations/mobileMenuAnimation.json';
import MobileLogo from './images/NutanixNameLogo.svg';
import Logo from './images/nutanixLogo.svg';
import {
  Divider,
  TextLabel,
  StackingLayout,
  FlexLayout
} from '@nutanix-ui/prism-reactjs';

const Nav = () => {

const [isNavExpanded, setNavExpanded] = useState(false);
const [nav, setNav] = useState(true);



  let hamburgerLottie = useRef(null);

  const myRef = useRef(null);

function handleNavSelection() {
  if(window.location.hash === '#/designSystem') {
    return '.DESIGN SYSTEM';
  } else if(window.location.hash === '#/careers') {
      return '.CAREERS';
  } else if(window.location.hash === '#/aboutUs') {
      return '.ABOUT US';
  } else if(window.location.hash === '#/') {
      return '.DESIGN';
  }
}
const handleKeyDown = (event, navItems) => {

  const currentFocusIndex = Array.from(navItems).indexOf(document.activeElement);

  if (event.key === "ArrowRight") {
  event.preventDefault();
  if (currentFocusIndex < navItems.length - 1) {
    let nextIndex = currentFocusIndex + 1;
    navItems[nextIndex].focus();
  }
} else if (event.key === "ArrowLeft") {
  event.preventDefault();
  if (currentFocusIndex > 0) {
    let prevIndex = currentFocusIndex - 1;
    navItems[prevIndex].focus();
  }
} else if (event.key === "Tab") {
    event.preventDefault();
    const skipButton = document.querySelector('#skipButton');
    skipButton.focus();
  }
};

useEffect(() => {
   hamburgerLottie.current = lottie.loadAnimation({
    container: myRef.current,
    renderer: "svg",
    loop: false,
    autoplay: false,
    animationData: animationData
  });
  const navItems = document.querySelectorAll(".navButton a");



  navItems.forEach((item) => {
    item.setAttribute("tabindex", "0");
    item.addEventListener("keydown", (event) => handleKeyDown(event, navItems));
  });

}, []);

function changeNavStatus() {

  if(!isNavExpanded) {
    hamburgerLottie.current.playSegments([0, 30], true);

    setNavExpanded(true);

  } else {

    hamburgerLottie.current.playSegments([30, 60], true);

    setNavExpanded(false);

  }


}


  return (
    <StackingLayout>

    <FlexLayout className="navigation">




     <header>
     <FlexLayout alignItems="center">

      <img src={Logo} style={{marginRight: '40px'}}/>


      <nav>
      <span className="navButton" id="homeNav">

        <NavLink to="/" exact strict>.Design</NavLink>

      </span>

      <span className="navButton" id="startNav">
        <NavLink to="/aboutUs" exact={true} strict className="navButtonLink">About Us</NavLink>
      </span>

      <span className="navButton" id="startNav">
        <NavLink to="/designSystem">Design System</NavLink>
      </span>

      <span className="navButton" id="careersNav">
        <NavLink to="/careers">Careers</NavLink>
      </span>
      </nav>

    </FlexLayout>
    </header>
    </FlexLayout>
    <FlexLayout className="mobileNavigation" justifyContent="space-between" alignItems="center">
       <FlexLayout itemSpacing="0px" alignItems="center">
        <img src={MobileLogo} style={{height: '10px', paddingRight: '1px'}}/>
       </FlexLayout>
       <div ref={myRef} style={{width: '12px', height: '16px'}} onClick={() =>changeNavStatus()}></div>

    </FlexLayout>
    <FlexLayout className={isNavExpanded ? 'mobileExpanded' : 'mobileCollapsed'} flexDirection="column" alignItems="center">

       <FlexLayout flexDirection="column" alignItems="flex-end" itemSpacing="40px" className="mobileNavLinks">
      <NavLink to="/" exact strict onClick={() =>changeNavStatus()}>.DESIGN</NavLink>
      <Divider style={{borderColor: '#404040', width: '241px'}}/>
        <NavLink to="/aboutUs" exact={true} onClick={() =>changeNavStatus()}>ABOUT US</NavLink>
          <Divider style={{borderColor: '#404040', width: '241px'}}/>
          <NavLink to="/designSystem" onClick={() =>changeNavStatus()} >DESIGN SYSTEM</NavLink>
            <Divider style={{borderColor: '#404040', width: '241px'}}/>
            <NavLink to="/careers" onClick={() =>changeNavStatus()}>CAREERS</NavLink>
            </FlexLayout>

    </FlexLayout>


    </StackingLayout>
  );
};

export default withRouter(Nav);
