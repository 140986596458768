import React, {useState, useRef, useEffect} from 'react';
import '../App.css';
import lottie from 'lottie-web';
import {
  FlexLayout,
  Paragraph,
  Button,
  Title
} from '@nutanix-ui/prism-reactjs';

export default function SideQuestCard(props) {
  const myLottie = useRef(null);

  const myRef = useRef(null);

  useEffect(() => {
    myLottie.current = lottie.loadAnimation({
     container: myRef.current,
     renderer: "svg",
     loop: false,
     autoplay: false,
     animationData: props.animationData
   });

   return () => {
     myLottie.current.destroy();
   };
  }, [])

  const playReverse = () => {
    if(myLottie) {
    myLottie.current.setDirection(-1);
    myLottie.current.play();
  }
  };
  const playForward = () => {
    if(myLottie) {
    myLottie.current.setDirection(1);
    myLottie.current.play();
  }
  };

return (
  <FlexLayout flexDirection="column" alignItems="flex-start" tabindex="0" itemSpacing="10px" className="sideQuest" onMouseEnter={()=>{playForward()}}
   onMouseLeave={()=>{playReverse()}}>

     <div ref={myRef} style={{width: '40px', height: '40px'}}></div>
     <Title size="h4" className="sectionDescriptionBold">{props.title}</Title>

    <Paragraph className="sectionDescriptionSmall">{props.description}</Paragraph>

   </FlexLayout>



);


}
