import React from 'react';

import { useSelector } from 'react-redux';

import {
  useHistory
} from 'react-router-dom';

import {
  FlexLayout,
  Link,
  Paragraph
} from '@nutanix-ui/prism-reactjs';

function OutOfScope({ path }) {
  const history = useHistory();
  const stepsActive = useSelector(state => state.studyActiveLevels.steps);

  return <FlexLayout
    className="out-of-scope"
    alignItems="center"
    justifyContent="center"
    flexDirection="column"
    flexGrow="1"
  >
    <Paragraph>
      This content is out of the prototype scope.
    </Paragraph>
    <Paragraph>
      Return to the <Link onClick={ () => {
        history.push(path || '/');
      } }>main page</Link> { stepsActive
        ? ' or click the "Hint" next to the step number for more info'
        : ''
      }.
    </Paragraph>
  </FlexLayout>;
}

export default OutOfScope;
