import React from 'react';
import './App.css';
import BangaloreImage from './images/Bangalore.png';
import BerlinImage from './images/Berlin.png';
import SanJoseImage from './images/San Jose.png';
import { NavLink, useHistory } from "react-router-dom";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import {
  FlexLayout,
  Paragraph,
  Button,
  Title

} from '@nutanix-ui/prism-reactjs';

export default function About() {

  const history = useHistory();
  const responsive = {
    desktop: {
      breakpoint: { max: 4000, min: 1024 },
      items: 1,
      slidesToSlide: 1 // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
      slidesToSlide: 1 // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1 // optional, default to 1.
    }
  };

  const options = {
    margin: 20,
loop: false,
items: 1,
stagePadding: 100
   };
   const redirectToAbout = (tabName) => {
       history.push(`/aboutUs`);
     };

return (
  <FlexLayout justifyContent="space-between" alignItems="center">
 <FlexLayout  justifyContent="flex-end" alignItems="center" className="aboutMainDiv">

 <OwlCarousel className="peopleCarousel" {...options}>
     <div className="peopleItem"><img src={BangaloreImage} alt="A collage of pictures of team members across geos"/></div>
     <div className="peopleItem"><img src={BerlinImage} alt="A collage of pictures of team members across geos"/></div>
     <div className="peopleItem"><img src={SanJoseImage} alt="A collage of pictures of team members across geos"/></div>
</OwlCarousel>
 </FlexLayout>
    <FlexLayout flexDirection="column" itemSpacing="40px" style={{width: '50%', marginLeft: '60px', flex: '1 1 0px'}}>
      <Title size="h2" className="sectionTitle" style={{width: '90%'}}>We are a Motley Crew</Title>
      <Paragraph className="sectionDescription" style={{width: '90%'}}> Our growing team of 50+ designers across Bangalore, Berlin, and San José come in all shapes and sizes. We research, design UX, generate content, visualize data, illustrate, and do visual design to collaboratively craft exceptional experiences.</Paragraph>

      <Button style={{alignSelf: 'flex-start', zIndex: '5000'}} className="linkButton" id="learnButton" onClick={redirectToAbout} aria-label="Learn more about the people">Learn more <div class="buttonArrow"></div></Button>

    </FlexLayout>




  </FlexLayout>
);


}
