const gaProperties = [
  // {
  //   name: '[Prototype Name] - Private',
  //   hostMatch: 'prototypes.design.nutanix.com',
  //   prop: 'UA-000000000-0' // from https://analytics.google.com/
  // },
  // {
  //   name: '[Prototype Name] - Public',
  //   hostMatch: 'nutanix.design',
  //   prop: 'UA-000000000-0' // from https://analytics.google.com/
  // },
  // {
  //   name: '[Prototype Name] - Public',
  //   hostMatch: 'sandbox.design.nutanix.com',
  //   prop: 'UA-000000000-0' // from https://analytics.google.com/
  // },
  // {
  //   name: '[Prototype Name] - Dev',
  //   hostMatch: 'localhost',
  //   prop: 'UA-000000000-0' // from https://analytics.google.com/
  // }
];

export default (function(){
  const host = window.location.host;
  return gaProperties.find(gaProperty => host.includes(gaProperty.hostMatch));
}());
