import { capitalize } from '../utils/utils';

const prototypeName = 'yourPrototype';

const v = process.env.REACT_APP_VERSION || 'dev';

const appSettingsMaps = {
  dev: {
    // analyticsProperty: 'UA-XXXXXXXXX-X',
    // hardResetOnly: true,
    name: 'Development',
    theme: 'light',
    prototypeSettings: true,
    themeSwitcher: true,
    studyActive: false,
    firestoreCollection: 'study-dev'
  },
  prod: {
    hardResetOnly: true,
    name: 'Production',
    theme: 'dark',
    prototypeSettings: true,
    themeSwitcher: false,
    studyActive: true,
    firestoreCollection: 'study-dev'
  }
};

export const appSettings = appSettingsMaps[v];

console.log('--- App Setting: ', appSettings.name);

const initialTheme = appSettings.theme === 'random'
  ? (Math.floor(Math.random() * 2) ? 'dark' : 'light')
  : appSettings.theme;

const linkStart = '{link}';
const linkEnd = '{/link}';
const lineBreak = '{br}';
const badgeStart = '{badge}';
const badgeEnd = '{/badge}';

export const themes = {
  initial: 'light',
  opposite: initialTheme === 'light' ? 'dark' : 'light'
};

export const tags = {
  badgeStart,
  badgeEnd,
  linkStart,
  linkEnd,
  lineBreak
};

const prototypeNameWithVersion = `${ prototypeName }${ capitalize(v) }`;

export const localStorageProp = `${ prototypeNameWithVersion }State`;
export const localStorageDevMode = `${ prototypeNameWithVersion }DevMode`;

export const studyTiming = 500;
