import React, {useState, useRef} from 'react';
import '../App.css';
import { NavLink, withRouter } from "react-router-dom";
import UXDesign from '../images/rolesUXDesign.svg';
import VisualDesign from '../images/rolesVisualDesign.svg';
import DesignOps from '../images/rolesDesignOps.svg';
import DesignResearch from '../images/rolesDesignResearch.svg';
import UXEngineering from '../images/rolesUXEngineering.svg';
import ContentDesign from '../images/rolesContentDesign.svg';
import BasicCard from '../components/BasicCard';
import Skills from './Skills';
import SkillTabs from '../components/SkillTabs';
import {
  Link,
  FlexLayout,
  Paragraph,
  Button,
  Tabs,
  StackingLayout,
  Title,
  CloseIcon,
  Modal
} from '@nutanix-ui/prism-reactjs';


const Roles = () => {


  const [modalState, setModalState] = useState(false);
  const [activeKey, setActiveKey] = useState(1);


  const data = [

    {
      title: 'User Experience',
      key: 1
    },
    {
      title: 'Visual Design',
      key: 2
    },
    {
      title: 'Research',
      key: 3
    },
    {
      title: 'Content Design',
      key: 4
    },
    {
      title: 'UX Engineering',
      key: 5
    },
    {
      title: 'Design Ops',
      key: 6
    }
  ];


  function handleOnClose() {
    setModalState(false);
  }
  function handleOpen(activeKey) {
    setModalState(true);
    setActiveKey(activeKey);

  }
return (
  <FlexLayout className="roles" justifyContent="space-between">

    <FlexLayout flexDirection="column" style={{width: '35%'}}>
      <Title  size="h2" className="darkSectionTitle" >We have many talents</Title>
      <Paragraph className="darkSectionDescription" style={{paddingTop: '33px'}}>Depending on the requirements of the project, we can take on many roles. Interested in customer research, flawless UX with simplicity in mind, or pixel-perfect visual design?</Paragraph>
      <Paragraph className="darkSectionDescription">We got it covered.</Paragraph>
    </FlexLayout>
    <article>
    <div className="roles-tiles">

       <BasicCard image={UXDesign} classname="rolesCard" text="User Experience" data-test-id="open-modal" clickHandler={handleOpen} activeKey={1}/>
       <Modal
           visible={ modalState }
           className="rolesModal"
           footer={false}
           tabIndex="0"
           title="We have many talents"
           onClose={ handleOnClose }
           primaryButtonOnClick={ handleOnClose }

         >
           <StackingLayout padding="20px">

             <Skills activeKey={activeKey}/>
           </StackingLayout>
         </Modal>


       <BasicCard image={VisualDesign} classname="rolesCard" text="Visual Design" data-test-id="open-modal" clickHandler={handleOpen} activeKey={2}/>


       <BasicCard image={DesignResearch} classname="rolesCard" text="Design Research" data-test-id="open-modal" clickHandler={handleOpen} activeKey={3}/>


       <BasicCard image={ContentDesign} classname="rolesCard" text="Content Design" data-test-id="open-modal" clickHandler={handleOpen} activeKey={4}/>

       <BasicCard image={UXEngineering} classname="rolesCard" text="UX Engineering" data-test-id="open-modal" clickHandler={handleOpen} activeKey={5}/>

       <BasicCard image={DesignOps} classname="rolesCard" text="Design Ops" data-test-id="open-modal" clickHandler={handleOpen} activeKey={6}/>



    </div>
    </article>

  </FlexLayout>
);


}


export default withRouter(Roles);
