import React from 'react';
import '../App.css';
import TeamImage1 from '../images/people1.svg';
import TeamImage2 from '../images/people2.svg';
import TeamImage3 from '../images/people3.svg';
import TeamImage4 from '../images/people4.svg';
import TeamImage5 from '../images/people5.svg';
import TeamImage6 from '../images/people6.svg';
import PlaceholderImage from '../images/people1.png';
import lottie from 'lottie-web';
import LazyLoad from 'react-lazyload';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import ArrowImage from '../images/arrow.svg';
import { NavLink, withRouter, useHistory } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import {
  Link,
  FlexItem,
  StackingLayout,
  FlexLayout,
  NavBarLayout,
  Paragraph,
  Button,
  Title
} from '@nutanix-ui/prism-reactjs';


export default function People() {


  const history = useHistory();
   const options = {
     margin: 20,
 loop: false,
 items: 1,
 stagePadding: 100
    };



    const redirectToCareers = (tabName) => {
        history.push(`/designSystem`);
      };


return (
  <FlexLayout className="people" justifyContent="space-between">

      <FlexLayout flexDirection="column" itemSpacing="40px" className="peopleDescription">

        <Title size="h1" className="visuallyHidden">Introduction of Nutanix Design Team</Title>
        <Title size="h2" className="sectionTitle" style={{width: '60%'}} >The Power of Many</Title>
            <Paragraph className="sectionDescription" style={{width: '70%'}}> Collaboration is key when building a great product. We work in synergy with product and engineering teams to ensure that what we’re making helps businesses do better business.

</Paragraph>
<Paragraph className="sectionDescription" style={{width: '80%', marginTop: '0px'}}>
During this process, our design principles help everybody on board stay focussed on our shared vision and values.
</Paragraph>

  <Button style={{alignSelf: 'flex-start', zIndex: '5000'}} className="linkButton" id="principlesButton" onClick={redirectToCareers}>Our Principles <div class="buttonArrow"></div></Button>
      </FlexLayout>

  <FlexLayout justifyContent="center" alignItems="center" style={{background: '#F6F6F6'}} >
     <OwlCarousel className="peopleCarousel" {...options}>
         <div className="peopleItem"><img src="./static/media/people1.4706675f.svg" /></div>
         <div className="peopleItem"><img src={TeamImage2} /></div>
         <div className="peopleItem"><img src={TeamImage3} /></div>
         <div className="peopleItem"><img src={TeamImage4} /></div>
         <div className="peopleItem"><img src={TeamImage5} /></div>
         <div className="peopleItem"><img src={TeamImage6} /></div>

         </OwlCarousel>

</FlexLayout>
  </FlexLayout>
);


}
