import React from 'react';
import '../App.css';
import { NavLink } from "react-router-dom";

import TeamImage1 from '../images/people1.svg';
import TeamImage2 from '../images/people2.svg';
import TeamImage3 from '../images/people3.svg';
import TeamImage4 from '../images/people4.svg';
import TeamImage5 from '../images/people5.svg';
import TeamImage6 from '../images/people6.svg';
import ArrowImage from '../images/arrow.svg';
import Carousel from 'react-multi-carousel';
import PlaceholderImage from '../images/people1.jpg';
import 'react-multi-carousel/lib/styles.css';
import { LazyLoadImage } from "react-lazy-load-image-component";
import {

  Link,
  FlexItem,
  FlexLayout,
  Paragraph,
  Button,
  Title
} from '@nutanix-ui/prism-reactjs';


export default function MobilePeople() {




return (
  <FlexLayout flexDirection="column" itemSpacing="20px">
  <Carousel
     partialVisible={true}
     showDots={true}
     swipeable={true}
     draggable={true}
     ssr={true}
     keyBoardControl={true}
     itemClass="mobilePeopleItems"
     containerClass="mobilePeopleCarousel"
 removeArrowOnDeviceType={["tablet", "mobile"]}
 renderDotsOutside={true}
 dotListClass="articleCarouselDots"
   responsive={{
     desktop: {
       breakpoint: {
         max: 3000,
         min: 1024
       },
       items: 3,
       partialVisibilityGutter: 40
     },
     mobile: {
       breakpoint: {
         max: 464,
         min: 0
       },
       items: 1,
       partialVisibilityGutter: 60
     },
     tablet: {
       breakpoint: {
         max: 1024,
         min: 464
       },
       items: 1
     }
   }}

   deviceType="mobile"
 >
 <LazyLoadImage src={TeamImage1}
width={300} height={300}
placeholderSrc={PlaceholderImage}
effect="blur"
alt="Image Alt"
/>
 <img src={TeamImage2} style={{ width: '300px', height: '300px'}}/>
 <img src={TeamImage3} style={{ width: '300px', height: '300px'}}/>
 <img src={TeamImage4} style={{ width: '300px', height: '300px'}} />
 <img src={TeamImage5}  style={{ width: '300px', height: '300px'}}/>
 <img src={TeamImage6}  style={{ width: '300px', height: '300px'}}/>
 </Carousel>
    <Title className="sectionTitle" style={{paddingTop: '80px'}}>The Power of Many</Title>
    <Paragraph className="sectionDescription">Collaboration is key when building a great product. We work in synergy with product and engineering teams to ensure that what we’re making helps businesses do better business.</Paragraph>
    <Paragraph className="sectionDescription">During this process, our design principles help everybody on board stay focussed on our shared vision and values.</Paragraph>
    <Button style={{alignSelf: 'flex-start'}} className="linkButton"><NavLink to='/designSystem'>Our Principles<img src={ArrowImage}/></NavLink></Button>
    <FlexItem>

    </FlexItem>


  </FlexLayout>
);


}
