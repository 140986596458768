import React from 'react';
import './App.css';
import  ArrowImage from './images/arrow.svg';
import  IntroImage from './images/introImage.svg';
import { NavLink, withRouter } from "react-router-dom";
import {
  FlexLayout,
  Paragraph,
  Button,
  Title
} from '@nutanix-ui/prism-reactjs';

export default function MobileIntro() {




return (
  <FlexLayout flexDirection="column" itemSpacing="20px">

    <img src={IntroImage} className="fullBleedImage"/>
    <Title className="sectionTitle" style={{ width: '95%', paddingTop: '80px'}}>We love to start with a 'Why'</Title>

    <Paragraph className="sectionDescription">We are a notoriously curious breed with a user-centric mindset. Design is our core, and we love creating seamless experiences that simplify the enterprise world. With design and great attention to detail, we allow our customers to achieve more by doing less.</Paragraph>


        <Paragraph className="sectionDescription" style={{marginTop: '0px'}}>Want to be a part of this mission ? </Paragraph>
        <Button style={{alignSelf: 'flex-start'}} className="linkButton"><NavLink to='/careers'>Explore Open Roles<img src={ArrowImage}/></NavLink></Button>







  </FlexLayout>
);


}
