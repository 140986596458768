import React, {useState, useEffect} from 'react';
import '../App.css';
import  ArrowImage from '../images/lightPurpleArrow.svg';
import {
  Menu,
  MenuItem,
  Dropdown,
  Link,
  Tabs,
  Divider,
  TextLabel,
  FlexItem,
  StackingLayout,
  FlexLayout,
  NavBarLayout,
  ShareArrowIcon,
  ExternalLinkIcon,
  AlertIcon,
  Paragraph,
  Badge,
  Button,
  Title,
  Progress,
  ThemeManager
} from '@nutanix-ui/prism-reactjs';

export default function ArticleTiles(props) {
  useEffect(() => {

  window.addEventListener('resize', handleWindowSizeChange);
  }, []);


  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  const isMobile = width <= 768;

  function truncateTextWithDots(text) {

    var lines = text.split('.');
    var truncatedText = lines[0].trim();
    return truncatedText + '...';
  }

return (
  <FlexLayout flexDirection="column" className="articleTile" style={{flex: '1 1 0px'}}>
   {isMobile ? null : <div style={{position: 'relative', flexGrow: '0.5'}}><img src={props.image} className="articleImage" /></div>}
   <FlexLayout flexDirection="column" itemSpacing="10px" className="articleTileDescription" >
     <Title size="h3" className="darkSectionDescriptionMedium taleArticleTitle">{props.title}</Title>
     <Paragraph className="darkSectionArticleDescriptionSmall">{truncateTextWithDots(props.description)}</Paragraph>
     {isMobile ? <Link href={props.link} target="_blank"><FlexLayout alignItems="center" justifyContent="center" itemSpacing="5px" style={{cursor: 'pointer'}}><Link className="articleLinkSmall" style={{color: '#AC9BFD'}}>View Details </Link><img src={ArrowImage} style={{width: '14px', height: '10px'}}/></FlexLayout></Link> : <Link href={props.link} target="_blank" className="articleParentLink"><FlexLayout alignItems="center" justifyContent="center" itemSpacing="5px" style={{cursor: 'pointer', textDecoration: 'none', zIndex: '5000', alignSelf: 'flex-end'}} className="articleLink">View Details <div className="articleArrow"></div></FlexLayout></Link>}
   </FlexLayout>


  </FlexLayout>
);


}
