import React, {useState} from 'react';
import './Careers.css';
import coursesAnimationData from './animations/coursesAnimation.json';
import conferenceAnimationData from './animations/conferenceAnimation.json';
import hackathonAnimationData from './animations/hackathonAnimation.json';
import teamWorkshopAnimationData from './animations/teamWorkshopAnimation.json';
import CareerLadder from '../components/CareerLadder';
import SideQuestCard from '../components/SideQuestCard';


import {
  FlexLayout,
  Paragraph,
  Title
} from '@nutanix-ui/prism-reactjs';

export default function Growth() {

return (
  <FlexLayout flexDirection="column">
    <Title size="h2" className="sectionTitle">Growth at work</Title>
    <CareerLadder />
    <FlexLayout justifyContent="space-between" style={{paddingTop: '100px'}}>
    <FlexLayout flexDirection="column" style={{flexBasis: '33.33%'}} >
    <Title size="h3" className="sectionDescriptionBoldXtraLarge">Side quests</Title>
    <FlexLayout flexDirection="column">
    <Paragraph className="sectionDescription" style={{paddingRight: '70px'}}>Good UX involves collaboration and continuous learning. We support our designers in their personal and professional growth on our journey to building better products.</Paragraph>
    <Paragraph className="sectionDescription" style={{paddingRight: '70px'}}>We also encourage them to upskill and form a strong network of support and constant development.</Paragraph>
    </FlexLayout>



      </FlexLayout>

      <FlexLayout style={{flexBasis: '66.66%'}}>
      <section>
      <FlexLayout flexDirection="column">
      <FlexLayout>
      <SideQuestCard animationData={coursesAnimationData} title="Courses & subscriptions" description="Level yourself up with free e-learning subscriptions covering topics like design, development, or management."/>
      <SideQuestCard animationData={teamWorkshopAnimationData} title="Team Workshops" description="Participate in facilitated discussions, share new ideas and learn from your colleagues."/>

      </FlexLayout>
      <FlexLayout>
        <SideQuestCard animationData={hackathonAnimationData} title="Hackathons" description="Create novel solutions in fast sprints together with engineering and Product Management stakeholders."/>
        <SideQuestCard animationData={conferenceAnimationData} title="Conferences" description="Be part of the design community, learn or even actively talk about design growth and impact, and network with peers."/>

      </FlexLayout>
    </FlexLayout>
    </section>
   </FlexLayout>

    </FlexLayout>




  </FlexLayout>
);


}
