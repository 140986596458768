import React, {useState} from 'react';
import '../App.css';
import { NavLink, withRouter } from "react-router-dom";
import UserExperienceLogo from '../images/userExperienceLogo.svg';
import VisualDesignLogo from '../images/visualDesignLogo.svg';
import UXEngineeringLogo from '../images/uxEngineeringLogo.svg';
import ContentDesignLogo from '../images/contentDesignLogo.svg';
import DesignOpsLogo from '../images/designOpsLogo.svg';
import DesignResearchLogo from '../images/designResearchLogo.svg';
import MobileSkills from './MobileSkills';
import {
  FlexLayout,
  Paragraph,
  Title,
  Modal,
  StackingLayout
} from '@nutanix-ui/prism-reactjs';


const MobileRoles = () => {

    const [modalState, setModalState] = useState(false);
    const [activeKey, setActiveKey] = useState(1);
  function handleOnClose() {
    setModalState(false);
  }
  function handleOpen(activeKey) {
    setModalState(true);
    setActiveKey(activeKey);

  }

return (
  <FlexLayout flexDirection="column" itemSpacing="20px">
    <Title className="darkSectionTitle">We have many talents</Title>
    <Paragraph className="darkSectionDescription">Depending on the requirements of the project, we can take on many roles. Interested in customer research, flawless UX with simplicity in mind, or pixel-perfect visual design?</Paragraph>
      <Paragraph className="darkSectionDescription">We got it covered.</Paragraph>
    <FlexLayout flexDirection="column" style={{paddingTop: '20px'}}>

      <NavLink to={{ pathname: "/mobileSkills", state: 1}}><FlexLayout className="mobileAccordion" activeKey={1}>
      <img src={UserExperienceLogo}/>
      <Paragraph className="basicCardTitle">User Experience</Paragraph>
      </FlexLayout>
      </NavLink>
      <NavLink to={{ pathname: "/mobileSkills", state: 2}}><FlexLayout className="mobileAccordion" activeKey={2}>
      <img src={VisualDesignLogo}/>
      <Paragraph className="basicCardTitle">Visual Design</Paragraph>
      </FlexLayout>
      </NavLink>
      <NavLink to={{ pathname: "/mobileSkills", state: 3}}><FlexLayout className="mobileAccordion" activeKey={3}>
      <img src={DesignResearchLogo}/>
      <Paragraph className="basicCardTitle">Design Research</Paragraph>
      </FlexLayout>
      </NavLink>
      <NavLink to={{ pathname: "/mobileSkills", state: 4}}><FlexLayout className="mobileAccordion" activeKey={4}>
      <img src={UXEngineeringLogo}/>
      <Paragraph className="basicCardTitle">Content Design</Paragraph>
      </FlexLayout>
      </NavLink>
      <NavLink to={{ pathname: "/mobileSkills", state: 5}}><FlexLayout className="mobileAccordion" activeKey={5}>
      <img src={ContentDesignLogo}/>
      <Paragraph className="basicCardTitle">UX Engineering</Paragraph>
      </FlexLayout>
      </NavLink>
      <NavLink to={{ pathname: "/mobileSkills", state: 6}}><FlexLayout className="mobileAccordion" activeKey={6}>
      <img src={DesignOpsLogo}/>
      <Paragraph className="basicCardTitle">Design Ops</Paragraph>
      </FlexLayout>
      </NavLink>






    </FlexLayout>


  </FlexLayout>
);


}


export default withRouter(MobileRoles);
