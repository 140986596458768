import React, { useState } from 'react';

import {
  Router,
  Route,
  Switch,
  useHistory,
  useLocation
} from 'react-router-dom';

import {
  Badge,
  Button,
  ContainerLayout,
  DashboardWidgetHeader,
  DashboardWidgetLayout,
  Dropdown,
  FlexLayout,
  Menu,
  MenuItem,
  // MultiSelect,
  // Paragraph,
  PlusIcon,
  Select,
  Separator,
  Tabs,
  TextLabel,
  Title,
  VerticalSeparator,
  WidgetTable
} from '@nutanix-ui/prism-reactjs';

import {
  processPathname
} from '../../utils/utils';

import OutOfScope from '../../components/OutOfScope';

export default function (props) {
  const {
    match,
    // history
  } = props;

  const history = useHistory();
  const location = useLocation();

  const defaultSupPageId = 'list'; //tabs.find(tab => tab.default).key;
  const pathLevel2 = processPathname(location, 2);
  const pathLevel3 = processPathname(location, 3);
  const [level2, setLevel2] = useState(pathLevel2 || defaultSupPageId);
  const [level3, setLevel3] = useState(pathLevel3 ? [pathLevel3] : []);
  const [metricsOpen, setMetricsOpen] = useState(false)

  history.listen((newLocation) => {
    const newPathLevel2 = processPathname(newLocation, 2);
    const newPathLevel3 = processPathname(newLocation, 3);
    setLevel2(newPathLevel2 || defaultSupPageId);
    setLevel3(newPathLevel3 ? [newPathLevel3] : []);
  });

  function formatLevel(level) {
    return level.split('_').map(l => {
      return l[0].toUpperCase() + l.substr(1);
    }).join(' ');
  }

  const columns = [{
    key: 'name'
  }, {
    key: 'dots',
    render() {
      return (
        <FlexLayout>
          <Badge color="yellow" text="0" />
          <Badge color="red" text="0" />
        </FlexLayout>
      );
    }
  }, {
    key: 'percentage',
    textAlign: 'right'
  }];

  const metricsMenu = (
    <Menu
      onClick={ e => {
        // setLevel3(e.keyPath);
        // setLevel2('metrics');
        history.push(`${match.url}/metrics/${e.key}`);
        setMetricsOpen(false);
      } }
      appearance={ Menu.APPEARANCE.COMPACT }
      activeKeyPath={ level3 }
    >
      <MenuItem key="cpu_usage">CPU Usage</MenuItem>
      <MenuItem key="cpu_ready_time">CPU Ready Time</MenuItem>
      <MenuItem key="memory_usage">Memory Usage</MenuItem>
      <MenuItem key="memory_swap">Memory Swap</MenuItem>
      <MenuItem key="iops">IOPS</MenuItem>
      <MenuItem key="io_latency">IO Latency</MenuItem>
      <MenuItem key="io_bandwidth">IO Bandwidth</MenuItem>
      <MenuItem key="storage_usage">Storage Usage</MenuItem>
      <MenuItem key="working_set_size">Working Set Size</MenuItem>
      <MenuItem key="network_packets_dropped">Network Packets Dropped</MenuItem>
      <MenuItem key="network_bytes">Network Bytes</MenuItem>
    </Menu>
  );

  const metricsTab = <Dropdown
    getPopupContainer={ () => document.querySelector('.app-main') }
    popupVisible={ metricsOpen }
    onPopupVisibleChange={ nextState => {
      setMetricsOpen(nextState);
    } }
    popup={ metricsMenu }
    className="tab-dropdown"
  >
    Metrics
  </Dropdown>;

  const tabs = [
    {
      key: 'summary',
      name: 'Summary',
      title: 'Summary'
    },
    {
      default: true,
      key: 'list',
      name: 'List',
      title: 'List'
    },
    {
      key: 'alerts',
      name: 'Alerts',
      title: 'Alerts'
    },
    {
      key: 'events',
      name: 'Events',
      title: 'Events'
    },
    {
      key: 'metrics',
      name: 'Metrics',
      title: metricsTab
    }
  ];

  const summary = <FlexLayout className="page-content" padding="20px" flexGrow="1" flexDirection="column">
    <Title size="h2">Summary</Title>
    <FlexLayout flexDirection="column" className="page-content-panel" padding="20px" flexGrow="1">
      <Title size="h3">Summary Tab</Title>
      <OutOfScope />
    </FlexLayout>
  </FlexLayout>;

  const list = <FlexLayout className="page-content-wide" padding="20px" flexGrow="1" flexDirection="column">
    <Title size="h2">List</Title>
    <FlexLayout flexDirection="column" className="page-content-panel" padding="20px" flexGrow="1">
      <Title size="h3">List Tab</Title>
      <OutOfScope />
    </FlexLayout>
  </FlexLayout>;

  const alerts = <FlexLayout className="page-content" padding="20px" flexGrow="1" flexDirection="column">
    <Title size="h2">Alerts</Title>
    <FlexLayout flexDirection="column" className="page-content-panel" padding="20px" flexGrow="1">
      <Title size="h3">Alert Tab</Title>
      <OutOfScope />
    </FlexLayout>
  </FlexLayout>;

  const events = <FlexLayout className="page-content" padding="20px" flexGrow="1" flexDirection="column">
    <Title size="h2">Events</Title>
    <FlexLayout flexDirection="column" className="page-content-panel" padding="20px" flexGrow="1">
      <Title size="h3">Events Tab</Title>
      <OutOfScope />
    </FlexLayout>
  </FlexLayout>;

  const metrics = <FlexLayout className="page-content" padding="20px" flexGrow="1" flexDirection="column">
    <FlexLayout alignItems="center">
      <TextLabel type={ TextLabel.TEXT_LABEL_TYPE.SECONDARY }>{ level3.length ? formatLevel(level3[0]) : null }</TextLabel>
    </FlexLayout>
    <FlexLayout flexDirection="column" className="page-content-panel" padding="20px" flexGrow="1">
      <Title size="h3">{ level3.length ? formatLevel(level3[0]) : null } content</Title>
      <OutOfScope />
    </FlexLayout>
  </FlexLayout>;

  const subPages = {
    summary,
    list,
    alerts,
    events,
    metrics
  };

  const pageTitle = 'VMs';

  const renderTabContent = (subPageId = defaultSupPageId) => {
    return subPages[subPageId];
  };

  function TabGlobal ({ match }) {
    return renderTabContent(match.params.subPageId);
  }

  return (
    <FlexLayout itemSpacing="0px" flexDirection="column" style={ { height: '100%' } }>
      <FlexLayout justifyContent="space-between" className="tabs-wrapper" alignItems="center">
        <Separator separator={ <VerticalSeparator /> } spacing="spacing-10px" className="horizontal-sub-menu">
          <FlexLayout padding="0px-20px"><Title size="h2">{ pageTitle }</Title></FlexLayout>
          <Tabs
            data={ tabs }
            onTabClick={ (key, index, tab, e) => {
              if (tab.key !== 'metrics') {
                // setLevel2(tab.key);
                // setLevel3([]);
                history.push(`${match.url}/${tab.key}`);
              }
            } }
            activeKey={ level2 }
          />
        </Separator>
        <FlexLayout alignItems="center" padding="0px-20px">

        </FlexLayout>
      </FlexLayout>
      <FlexLayout className="page-content" flexGrow="1">
        <Router history={ history }>
        <Switch>
          <Route path={ `${match.path}/:subPageId` } component={ TabGlobal } />
          <Route component={ TabGlobal } />
        </Switch>
        </Router>
      </FlexLayout>
    </FlexLayout>
  );
}
