import React, {useRef, useEffect, useState} from 'react';
import './App.css';
import lottie from 'lottie-web';
import '@lottiefiles/lottie-player';
import {create} from '@lottiefiles/lottie-interactivity';
import introAnimation from './animations/introAnimation.json';
import  ArrowImage from './images/arrow.svg';
import  IntroImage from './images/introImage.svg';
import { NavLink, withRouter, useHistory } from "react-router-dom";
import {
  FlexLayout,
  Paragraph,
  Button,
  Title
} from '@nutanix-ui/prism-reactjs';

export default function Intro() {

const introRef = useRef(null);
const history = useHistory();
const [width, setWidth] = useState(window.innerWidth);

function handleWindowSizeChange() {
  setWidth(window.innerWidth);
}
const isTablet = width >=1024 && width <=1350;

useEffect(() => {


  introRef.current.addEventListener('ready', function (e) {
        // 4. configure the interactivity library
        create({
          mode: 'chain',
          player: '#firstLottie',
          actions: [
        {
            state: 'autoplay',
            transition: 'hover',
            frames: [0, 54]


        },
        {   type: 'hover',
            state: 'none',
            transition: 'seek',
            frames: [55, 254],
            position: { x: [0, 1], y: [0, 1] }
        },

     ]
        });
      });

      window.addEventListener('resize', handleWindowSizeChange);
  return () => {
     window.removeEventListener('resize', handleWindowSizeChange);


  };
}, []);

const redirectToCareers = (tabName) => {
    history.push(`/careers`);
  };

return (
  <FlexLayout justifyContent="space-between" alignItems="center">
    <FlexLayout flexDirection="column" itemSpacing="40px" >
      <Title size="h1" className="visuallyHidden">Introduction of Nutanix Design Team</Title>
      <Title size="h2" className="darkSectionTitle" style={{width: '75%'}}>We love to start with a 'Why'</Title>
      <Paragraph className="darkSectionDescription reducedSection">We are a notoriously curious breed with a user-centric mindset. Design is our core, and we love creating seamless experiences that simplify the enterprise world. With design and great attention to detail, we allow our customers to achieve more by doing less. </Paragraph>


        <Paragraph className="darkSectionDescription" style={{marginTop: '0px'}}>Want to be a part of our mission?</Paragraph>

        <Button style={{alignSelf: 'flex-start', zIndex: '5000'}} className="linkButton" id="introExploreButton" onClick={redirectToCareers}>Explore Open Roles <div className="buttonArrow"></div></Button>


    </FlexLayout>
    <FlexLayout itemFlexBasis="50pc">

    <div>
    <lottie-player
        ref={introRef} // 2. set the reference for the player
        id="firstLottie"
        src={JSON.stringify(introAnimation)}
        style={{height: '575px', width: '600px'}}
        role="img" aria-label="Home page animation"
      ></lottie-player>
    </div>
    </FlexLayout>


  </FlexLayout>
);


}
