import React, {useState, useLayoutEffect, useEffect, useRef} from 'react';
import { Element, scroller } from 'react-scroll';
import Headroom from 'react-headroom';
import NavBar from './NavBar';
import Nav from '../Nav';
import { Switch, Route, NavLink } from "react-router";
import Principles from './Principles';
import MobilePrinciples from './MobilePrinciples';
import Artefacts from './Artefacts';
import MobileArtefacts from './MobileArtefacts';
import Footer from '../Footer';
import MobileFooter from '../MobileFooter';
import Foundation from './Foundation';
import MobileFoundation from './MobileFoundation';

import './Main.css';


import {
  Menu,
  MenuItem,
  Dropdown,
  Tabs,
  Link,
  FlexItem,
  StackingLayout,
  FlexLayout,
  NavBarLayout,
  AlertIcon,
  Badge,
  Progress,
  ThemeManager
} from '@nutanix-ui/prism-reactjs';


export default function Main() {


const [width, setWidth] = useState(window.innerWidth);


  const isTablet = width >=1024 && width <=1350;

function handleWindowSizeChange() {
  setWidth(window.innerWidth);
}
const sectionFocused = useRef(false);

const handleTabPress = (e) => {
    if (e.key === 'Tab' && !e.shiftKey) {
      const introLink = document.getElementById('foundationLink');
       e.preventDefault();
       introLink.focus();

      // Remove this event listener after it runs once

      sectionFocused.current = false;
    }
  };

const handleKeyDown = (event, navItems) => {

  const currentFocusIndex = Array.from(navItems).indexOf(document.activeElement);
  const introLink = document.getElementById('foundationLink');
  if (event.key === "ArrowDown") {
    event.preventDefault();
    let nextIndex = (currentFocusIndex + 1) % navItems.length;
    navItems[nextIndex].focus();
  } else if (event.key === "ArrowUp") {
    event.preventDefault();
    let prevIndex = (currentFocusIndex - 1 + navItems.length) % navItems.length;
    navItems[prevIndex].focus();
  } else if (event.key === "Tab" && !event.shiftKey) {
     if(!sectionFocused.current) {
     event.preventDefault();
     const principleCard = document.querySelector('.sideQuest');
     principleCard.focus();
   } else if(sectionFocused.current && introLink.classList.contains('active')) {
     event.preventDefault();
     const introButton = document.querySelector('.sideQuest');
     introButton.focus();
     sectionFocused.current = false;
   }
  }
};

const handleNavigation = (event, targetSection, focusedItem) => {
  if(event.key === "Enter") {
    sectionFocused.current = true;
    window.scroller = scroller;
    setTimeout(() => {
      scroller.scrollTo(targetSection, { duration: 1500,
  delay: 0,
  smooth: true,
  containerId: 'containerElement',
  offset: 50, });
 }, 50);
   } else if(event.key === 'Tab' && sectionFocused.current) {

      event.preventDefault();
      focusedItem.focus();
   }

}


const isMobile = width <= 768;


  useEffect(() => {


   window.addEventListener('resize', handleWindowSizeChange);

   if(!isMobile) {

   const navItems = document.querySelectorAll(".focusable-link");



   navItems.forEach((item) => {
     item.setAttribute("tabindex", "0");
     item.addEventListener("keydown", (event) => handleKeyDown(event, navItems));
   });

   const aboutLink = document.getElementById('foundationLink');

   aboutLink.addEventListener("keydown", function(event) {
   if(!aboutLink.classList.contains('active')) {
    const introButton = document.querySelector('.sideQuest');


   handleNavigation(event, 'foundationSection', introButton);
   }
   });
   const principlesLink = document.getElementById('principlesLink');
   principlesLink.addEventListener("keydown", function(event) {
    const sideQuestCard = document.querySelectorAll('.sideQuest');
    handleNavigation(event, 'principlesSection', sideQuestCard[0]);
    sideQuestCard[3].addEventListener('keydown', function(e) {
     if(sectionFocused.current){
       handleTabPress(e);
     }
     });
   });
   const artefactsLink = document.getElementById('artefactsLink');
   artefactsLink.addEventListener("keydown", function(event) {
    const links = document.querySelectorAll('.artefactParentLink');
    handleNavigation(event, 'artefactsSection', links[0]);
    links[2].addEventListener('keydown', function(e) {
     if(sectionFocused.current){
       handleTabPress(e);
     }
     });
   });
 }
   return () => {
       window.removeEventListener('resize', handleWindowSizeChange);
   }

  }, []);


  return (

    <Element name="test7" className="element" id="containerElement" style={{
      position: 'relative',
      height: '100vh',
      overflow: 'scroll'
    }}>
      <Nav />

      <a href="#maincontent" className="skip-link" id="skipButton">Skip to main content</a>
    <StackingLayout className="mainContainer" id="maincontent" padding="20px" itemSpacing="0px">
    <StackingLayout itemSpacing="0px">



  <Headroom style={{
    webkitTransition: 'all .5s ease-in-out',
   mozTransition: 'all .5s ease-in-out',
    oTransition: 'all .5s ease-in-out',
    transition: 'all .5s ease-in-out'
}}>
<header>
 <NavBar />
   </header>
  </Headroom>
    </StackingLayout>

    <Element id="foundationSection">
      <section>
      {isMobile ? <MobileFoundation/> : <Foundation />}
      </section>
    </Element>
    <Element id="principlesSection">
      <section>
      {isMobile ? <MobilePrinciples/> : <Principles />}
      </section>
    </Element>
        <Element id="artefactsSection">
          <section>
          {isMobile ? <MobileArtefacts/> : <Artefacts />}
          </section>
        </Element>


    </StackingLayout>
        <footer>
        {isMobile ? <MobileFooter/> : <Footer />}
        </footer>
    </Element>


  );
}
