import { themes, appSettings } from '../data/initialValues';
import scenario from '../study/scenario';

export default {
  v: 80,
  appSettings,
  theme: themes.initial,
  previousSearchMenu: false,
  task: 'first',
  hierarchyVersion: 'link',
  menuType: 'expand',
  lockType: 'lock',
  vmsTableForcedWidth: false,
  vmsTableColResize: false,
  isLocked: true,
  isBookmarksClosed: true,
  hasNewBookmarks: false,
  recentEntities: [],
  vmsResultsSort: null,
  bookmarks: [], // [{ id:0, text: 'Page1', to: '/page1' }]
  studyLoading: true,
  studyActive: appSettings.studyActive,
  studyActiveLevels: {
    intro: true,
    steps: true,
    stepsReduced: false,
    stepIndex: 0,
    survey: false,
    surveyPageIndex: 0
  },
  study: {
    backed: 0,
    id: null,
    timestamps: {},
    firstName: null,
    lastName: null,
    email: null,
    stepsAnswers: [...scenario.steps.list.first, ...scenario.steps.list.second, ...scenario.steps.list.third, ...scenario.steps.list.fourth].map(step => {
      const { text } = step;

      return {
        question: text,
        hint: 0
      };
    }),
    surveyAnswers: scenario.survey.list.reduce((acc, page) => {
      const { questions } = page;
      const currentPage = [];

      questions.forEach(question => {
        const { text } = question;
        const answer = {
          question: text
        };

        if (question.type === 'boolean') {
          answer.answer = question.initial;
        }

        if (question.optional) {
          answer.optional = true;
        }

        currentPage.push(answer)
      })

      acc.push(currentPage);
      return acc;
    }, [])
  }
};
