import React, { useRef, useEffect } from 'react';
import animationData from '../animations/designSystemHome.json';
import './Main.css';

import '@lottiefiles/lottie-player';
import { create } from '@lottiefiles/lottie-interactivity';
import {
  FlexLayout,
  Paragraph,
  Title
} from '@nutanix-ui/prism-reactjs';

export default function Foundation() {

  const myRef = useRef(null);

  useEffect(() => {

    window.addEventListener('load', function (e) {
    // 4. configure the interactivity library
    create({

      player: '#foundationLottie'


    });
  });
  }, []);


return (
  <FlexLayout justifyContent="space-between" alignItems="center">
    <FlexLayout flexDirection="column" itemSpacing="20px" style={{width: '30%', marginTop: '50px'}}>
    <Title size="h1" className="visuallyHidden">Introduction of Nutanix Design Team</Title>
      <Title size="h2" className="sectionTitle">Our Foundation</Title>
      <Paragraph className="sectionDescription">Our full-stack design system helps us stay agile and iterate fast. It is our single source of truth and helps ensure quality and consistency throughout products.</Paragraph>
      <Paragraph className="sectionDescription">It includes our design principles, UI components, data-visualisation and illustration library, and content guidelines.</Paragraph>
    </FlexLayout>


    <lottie-player
   ref={myRef}
   id="foundationLottie"
   autoPlay={true}

   src={JSON.stringify(animationData)}
   style={{ width: '617px', height: '586px' }}
 ></lottie-player>


  </FlexLayout>
);


}
