import React from 'react';
import '../App.css';
import Line from '../images/line.svg';
import {
  Menu,
  MenuItem,
  Dropdown,
  Link,
  Tabs,
  Divider,
  TextLabel,
  FlexItem,
  StackingLayout,
  ChevronCutoutLeftIcon,
  FlexLayout,
  NavBarLayout,
  ShareArrowIcon,
  ExternalLinkIcon,
  AlertIcon,
  Paragraph,
  ChevronLeftIcon,
  Badge,
  Button,
  Title,
  Progress,
  ThemeManager
} from '@nutanix-ui/prism-reactjs';

export default function ManagementLadder(props) {

  function changeColor(e) {
     e.target.style.background = '#333';
     if(e.target.querySelector('svg')) {
     e.target.querySelector('svg').style.color = "#FFF";
   }
  }
  function revertColor(e) {
     e.target.style.background = '#222';
     if(e.target.querySelector('svg')) {
     e.target.querySelector('svg').style.color = "#BCBCBD";
   }
  }


return (
  <FlexLayout itemSpacing="0px"  alignItems="center">
  <FlexLayout className="flowChartTile focusable-tile" itemSpacing="10px" alignItems="center" justifyContent="center" id="manager" onClick={(e) => {props.setActive(e, 'Design Manager', 'management')}} onKeyDown={(e) => {props.handleKeyDown(e, 'Design Manager', 'management')}}>

    <Title size="h3" className="sectionDescriptionBold" style={{color: '#BCBCBD', whiteSpace: 'nowrap'}}>Design Manager</Title>

  </FlexLayout>
  <img src={Line} />
  <FlexLayout className="flowChartTile focusable-tile" itemSpacing="10px" alignItems="center" justifyContent="center" onClick={(e) => {props.setActive(e, 'Sr.Design Manager', 'management')}} onKeyDown={(e) => {props.handleKeyDown(e, 'Sr.Design Manager', 'management')}}>

    <Title size="h3" className="sectionDescriptionBold" style={{color: '#BCBCBD', whiteSpace: 'nowrap'}}>Sr.Design Manager</Title>

  </FlexLayout>
  <img src={Line} />
  <FlexLayout className="flowChartTile focusable-tile" itemSpacing="10px" alignItems="center" justifyContent="center" onClick={(e) => {props.setActive(e, 'Design Director', 'management')}} onKeyDown={(e) => {props.handleKeyDown(e, 'Design Director', 'management')}}>

    <Title size="h3" className="sectionDescriptionBold" style={{color: '#BCBCBD', whiteSpace: 'nowrap'}}>Design Director</Title>

  </FlexLayout>
  <img src={Line} />
  <FlexLayout className="flowChartTile focusable-tile" itemSpacing="10px" alignItems="center" justifyContent="center" onClick={(e) => {props.setActive(e, 'Sr.Design Director', 'management')}} onKeyDown={(e) => {props.handleKeyDown(e, 'Sr.Design Director', 'management')}}>

    <Title size="h3" className="sectionDescriptionBold" style={{color: '#BCBCBD', whiteSpace: 'nowrap'}}>Sr.Design Director</Title>

  </FlexLayout>
  <img src={Line} />
  <FlexLayout className="flowChartTile focusable-tile" itemSpacing="10px" alignItems="center" justifyContent="center" onClick={(e) => {props.setActive(e, 'Vice President', 'management')}} onKeyDown={(e) => {props.handleKeyDown(e, 'Vice President', 'management')}}>

    <Title size="h3" className="sectionDescriptionBold" style={{color: '#BCBCBD', whiteSpace: 'nowrap'}}>Vice President</Title>

  </FlexLayout>
  <FlexItem style={{background: '#222', padding: '30px 7px', marginLeft: '15px', cursor: 'pointer'}} onMouseEnter={(e) => changeColor(e)} onMouseLeave={(e) => revertColor(e)} onClick={() => {props.clickHandler('Management')}} className="focusable-tile focusable-chevron" onKeyDown={(e) => {props.handleKeyDown(e, 'collapseManage')}}><ChevronLeftIcon style={{color: '#BCBCBD',  height: '12px', width: '12px'}}/></FlexItem>
  </FlexLayout>



);


}
