import React, {useRef, useEffect} from 'react';
import '../App.css';
import $ from 'jquery';
import Line from '../images/line.svg';
import {
  Menu,
  MenuItem,
  Dropdown,
  Link,
  Tabs,
  Divider,
  TextLabel,
  FlexItem,
  StackingLayout,
  ChevronCutoutLeftIcon,
  ChevronLeftIcon,
  FlexLayout,
  NavBarLayout,
  ShareArrowIcon,
  ExternalLinkIcon,
  AlertIcon,
  Paragraph,
  Badge,
  Button,
  Title,
  Progress,
  ThemeManager
} from '@nutanix-ui/prism-reactjs';

export default function SeniorICLadder(props) {

  function changeColor(e) {
     e.target.style.background = '#333';
    if(e.target.querySelector('svg')) {
     e.target.querySelector('svg').style.color = "#FFF";
   }
  }
  function revertColor(e) {
     e.target.style.background = '#222';
     if(e.target.querySelector('svg')) {
     e.target.querySelector('svg').style.color = "#BCBCBD";
   }
  }

return (
  <FlexLayout itemSpacing="0px" alignItems="center">
  <FlexLayout className="flowChartTile focusable-tile" itemSpacing="10px" alignItems="center" justifyContent="center" id="seniorDesigner" onClick={(e) => {props.setActive(e, 'Sr.Designer', 'senior')}} onKeyDown={(e) => {props.handleKeyDown(e, 'Sr.Designer', 'senior')}}>

    <Title size="h3" className="sectionDescriptionBold" style={{color: '#BCBCBD', whiteSpace: 'nowrap'}}>Sr.Designer</Title>

  </FlexLayout>
  <img src={Line} />
  <FlexLayout className="flowChartTile focusable-tile" itemSpacing="10px" alignItems="center" justifyContent="center" onClick={(e) => {props.setActive(e, 'Staff Designer', 'senior')}} onKeyDown={(e) => {props.handleKeyDown(e, 'Sr.Designer', 'senior')}}>

    <Title size="h3" className="sectionDescriptionBold" style={{color: '#BCBCBD', whiteSpace: 'nowrap'}}>Staff Designer</Title>

  </FlexLayout>
  <img src={Line} />
  <FlexLayout className="flowChartTile focusable-tile" itemSpacing="10px" alignItems="center" justifyContent="center" onClick={(e) => {props.setActive(e, 'Sr.Staff Designer', 'senior')}} onKeyDown={(e) => {props.handleKeyDown(e, 'Sr.Staff Designer', 'senior')}}>

    <Title size="h3" className="sectionDescriptionBold" style={{color: '#BCBCBD', whiteSpace: 'nowrap'}}>Sr.Staff Designer</Title>

  </FlexLayout>
  <img src={Line} />
  <FlexLayout className="flowChartTile focusable-tile" itemSpacing="10px" alignItems="center" justifyContent="center" onClick={(e) => {props.setActive(e, 'Principal Designer', 'senior')}} onKeyDown={(e) => {props.handleKeyDown(e, 'Principal Designer', 'senior')}}>

    <Title size="h3" className="sectionDescriptionBold" style={{color: '#BCBCBD', whiteSpace: 'nowrap'}}>Principal Designer</Title>

  </FlexLayout>
  <FlexItem style={{background: '#222', padding: '30px 7px', marginLeft: '15px', cursor: 'pointer'}} onMouseEnter={(e) => changeColor(e)} onMouseLeave={(e) => revertColor(e)} onClick={() => {props.clickHandler('Senior IC')}} className="focusable-tile focusable-chevron" onKeyDown={(e) => {props.handleKeyDown(e, 'collapseIC')}}><ChevronLeftIcon style={{color: '#BCBCBD',  height: '12px', width: '12px'}}/></FlexItem>

  </FlexLayout>



);


}
