import widgetRows from '../data/widgetRows';
import { themes, tags } from '../data/initialValues';
import { capitalize } from '../utils/utils';

const {
  badgeStart,
  badgeEnd,
  linkStart,
  linkEnd,
  lineBreak,
} = tags;


const scenario = {

  steps: {
    completionTimestamp: 'survey',
    list: {
      first : [{
        id: 1,
        type: 'action',
        text: 'Find the “Favorites” section and open the “Set Favorites” pop-up modal.',
        task: 'first',
        hint: {
          svg: 'svg1'
        }
      },
      {
        id: 2,
        type: 'action',
        task: 'first',
        text: 'Select “X-Ray”.',
        hint: {

          svg: 'svg2'
        }
      },
      {
        id: 3,
        type: 'action',
        task: 'first',
        text: 'Save the configuration.',
        hint: {

          svg: 'svg3'
        }
      },
      {
        id: 4,
        type: 'textarea',
        text: 'How does the “Favorites” feature enhance or change your experience compared to the current method you use to access your most frequently used apps?',
        task: 'first'
      }],
      second : [{
        id: 5,
        type: 'action',
        text: 'Find the “Favorites” section and open the “Set Favorites” pop-up modal.',
        task: 'second',
        hint: {
          svg: 'svg1'
        }
      },
      {
        id: 6,
        type: 'action',
        text: 'Select “Partner Portal”.',
        task: 'second',
        hint: {

          svg: 'svg4'
        }
      },
      {
        id: 7,
        type: 'action',
        text: 'Save the configuration.',
        task: 'second',
        hint: {
          text: `Navigate to the summary tab inside ${ linkStart }'SJC-AHV-4'${ linkEnd }.`,
          link: '/entity/sjc-ahv-4/metrics',
          svg: 'svg5'
        }
      },
      {
        id: 8,
        type: 'textarea',
        text: 'Is there anything that stands out to you immediately for the favorites features, either positively or negatively?',
        task: 'second'
      }
    ],
    third : [{
      id: 9,
      type: 'action',
      text: 'Find and open the “Add Custom View” side panel to create a custom view.',
      task: 'third',
      hint: {
        svg: 'svg6'
      }
    },
    {
      id: 10,
      type: 'action',
      text: 'Hide the “Sales and Partner Services” section and move “Cloud Services” right after “Favorites.”',
      task: 'third',
      hint: {
        svg: 'svg7'
      }
    },
    {
      id: 11,
      type: 'action',
      text: 'Set this as your default custom view.',
      task: 'third',
      hint: {
        svg: 'svg8'
      }
    },
    {
      id: 12,
      type: 'action',
      text: 'Complete all required fields, then save and apply the custom view.',
      task: 'third',
      hint: {

        svg: 'svg9'
      }
    },
    {
      id: 13,
      type: 'textarea',
      text: 'How does the “Custom View” feature enhance or change your experience compared to the current method you use to access your most frequently used sections?',
      task: 'third'
    }
  ],
  fourth : [{
    id: 14,
    type: 'action',
    text: 'Find and apply the pre-set custom view “Admin View”.',
    task: 'fourth',
    hint: {
      svg: 'svg12'
    }
  },
  {
    id: 15,
    type: 'action',
    text: 'Explore and find the appropriate option for modifying the pre-set “Admin View”.',
    task: 'fourth',
    hint: {

      svg: 'svg13'
    }
  },
  {
    id: 16,
    type: 'action',
    text: 'Uncheck “Inactive”',
    task: 'fourth',
    hint: {

      svg: 'svg14'
    }
  },
  {
    id: 17,
    type: 'action',
    text: 'Save the configuration.',
    task: 'fourth',
    hint: {
      svg: 'svg9'
    }
  },
  {
    id: 18,
    type: 'textarea',
    text: 'Is there anything that stands out to you immediately for the “Custom View” features, either positively or negatively?',
    task: 'fourth'
  }
]
  }
},
  survey: {
    completionTimestamp: 'end',
    list: [
      {
        name: 'Survey',
        paragraphs: [
          'You\'re done with the tasks! Now, to evaluate your experience, please fill out the following 9 statements and questions.'
        ],
        questions: [
          {
            type: 'range',
            text: '1. On a scale of 1 to 10, how would you rate your experience and the ease of use in the “Favorites” feature on the homepage? (1 being very poor, 10 being excellent)',
            options: [ '1', '2', '3', '4', '5', '6', '7', '8', '9', '10' ],
            min: 'Very poor',
            mid: '',
            max: 'Excellent'
          },
          {
            type: 'range',
            text: '2. On a scale of 1 to 10, how would you rate your experience and the ease of use in the “Custom View” feature on the homepage? (1 being very poor, 10 being excellent)',
            options: [ '1', '2', '3', '4', '5', '6', '7', '8', '9', '10' ],
            min: 'Very poor',
            mid: '',
            max: 'Excellent'
          },
          {
            type: 'range',
            text: '3. With the updated features, on a scale of 1 to 10, how would you rate your experience and the ease of use in finding and accessing apps from the homepage? (1 being very poor, 10 being excellent)',
            options: [ '1', '2', '3', '4', '5', '6', '7', '8', '9', '10' ],
            min: 'Very poor',
            mid: '',
            max: 'Excellent'
          },
          {
            type: 'range',
            text: '4. With the updated features, On a scale of 1 to 10, how would you rate your overall satisfaction with the homepage/dashboard in terms of overall ease of use, layout, and accessibility of features? (1 being very poor, 10 being excellent)',
            options: [ '1', '2', '3', '4', '5', '6', '7', '8', '9', '10' ],
            min: 'Very poor',
            mid: '',
            max: 'Excellent'
          }
      ]
      }
    ]
  },
  end: {
    title: 'Thank you for your feedback!',

    paragraphs: [
    "Your feedback is crucial as it helps us understand your experience and interactions with the My Nutanix. If you have any other feedback or thoughts, please feel free to reach out to me at ",
    { type: 'link', str: 'kunwei.jin@nutanix.com' },
    "."
  ]
  }
};

export default scenario;
