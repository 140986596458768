import React, {useState, useEffect, useRef} from 'react';
import '../App.css';
import {
  Link,
  StackingLayout,
  FlexLayout,
  Paragraph,
  Button,
  Title
} from '@nutanix-ui/prism-reactjs';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import MercedesImage from '../images/MercedesBenz.svg';
import HomeDepotImage from '../images/HomeDepot.svg';
import HolcimImage from '../images/Holcim.svg';
import ToyotaImage from '../images/Toyota.svg';
import OlamImage from '../images/Olam.svg';
import YamaichiImage from '../images/Yamaichi.svg';
import PartnerTile from '../components/PartnerTile';

export default function Partners() {
   const [blurIndex, setBlurIndex] = useState(null);
   const [width, setWidth] = useState(window.innerWidth);

   function handleWindowSizeChange() {
     setWidth(window.innerWidth);
   }

 useEffect(() => {

 window.addEventListener('resize', handleWindowSizeChange);


 return () => {
     window.removeEventListener('resize', handleWindowSizeChange);
 }

}, []);
  const blurItem = (currentIndex, prevIndex) => {

    if(width >= 1792) {

      if(currentIndex < prevIndex.currentSlide) {
         if(prevIndex.currentSlide === 3) {
           if(width >=3840) {
             setBlurIndex(4);
           } else if(width >=1920) {
             if(width >= 2000) {
               setBlurIndex(2);
             } else {
             setBlurIndex(3);
           }
           } else {
           setBlurIndex(2);
         }
         } else if(prevIndex.currentSlide === 4) {
           if(width >= 2000 && width <= 3840) {
             setBlurIndex(3);
           } else {
           setBlurIndex(4);
         }
         }
      } else {
        if(prevIndex.currentSlide !== 0) {
        setBlurIndex(0);
      } else {
        setBlurIndex(null);
      }
      }

    } else if (width >= 1440){

      if(currentIndex === 0) {
        currentIndex = currentIndex + 2;
      }
      if(currentIndex !== 1) {
      setBlurIndex(currentIndex);
      if(currentIndex === 4) {
        setBlurIndex(0);
      }

    } else if(currentIndex === 1) {
        setBlurIndex(null);
    }

  }
    };


return (
  <FlexLayout className="partners" flexDirection="column" itemSpacing="40px">

       <Title  size="h2" className="sectionTitle" style={{width: '40%'}}>Don’t just take our word for it</Title>
       <Paragraph className="sectionDescription" style={{marginBottom: '22px'}}>Here is what customers and stakeholders are saying about our work.</Paragraph>
   <section>
    <Carousel

    additionalTransfrom={0}
    arrows
    renderArrowsWhenDisabled
    autoPlaySpeed={3000}
    centerMode={true}
    containerClass="partnersCarousel"
    dotListClass=""
    draggable={false}
    focusOnSelect={false}
    itemClass="partnerItems"
    rtl={false}
    keyBoardControl
    minimumTouchDrag={80}
    pauseOnHover
    renderButtonGroupOutside={false}
    renderDotsOutside={false}
     responsive={{
       desktop: {
         breakpoint: {
           max: 4000,
           min: 1439
         },
         items: 3,
         partialVisibilityGutter: 100
       },
       mobile: {
         breakpoint: {
           max: 464,
           min: 0
         },
         items: 1,
         partialVisibilityGutter: 30
       },
       tablet: {
         breakpoint: {
           max: 1350,
           min: 1023
         },
         items: 2,
         partialVisibilityGutter: 30
       }
     }}
     deviceType="desktop"
     rewind={false}
     rewindWithAnimation={false}
     rtl={false}
     showDots={false}
     sliderClass=""
     slidesToSlide={3}
     swipeable
     afterChange={(previousSlide, { currentSlide }) => {
      blurItem(previousSlide, {currentSlide});

    }}
    >
<div id="partnersTile" className={`carousel-item ${
          blurIndex !== null && blurIndex === 0 ? "blur" : ""
        }`}><PartnerTile image={HomeDepotImage} name="The Home Depot" position="Customer" company="Prism Pro" value="Prism Pro makes it extremely easy to watch all of the clusters in our environment and quickly reconfigure things as needed. It provides very clear metrics on oversubscription and utilization, giving us a powerful, proactive capacity management function that we never had before."/></div>
<div id="partnersTile" className={`carousel-item ${
          blurIndex !== null && blurIndex === 1 ? "blur" : ""
        }`}><PartnerTile image={MercedesImage} name="Mercedes-Benz [LATM]" position="Customer" value="As soon as we were introduced to Nutanix, we thought, ‘Well, there’s an interesting solution that we need to test internally. We could see that the environment—not only the applications, but also the infrastructure—was well developed and offered the simplicity and everything else we needed."/></div>
<div id="partnersTile" className={`carousel-item ${
          blurIndex !== null && blurIndex === 2 ? "blur" : ""
        }`}><PartnerTile image={YamaichiImage} name="Yamaichi Special Steel" position="Customer" company="NDB" value="Thanks to the ease of use of the GUI, we can easily deal with various operations, starting with daily operation checks, detecting errors in weekend coverage, checking NDB, etc. It was my first time using Nutanix, but their engineers helped by giving me detailed instructions."/></div>
<div id="partnersTile" className={`carousel-item ${
          blurIndex !== null && blurIndex === 3 ? "blur" : ""
        }`}><PartnerTile image={MercedesImage} name="Mercedes-Benz [LATM]" position="Customer"  value="With Nutanix, I don’t need those separate teams. One person can operate the whole environment on their own. The operation is easy and the interface is very user-friendly, so one team member can handle a cluster with three or one hundred nodes without much effort."/></div>
<div id="partnersTile" className={`carousel-item ${
          blurIndex !== null && blurIndex === 4 ? "blur" : ""
        }`}><PartnerTile image={HolcimImage} name="Holcim Group [EMEA]" position="Customer"  value="One of the key advantages is simplicity, since we manage all the clusters from a single console, and we are capable of automating critical activities such as loading security patches. Another great benefit we have experienced is scalability. In any relevant multinational company, the possibility of dynamically cope with the demand based on business needs has unquestionable value."/></div>
<div id="partner  sTile" className={`carousel-item ${
          blurIndex !== null && blurIndex === 5 ? "blur" : ""
        }`}><PartnerTile image={ToyotaImage} name="Toyota Motor Corporation" position="Customer" value="We also highly valued the fact that it enabled us to start small, expand ﬂexibly, and respond promptly to changes in the business environment; as a result, we decided to adopt the Nutanix Cloud Platform."/></div>
<div id="partnersTile" className={`carousel-item ${
          blurIndex !== null && blurIndex === 6 ? "blur" : ""
        }`}><PartnerTile image={OlamImage} name="Olam" position="Customer" company="Prism Pro" value="It simplifies the entire process, be it a software or firmware upgrade, while the Prism Pro console allows you to manage your entire IT footprint through a single interface."/></div>

</Carousel>
</section>
  </FlexLayout>
);


}
