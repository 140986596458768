import React from 'react';

import {
  FlexLayout,
  Paragraph,
  TextLabel
} from '@nutanix-ui/prism-reactjs';

const items = [
  { vm: 'NTNX-demo-files-3', cluster: 'DEMO-AHV', cpu: 19.63, memory: 4, storage: 18.11 },
  { vm: 'win', cluster: 'DEMO-AHV', cpu: 17.11, memory: 18.63, storage: 18.09 },
  { vm: 'NTNX-demo-files-1', cluster: 'DEMO-AHV', cpu: 17.09, memory: 16.11, storage: 17.38 },
  { vm: 'NTNX-demo-files-2', cluster: 'DEMO-AHV', cpu: 16.38, memory: 16.09, storage: 11.35 },
  { vm: 'Demo PC', cluster: 'DEMO-AHV', cpu: 10.35, memory: 15.38, storage: 8.53 },
  { vm: 'demo-obj-a19a92-default-0', cluster: 'DEMO-AHV', cpu: 8.53, memory: 9.35, storage: 9.32 },
  { vm: 'testezilmar', cluster: 'DEMO-AHV', cpu: 8.32, memory: 7.53, storage: 7.59 },
  { vm: 'demo-obj-a19a92-default-2', cluster: 'DEMO-AHV', cpu: 6.59, memory: 7.32, storage: 6.27 },
  { vm: 'karbon-ms-test-45ed65-k8s-master-0', cluster: 'DEMO-AHV', cpu: 5.27, memory: 5.59, storage: 6 },
  { vm: 'karbon-dev-k8s-946619-k8s-master-0', cluster: 'DEMO-AHV', cpu: 5, memory: 4.27, storage: 20.63 }
];

export default items.map((item, i) => {
  const { vm, cluster, cpu, memory, storage } = item;
  return {
    key: i + 1,
    option: vm,
    name: <FlexLayout flexDirection="column" itemSpacing="10px" padding="10px-0px">
      <Paragraph>{ vm }</Paragraph>
      <Paragraph type="supplementary">{ cluster }</Paragraph>
    </FlexLayout>,
    cpu: <TextLabel type="primary">{ cpu }%</TextLabel>,
    memory: <TextLabel type="primary">{ memory }%</TextLabel>,
    storage: <TextLabel type="primary">{ storage }%</TextLabel>
  };
});
