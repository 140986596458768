import React, { useState } from 'react';
import './pages/website/App.css';

import {
  Redirect,
  Route,
  Switch,
  BrowserRouter
} from 'react-router-dom';

import {
  FlexLayout,
  InputPassword,
  MainPageLayout
} from '@nutanix-ui/prism-reactjs';

import menuItems from './menu/menuItems';
import Website from './pages/website/App';
import About from './pages/website/aboutUs/App';
import DesignSystems from './pages/website/designSystem/Main';
import Careers from './pages/website/careers/CareersMain';

import '@nutanix-ui/prism-reactjs/dist/index.css';


const pwd = '';



export default function App() {

  const isLocked = false;


  const [leftPanelVisible, setLeftPanelVisible] = useState(isLocked);
  const [isLoggedIn, setIsLoggedIn] = useState(!pwd.length ? true : sessionStorage.getItem('isLoggedIn') || false);
  const [pwdError, setPwdError] = useState(false);


  const router = (<div className="app-main" onClick={ () => {
    if (leftPanelVisible && !isLocked) {
      setLeftPanelVisible(false);
    }
  } }>
   <BrowserRouter>
    <Switch>
      <Route exact={ true } path="/" component={ Website } />
      { menuItems.map((menuItem) => menuItem.pages.map((page, index) => (<Route
        key={ `route-${index}` }
        path={ page.to }
        component={ page.component }
      />))) }
      <Route
        key={ `route-dashboard` }
        path={ '/' }
        component={ Website }
      />
      <Route
        key={ `route-careers` }
        path={ '/careers' }
        component={ Careers }
      />
      <Route
        key={ `route-about` }
        path={ '/aboutUs' }
        component={ About }
      />
      <Route
        key={ `route-design` }
        path={ '/designSystem' }
        component={ DesignSystems }
      />
      <Route>
        <Redirect to="/" />
      </Route>
    </Switch>
    </BrowserRouter>
  </div>);
  return (<div className={ `app${ isLoggedIn && isLocked ? ' locked' : '' }` }>
    { !isLoggedIn
      ? <MainPageLayout
        className="login-page"
        fullPage={ true }
        body={
          <FlexLayout
            className="login-form"
            flexGrow="1"
            justifyContent="center"
            alignItems="center"
          >
            <InputPassword
              className="login-input"
              placeholder="Password"
              error={ pwdError }
              onChange={ () => {
                setPwdError(false);
              } }
              onPressEnter={ (event) => {
                const pwdCorrect = event.currentTarget.value === pwd;
                setPwdError(!pwdCorrect);

                if (pwdCorrect) {
                  sessionStorage.setItem('isLoggedIn', true)
                  setIsLoggedIn(true);
                }
              } }
            />
          </FlexLayout>
        }
      />
      : <>
        <MainPageLayout
          className={ `theme-light` }
          header={false}
          leftPanelVisible={ false }
          fullPage={ true }
          body={ router }
        />
      </>
    }
  </div>);
}
