import React, {useState} from 'react';
import './App.css';
import FooterLogo from './images/footerLogo.svg';

import TwitterLogo from './images/twitter.svg';
import MediumLogo from './images/medium.svg';
import CopyRightLogo from './images/footerCopyright.svg';

import { NavLink, withRouter } from "react-router-dom";
import {
  Link,
  VerticalSeparator,
  FlexLayout,
  TextLabel,
  Title,
  FlexItem,
  ChevronRightIcon,
  ExternalLinkIcon
} from '@nutanix-ui/prism-reactjs';

function Footer(props) {



const moveToPage = (value) => {
  if(document.getElementById('containerElement')) {
  if(value === props.location.pathname) {
     document.getElementById('containerElement').scrollTo({
       top: 0,
       left: 0,
       behavior: 'smooth'
     });
  }
}
}


return (
  <FlexLayout className="footer">
  <FlexLayout justifyContent="space-between" alignItems="center" style={{width: '100%'}}>
   <img src={FooterLogo} />
   <VerticalSeparator style={{height: '150px', backgroundColor: '#333333'}}/>
     <nav>
   <FlexLayout flexDirection="column" itemSpacing="20px">

    <Title size="h2" className="brandColorTextRegular">Explore</Title>
    <FlexLayout itemSpacing="60px">
    <FlexLayout flexDirection="column">
    <FlexLayout flexDirection="column" itemSpacing="5px" className="footerLink">
      <NavLink to="/aboutUs" onClick={moveToPage('/aboutUs')}>
      <FlexLayout alignItems="center" itemSpacing="5px">
        <FlexItem  className="darkSectionDescriptionSmallFooter">About Us</FlexItem>
        <ChevronRightIcon style={{height: '10px', width: '10px', color: '#9D9D9D'}} className="footerImage" />
      </FlexLayout>
      </NavLink>
      <TextLabel className="darkSectionDescriptionSmallSubHeader" >People + Processes</TextLabel>
    </FlexLayout>
    <FlexLayout flexDirection="column" itemSpacing="5px" className="footerLink">
    <NavLink to="/careers" onClick={moveToPage('/careers')}>
      <FlexLayout alignItems="center" itemSpacing="5px" >
        <FlexItem className="darkSectionDescriptionSmallFooter">Careers</FlexItem>
          <ChevronRightIcon style={{height: '10px', width: '10px', color: '#9D9D9D'}} className="footerImage" />
      </FlexLayout>
      </NavLink>
      <TextLabel className="darkSectionDescriptionSmallSubHeader" >Openings in team</TextLabel>
    </FlexLayout>

    </FlexLayout>
    <FlexLayout flexDirection="column">
    <FlexLayout flexDirection="column" itemSpacing="5px" className="footerLink">
    <NavLink to="/designSystem" onClick={moveToPage('/designSystem')}>
      <FlexLayout alignItems="center" itemSpacing="5px" >
        <FlexItem className="darkSectionDescriptionSmallFooter">Design System</FlexItem>
           <ChevronRightIcon style={{height: '10px', width: '10px', color: '#9D9D9D'}} className="footerImage" />
      </FlexLayout>
      </NavLink>
      <TextLabel className="darkSectionDescriptionSmallSubHeader" >Guides + Frameworks</TextLabel>
    </FlexLayout>
    <FlexLayout flexDirection="column" itemSpacing="5px" className="footerLink">
    <Link href="https://www.nutanix.com/" target="_blank">
      <FlexLayout alignItems="center" itemSpacing="5px" >
        <FlexItem className="darkSectionDescriptionSmallFooter">About Nutanix</FlexItem>
          <ExternalLinkIcon style={{height: '10px', width: '10px', color: '#9D9D9D'}} className="footerImage" />
      </FlexLayout>
      </Link>
      <TextLabel className="darkSectionDescriptionSmallSubHeader" >Our Products & Solutions</TextLabel>
    </FlexLayout>

    </FlexLayout>
    </FlexLayout>

   </FlexLayout>
   </nav>
   <VerticalSeparator style={{height: '150px', backgroundColor: '#333333'}}/>
   <nav>
   <FlexLayout flexDirection="column" itemSpacing="20px" style={{alignSelf: 'flex-start'}}>

    <Title size="h2" className="brandColorTextRegular">Follow us</Title>
    <FlexLayout alignItems='center' itemSpacing="10px" className="footerLink">

      <img src={TwitterLogo} style={{width: '18px', height: '18px'}} className="footerImage"/>
      <Link className="footerSubLink" href="https://twitter.com/NutanixDesign" target="_blank" style={{marginTop: '-4px'}}>Twitter</Link>
    </FlexLayout>
    <FlexLayout alignItems="center" itemSpacing="10px" className="footerLink">
      <img src={MediumLogo} style={{width: '18px', height: '18px'}} className="footerImage"/>
      <Link className="footerSubLink" href="https://medium.com/nutanix-design" target="_blank" style={{marginTop: '-4px'}}>Medium</Link>
    </FlexLayout>
    <img src={CopyRightLogo} />

   </FlexLayout>
   </nav>

</FlexLayout>




  </FlexLayout>
);


}
export default withRouter(Footer);
