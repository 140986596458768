import React, {useState, useEffect, useRef} from 'react';
import { Element, Link, scroller } from 'react-scroll';
import Headroom from 'react-headroom';
import People from './People';
import MobilePeople from './MobilePeople';
import Roles from './Roles';
import MobileRoles from './MobileRoles';
import Partners from './Partners';
import MobilePartners from './MobilePartners';
import NavBar from './NavBar';
import Nav from '../Nav';
import Growth from './Growth';
import MobileGrowth from './MobileGrowth';
import Footer from '../Footer';
import MobileFooter from '../MobileFooter';
import '../App.css';
import {
  StackingLayout,
  FlexLayout
} from '@nutanix-ui/prism-reactjs';


export default function App() {


  const [width, setWidth] = useState(window.innerWidth);
  let linkClicked = false;

  const sectionFocused = useRef(false);

  const isTablet = width >=1024 && width <=1350;

  function openNav() {
  document.getElementById("mySidenav").style.width = "100%";
  }
  function closeNav() {
  document.getElementById("mySidenav").style.width = "0";
  }


  const isMobile = width <= 768;


  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  function handleScroll() {

    if(!isMobile) {

    const rolesLink = document.getElementById('roles').getElementsByTagName('a')[0];
    const rolesCard = document.getElementsByClassName('rolesCard');

    const partnerCarousel = document.getElementsByClassName('partnersCarousel')[0].getElementsByClassName('react-multi-carousel-track')[0];
    const partnersLink = document.getElementById('partners').getElementsByTagName('a')[0];
    setTimeout(() => {
      if(rolesLink.classList.contains('active')){
         Array.from(rolesCard).forEach((item, i) => {
           item.classList.add('animatedCard');
         });

      } else if (partnersLink.classList.contains('active')) {
          partnerCarousel.classList.add('animatedCarousel');
      }
    }, 50);

  }
  }
  const handleTabPress = (e) => {
      if (e.key === 'Tab' && !e.shiftKey) {
        const introLink = document.getElementById('peopleLink');
         e.preventDefault();
         introLink.focus();

        // Remove this event listener after it runs once

        sectionFocused.current = false;
      }
    };

  const handleKeyDown = (event, navItems) => {

    const currentFocusIndex = Array.from(navItems).indexOf(document.activeElement);

    const introLink = document.getElementById('peopleLink');

    if (event.key === "ArrowDown") {
      event.preventDefault();
      let nextIndex = (currentFocusIndex + 1) % navItems.length;
      navItems[nextIndex].focus();
    } else if (event.key === "ArrowUp") {
      event.preventDefault();
      let prevIndex = (currentFocusIndex - 1 + navItems.length) % navItems.length;
      navItems[prevIndex].focus();
    } else if (event.key === "Tab" && !event.shiftKey) {
       if(!sectionFocused.current) {
       event.preventDefault();
       const introButton = document.getElementById('principlesButton');
       introButton.focus();
     } else if(sectionFocused.current && introLink.classList.contains('active')) {
       event.preventDefault();
       const introButton = document.getElementById('principlesButton');
       introButton.focus();
       sectionFocused.current = false;
     }
    }
  };

  const handleNavigation = (event, targetSection, focusedItem) => {
    if(event.key === "Enter") {
      sectionFocused.current = true;
      window.scroller = scroller;
      setTimeout(() => {
        scroller.scrollTo(targetSection, { duration: 1500,
    delay: 0,
    smooth: true,
    containerId: 'containerElement',
    offset: 50, });
   }, 50);
     } else if(event.key === 'Tab' && sectionFocused.current) {

        event.preventDefault();
        focusedItem.focus();
     }

  }



  useEffect(() => {




   document.getElementById('containerElement').addEventListener('scroll', handleScroll);
   window.addEventListener('resize', handleWindowSizeChange);
   if(!isMobile) {
   const principlesButton = document.getElementById('principlesButton');
   const rolesCard = document.getElementsByClassName('rolesCard');
   document.getElementById('principlesButton').addEventListener('keydown', function(event) {
     Array.from(rolesCard).forEach((item, i) => {
       item.classList.add('animatedCard');
     });

   });

   const navItems = document.querySelectorAll(".focusable-link");



   navItems.forEach((item) => {
     item.setAttribute("tabindex", "0");
     item.addEventListener("keydown", (event) => handleKeyDown(event, navItems));
   });

   const aboutLink = document.getElementById('peopleLink');

   aboutLink.addEventListener("keydown", function(event) {
   if(!aboutLink.classList.contains('active')) {
    const introButton = document.getElementById('principlesButton');

   handleNavigation(event, 'peopleSection', introButton);
   }
   });
   const rolesLink = document.getElementById('rolesLink');

   rolesLink.addEventListener("keydown", function(event) {
    const rolesCard = document.querySelectorAll('.rolesCard');
    handleNavigation(event, 'rolesSection', rolesCard[0]);
    rolesCard[5].addEventListener('keydown', function(e) {
     if(sectionFocused.current){
       handleTabPress(e);
     }
     });
   });

   const partnersLink = document.getElementById('partnersLink');

   partnersLink.addEventListener("keydown", function(event) {
    const carouselButton = document.getElementsByClassName('partnersCarousel')[0].querySelector('.react-multiple-carousel__arrow--right');
    handleNavigation(event, 'partnersSection', carouselButton);
    carouselButton.addEventListener('keydown', function(e) {
     if(sectionFocused.current){
       handleTabPress(e);
     }
     });
   });

   const growthLink = document.getElementById('growthLink');

   growthLink.addEventListener("keydown", function(event) {
     const growthCard = document.querySelectorAll('.growthCard');
     handleNavigation(event, 'growthSection', growthCard[0]);
     growthCard[4].addEventListener('keydown', function(e) {
      if(sectionFocused.current){
        handleTabPress(e);
      }
      });
   });

 }
   return () => {
       window.removeEventListener('resize', handleWindowSizeChange);
   }

  }, []);

  function changeLink() {
    linkClicked = true;
  }




  return (

    <Element name="test7" className="element" id="containerElement" style={{
      position: 'relative',
      height: '100vh',
      overflow: 'scroll'
    }}>
    <Nav />
    <a href="#maincontent" className="skip-link" id="skipButton">Skip to main content</a>
    <StackingLayout className="mainContainer" id="maincontent" padding="20px" itemSpacing="0px">
    <StackingLayout itemSpacing="0px">





  <Headroom style={{
    webkitTransition: 'all .5s ease-in-out',
   mozTransition: 'all .5s ease-in-out',
    oTransition: 'all .5s ease-in-out',
    transition: 'all .5s ease-in-out'
}}>

<header>
<NavBar />
   </header>
  </Headroom>
    </StackingLayout>
      <Element id="peopleSection">
        <section>
        {isMobile ? <MobilePeople/> : <People />}
        </section>
      </Element>
      <Element id="rolesSection" name="rolesPage">
        <section>
        {isMobile ? <MobileRoles/> : <Roles />}
        </section>
      </Element>
      <Element name="partners" id="partnersSection">
        <section>
        {isMobile ? <MobilePartners/> : <Partners />}
        </section>
      </Element>
      <Element id="growthSection">
        <section>
        {isMobile ? <MobileGrowth/> : <Growth />}
        </section>
      </Element>

    </StackingLayout>
      <footer>
      {isMobile ? <MobileFooter/> : <Footer />}
      </footer>
    </Element>


  );
}
