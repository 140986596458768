import React from 'react';
import '../App.css';
import {
  Menu,
  MenuItem,
  Dropdown,
  Link,
  Tabs,
  Divider,
  TextLabel,
  FlexItem,
  StackingLayout,
  FlexLayout,
  NavBarLayout,
  ShareArrowIcon,
  ExternalLinkIcon,
  AlertIcon,
  Paragraph,
  Badge,
  Button,
  Title,
  Progress,
  ThemeManager
} from '@nutanix-ui/prism-reactjs';

export default function PartnerTile(props) {




return (
  <FlexLayout flexDirection="column" className="partnerTile" itemSpacing="10px">
   <img src={props.image} style={{height: '40px', width: '40px'}}/>
   <Title size="h3" className="sectionDescriptionBold">{props.name}</Title>
   <FlexLayout itemSpacing="10px">
   <Badge color='gray' count={props.position}/>
   {props.company ? <Badge color="gray" count={props.company} className="customerCompanyBadge"/> : null}
   </FlexLayout>
   <Paragraph className="sectionDescriptionSmall customerTestimonial">{props.value}</Paragraph>


   </FlexLayout>



);


}
