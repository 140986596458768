import { localStorageDevMode } from '../data/initialValues';


// Initial variables

let isDevEnv;


// Settings

const interLevelTimeOut = 500;

const stepsMapping = {
  email: answer => answer.trim(),
  multi: answer => answer.map(answer => answer.label),
  number: answer => parseInt(answer),
  radio: (answer, currentQuestion) => currentQuestion.options[answer],
  slider: answer => answer,
  textarea: answer => answer
};

const surveyMapping = {
  range: (answer, currentQuestion) => currentQuestion.options[answer],
  comment: answer => answer,
  boolean: answer => answer
};


// Private Functions

const mapStepsAnswers = (questions, answers) => {
  // Flatten the questions object into an array of question objects
  const flatQuestions = Object.values(questions).flat();

  return answers.map((answerObject) => {
    const { question: answerQuestion, answer } = answerObject;

    // Find the corresponding question object in the flatQuestions array
    const currentQuestionObject = flatQuestions.find(
      (q) => q.text === answerQuestion
    );

    // Create a new object with the mapped answer
    const newAnswerObject = Object.assign({}, answerObject);

    if (newAnswerObject.answer !== undefined && currentQuestionObject) {
      newAnswerObject.answer = stepsMapping[currentQuestionObject.type](answer, currentQuestionObject);
    }

    return newAnswerObject;
  });
};


const mapSurveyAnswers = (questions, answers) => {
  return answers.reduce((acc, answersPage, pageIndex) => {
    const questionsPage = questions[pageIndex].questions;

    const mappedAnswers = answersPage.map((answerObject, i) => {
      let { answer } = answerObject;
      const currentQuestionObject = questionsPage[i];

      if (answer === null || answer === undefined) {
        answer = '';
      };

      return {
        question: currentQuestionObject.text,
        answer: surveyMapping[currentQuestionObject.type](answer, currentQuestionObject)
      }
    });

    return acc.concat(mappedAnswers);
  }, []);
};


// Exported Functions

export function initQuery(search) {
  return new URLSearchParams(search);
};

export function useQuery(useLocation) {
  return new URLSearchParams(useLocation().search);
}

export function isDev() {
  if (isDevEnv === undefined) {
    isDevEnv = JSON.parse(localStorage.getItem(localStorageDevMode));
  }

  return isDevEnv;
}

export function updateLevel({ dispatch, activeLevel, inactiveLevel }) {
  if (inactiveLevel) {
    dispatch({ type: 'SET_STUDY_ACTIVE_LEVELS', level: inactiveLevel, active: false });

    if (activeLevel) {
      setTimeout(() => {
        dispatch({ type: 'SET_STUDY_ACTIVE_LEVELS', level: activeLevel, active: true });
      }, interLevelTimeOut);
    }
  } else if (activeLevel) {
    dispatch({ type: 'SET_STUDY_ACTIVE_LEVELS', level: activeLevel, active: true });
  }
};

export function processAnswers({ scenario, study }) {
  const { steps, survey } = scenario;
  const { backed, stepsAnswers, surveyAnswers } = study;

  return {
    backed,
    steps: mapStepsAnswers(steps.list, stepsAnswers),
    survey: mapSurveyAnswers(survey.list, surveyAnswers)
  };
}

export function getColorSchemePref() {
  let colorScheme = 'unsupported';

  if (window.matchMedia) {
    if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
      colorScheme = 'dark';
    } else if (window.matchMedia('(prefers-color-scheme: light)').matches) {
      colorScheme = 'light';
    } else {
      colorScheme = 'unset';
    }
  }

  return colorScheme;
}

export function capitalize(str) {
  return str[0].toUpperCase() + str.substr(1);
}

export function processPathname(location, level) {
  return location.pathname.split('/')[level] || null;
};
