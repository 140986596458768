import React from 'react';
import {
  FlexLayout,
  Separator,
  Title,
  VerticalSeparator
} from '@nutanix-ui/prism-reactjs';

import OutOfScope from '../components/OutOfScope';

export default function Page1({ history }) {
  const title = (history.location.pathname || '').replace('/', '').split('_').map(l => {
    return l[0].toUpperCase() + l.substr(1);
  }).join(' ') || 'No Title';

  return (
    <FlexLayout itemSpacing="0px" flexDirection="column" style={ { height: '100%' } }>
      <FlexLayout justifyContent="space-between" className="tabs-wrapper" alignItems="center">
        <Separator separator={ <VerticalSeparator /> } spacing="spacing-10px" className="horizontal-sub-menu">
          <FlexLayout padding="0px-20px"><Title size="h2">{ title }</Title></FlexLayout>
        </Separator>
        <FlexLayout alignItems="center" padding="0px-20px">

        </FlexLayout>
      </FlexLayout>
      <FlexLayout className="page-content" padding="20px" flexGrow="1">
        <FlexLayout className="page-content-panel" flexDirection="column" padding="20px" flexGrow="1">
          <Title size="h3">Content for { title }</Title>
          <OutOfScope />
        </FlexLayout>
      </FlexLayout>
    </FlexLayout>
  );
}
