import React, {useEffect, useRef} from 'react';
import '../App.css';
import {
  Menu,
  MenuItem,
  Dropdown,
  Link,
  Tabs,
  Divider,
  TextLabel,
  FlexItem,
  StackingLayout,
  FlexLayout,
  NavBarLayout,
  ShareArrowIcon,
  ExternalLinkIcon,
  AlertIcon,
  Paragraph,
  Badge,
  Button,
  Title,
  VerticalSeparator,
  Progress,
  ThemeManager
} from '@nutanix-ui/prism-reactjs';

export default function BasicCard(props) {

useEffect(() => {
  Array.from(document.getElementsByClassName('rolesCard')).forEach((item, i) => {

    item.addEventListener('focus', function () {
         item.classList.add('rolesCardHover');
    });
    item.addEventListener('blur', function () {
         item.classList.remove('rolesCardHover');
    });
  });

});

return (
  <FlexLayout className={props.classname} flexDirection="column" tabIndex="0" itemSpacing="40px" onClick={() => props.clickHandler(props.activeKey)} onKeyPress={(e) => { if (e.key === 'Enter') props.clickHandler(props.activeKey); }}>
    <img src={props.image} className="basicCardImage"/>
    <FlexLayout style={{padding: '0px 50px 20px 20px', marginTop: '20px'}} itemSpacing="20px">
    <Title size="h3" className="basicCardText" style={{width: '80%'}} >{props.text}</Title>
    <VerticalSeparator style={{height: '45px', backgroundColor: '#333333'}}/>
    <div class="rolesArrow"></div>
    </FlexLayout>


  </FlexLayout>
);


}
