import React, { Component, useState, useEffect, useRef, forwardRef} from "react";
import { Link as ScrollLink, animateScroll as scroll, scroller } from "react-scroll";
import Line from './images/navLine.svg';
import ReactDOM from 'react-dom';
import './App.css';
import { useLocation } from 'react-router-dom';

export default function NavBar(props) {
  const handleScroll = (event, sectionId) => {
    event.preventDefault();
    const section = document.getElementById(sectionId);
    if (section) {
      const viewportHeight = document.documentElement.clientHeight;
      const sectionHeight = section.getBoundingClientRect().height;
      const sectionPosition = section.getBoundingClientRect().top + window.pageYOffset;
      const documentHeight = document.documentElement.scrollHeight;

      // Calculate scroll position
      let scrollPosition = sectionPosition - (viewportHeight / 2);

      // Adjust scroll position for the last section if it's smaller than the viewport height
      if (sectionPosition + sectionHeight < viewportHeight) {
        scrollPosition = sectionPosition - (viewportHeight - sectionHeight) / 2;
      }

      // Ensure the scroll position does not exceed document height
      scrollPosition = Math.min(scrollPosition, documentHeight - viewportHeight);

      window.scrollTo({
        top: scrollPosition,
        behavior: 'smooth'
      });

      // Ensure active link is set using scroller from react-scroll
      scroller.scrollTo(sectionId, {
        duration: 800,
        delay: 0,
        smooth: 'easeInOutQuart',
        offset: -viewportHeight / 2,
      });
    }
  };

    useEffect(() => {
      const introLink = document.getElementById('introLink');
      const aboutLink = document.getElementById('aboutLink');
      const articleLink = document.getElementById('articleLink');
      const joinUsLink = document.getElementById('joinUsLink');

      const handleIntroClick = (e) => handleScroll(e, 'introSection');
      const handleAboutClick = (e) => handleScroll(e, 'aboutSection');
      const handleArticleClick = (e) => handleScroll(e, 'articleSection');
      const handleJoinUsClick = (e) => handleScroll(e, 'joinUsSection');

      introLink.addEventListener('click', handleIntroClick);
      aboutLink.addEventListener('click', handleAboutClick);
      articleLink.addEventListener('click', handleArticleClick);
      joinUsLink.addEventListener('click', handleJoinUsClick);

      return () => {
        introLink.removeEventListener('click', handleIntroClick);
        aboutLink.removeEventListener('click', handleAboutClick);
        articleLink.removeEventListener('click', handleArticleClick);
        joinUsLink.removeEventListener('click', handleJoinUsClick);
      };
    }, []);

    return (
      <div className="nav" id="navbar">
         <nav>
         <img src={Line} className="navLine"/>
          <ul className="nav-items" >
            <li className="nav-item firstItem" ><ScrollLink
            activeClass="active"

            to="introSection"
            id="introLink"
            spy={true}
            className="focusable-link"
            containerId="containerElement"
            smooth={true}
            isDynamic={true}

            duration={500}
            >Intro</ScrollLink></li>
            <li className="nav-item secondItemIntro"><ScrollLink
            activeClass="active"
            to="aboutSection"
            id="aboutLink"
            spy={true}
            className="focusable-link"
            role="button"
            containerId="containerElement"
            smooth={true}
            isDynamic={true}

            duration={500}
            >About</ScrollLink></li>
            <li className="nav-item thirdItemIntro" id="article"><ScrollLink
            activeClass="active"
            to="articleSection"
            id="articleLink"
            spy={true}
            className="focusable-link"
            containerId="containerElement"
            smooth={true}
            isDynamic={true}

            duration={400}>Articles</ScrollLink></li>
            <li className="nav-item fourthItemIntro" id="joinUs"><ScrollLink
            activeClass="active"
            id="joinUsLink"
            to="joinUsSection"
            spy={true}
            className="focusable-link"
            containerId="containerElement"
            smooth={true}
            isDynamic={true}

            duration={500}>Join Us</ScrollLink></li>

          </ul>
        </nav>
      </div>
    );

}
