import React, {useState} from 'react';
import '../App.css';
import Linkedin from '../images/linkedin.svg';
import {
  Link,
  TextLabel,
  FlexItem,
  FlexLayout,
  Paragraph,
  Title,
  Modal
} from '@nutanix-ui/prism-reactjs';

export default function ThoughtsCards(props) {

const [modalState, setModalState] = useState(false);

function handleOnClose() {
  setModalState(false);
}
return (
  <FlexLayout flexDirection="column" itemSpacing="10px" style={{padding: '30px'}}>

    <img src={props.image} style={{height: '40px', width: '40px'}}/>
    <FlexLayout flexDirection="column" itemSpacing="5px" >
    <FlexLayout itemSpacing="10px">
     <TextLabel type={ TextLabel.TEXT_LABEL_TYPE.PRIMARY } className="sectionDescriptionBold" style={{color: '#7855FA'}}>{props.name}</TextLabel>
     <Link href={props.href} className="thoughLink"><img src={Linkedin} /></Link>
    </FlexLayout>
     <TextLabel type={ TextLabel.TEXT_LABEL_TYPE.PRIMARY } className="sectionDescriptionArticleDesc" style={{fontStyle: 'italic'}}>{props.position}</TextLabel>
    </FlexLayout>

    <Paragraph className="sectionDescriptionSmallBold" style={{marginTop: '20px'}}>{props.question}</Paragraph>
    <Paragraph className="sectionDescriptionSmall">{props.answer}</Paragraph>
    {props.viewMore ? <Link className="sectionDescriptionBold" style={{color: '#7855FA'}} onClick={ () => setModalState(true) }>View more</Link> : null}
    <Modal
         visible={ modalState }
         footer={false}
         onClose={ handleOnClose }
         closeIconProps={ { 'data-test': 'my-close-icon-data-test' } }
         primaryButtonOnClick={ handleOnClose }
       >
       <FlexLayout flexDirection="column" style={{padding: '5px 30px 30px'}}>

         <img src={props.image} style={{width: '40px', height: '40px'}}/>
         <FlexLayout flexDirection="column" itemSpacing="2px">
         <FlexLayout alignItems="center" itemSpacing="10px">
        <TextLabel type={ TextLabel.TEXT_LABEL_TYPE.PRIMARY } className="sectionDescriptionBold" style={{color: '#7855FA'}}>{props.name}</TextLabel>
          <img src={Linkedin} />
         </FlexLayout>
         <TextLabel type={ TextLabel.TEXT_LABEL_TYPE.PRIMARY } className="sectionDescriptionArticleDesc" style={{fontStyle: 'italic'}}>{props.position}</TextLabel>
         </FlexLayout>

         <Paragraph className="sectionDescriptionSmallBold">{props.question}</Paragraph>
         <Paragraph className="sectionDescriptionSmall">{props.fullAnswer}</Paragraph>
         </FlexLayout>
       </Modal>



  </FlexLayout>
);


}
