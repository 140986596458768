import React from 'react';
import './Careers.css';
import  ArrowImage from '../images/arrow.svg';
import MobileCareerLadder from '../components/MobileCareerLadder';
import MobileSideQuestCard from '../components/MobileSideQuestCard';
import courseImage from '../images/courseImage.svg';
import teamWorkshopImage from '../images/teamWorkshopsImage.svg';
import hackathonImage from '../images/hackathonImage.svg';
import conferenceImage from '../images/conferenceImage.svg';
import {
  FlexLayout,
  Paragraph,
  Button,
  Title
} from '@nutanix-ui/prism-reactjs';

export default function MobileGrowth() {




return (
  <FlexLayout flexDirection="column">
    <Title className="sectionTitle" style={{marginBottom: '45px'}}> Growth at Work</Title>
    <MobileCareerLadder />


   <Paragraph className="sectionTitle" style={{marginTop: '70px'}}>Side Quests</Paragraph>
   <Paragraph className="sectionDescription">Good UX involves collaboration and continuous learning. We support our designers in their personal and professional growth on our journey to building better products.</Paragraph>
   <Paragraph className="sectionDescription">We also encourage them to upskill and form a strong network of support and constant development.</Paragraph>
     <MobileSideQuestCard image={courseImage} title="Courses & subscriptions" description="Level yourself up with free e-learning subscriptions covering topics like design, development, or management."/>
<MobileSideQuestCard image={teamWorkshopImage} title="Team Workshops" description="Participate in facilitated discussions, share new ideas and learn from your colleagues."/>
<MobileSideQuestCard image={conferenceImage} title="Conferences" description="Be part of the design community, learn or even actively talk about design growth and impact, and network with peers."/>
<MobileSideQuestCard image={hackathonImage} title="Hackathons" description="Create novel solutions in fast sprints together with engineering and Product Management stakeholders."/>





  </FlexLayout>
);


}
