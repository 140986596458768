import React from 'react';
import '../App.css';
import { NavLink, withRouter, useLocation } from "react-router-dom";

import { scroller } from "react-scroll";
import Nav from '../Nav';
import MobileSkillTabs from '../components/MobileSkillTabs';
import ThoughtsCards from '../components/ThoughtsCards';
import JackImage from '../images/jack.svg';
import NatashaImage from '../images/natasha.svg';
import SumitImage from '../images/sumit.svg';
import AromalImage from '../images/aromal.svg';
import JoinUs from './MobileSkillsJoinUs';
import {
  Link,
  FlexItem,
  StackingLayout,
  FlexLayout,
  Paragraph,
  Button,
  Title
} from '@nutanix-ui/prism-reactjs';


const MobileSkills = (props) => {
  let location = useLocation();
  let value = location.state ? location.state : '1';

 const scrollToRoles = () => {
   props.history.goBack();
   window.scroller = scroller;
   setTimeout(() => {
     scroller.scrollTo('rolesPage', { duration: 1500,
 delay: 0,
 smooth: true,
 containerId: 'containerElement',
 offset: 50, });
}, 50);

}


return (
  <StackingLayout>

  <FlexLayout flexDirection="column" className="mobileSkillsContainer" itemSpacing="0px" style={{overflow: 'scroll', height: '100vh'}}>

     <FlexLayout flexDirection="column" style={{background: '#000'}}>
        <Link type="backward" id="backLink" onClick={scrollToRoles}>We have many talents</Link>
       <MobileSkillTabs activekey={value} />

      </FlexLayout>
      <FlexLayout flexDirection="column" style={{ padding: '50px 16px 0px'}}>
       <Title className="sectionTitle">Thoughts from fellow designers</Title>
       <Paragraph className="sectionDescription">Hear what some of our designers have to say about their experience of working at Nutanix. Hear what some of our designers have to say about their experience.</Paragraph>
      </FlexLayout>

<FlexLayout flexDirection="column" justifyContent="center"style={{padding: '50px 20px 70px', background: '#F6F6F6'}}>


<div className="mobileThoughtsTile"><ThoughtsCards  image={SumitImage} name="Sumit Dutta" position="Senior UX Researcher" href="https://www.linkedin.com/in/sumitduttauxanthropologist/" question="How do you define your research goals?" answer={<React.Fragment><span className="sectionDescriptionSmall">I define these goals based on what my team members are hoping to learn from specific research. These goals then help me formulate the right research questions that would eventually bring in answers</span></React.Fragment>} /></div>

<div className="mobileThoughtsTile"><ThoughtsCards image={AromalImage} name="V Aromal Ram" position="Designer 4" href="https://www.linkedin.com/in/aromalram/" question="How do you conceptualize when faced with a complex problem?" answer={<React.Fragment><span className="sectionDescriptionSmall">When faced with a complex problem, I try to break it down. This helps in understanding the various nuances at play. It's more like lego - where you understand the individual pieces by playing around and then you try piecing it together</span></React.Fragment>}/></div>

<div className="mobileThoughtsTile"><ThoughtsCards  image={SumitImage} name="Sumit Dutta" position="Senior UX Researcher" href="https://www.linkedin.com/in/rosenthaljacob/" question="What does UX research for enterprise design look like?" answer={<React.Fragment><span className="sectionDescriptionSmall">A researcher needs to familiarize themselves with enterprise specific vocabularies, avoid contacting the enterprise users too often about similar issues. Building relationships and maintaining the rapport with enterprise users also becomes important.</span></React.Fragment>} /></div>


</FlexLayout>
<JoinUs />
  </FlexLayout>
  </StackingLayout>
);


}

export default withRouter(MobileSkills);
