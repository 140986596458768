import React, {useState} from 'react';
import './App.css';
import  ArrowImage from './images/arrow.svg';
import  IntroImage from './images/introImage.svg';
import ArticleTile from './components/ArticleTiles';
import {
  FlexLayout,
  Paragraph,
  Button,
  Title
} from '@nutanix-ui/prism-reactjs';

export default function MobileTales(props) {



return (
  <FlexLayout flexDirection="column" itemSpacing="20px">

      <Title className="darkSectionTitle" >Tales from the field</Title>
      <Paragraph className="darkSectionDescription"  style={{paddingBottom: '20px'}}>We share our thoughts on design with the community. Explore selected insights on the craft of design, case studies, and experiences from fellow designers at Nutanix
   </Paragraph>

   {props.articles.map((article) => (
       <ArticleTile key={article.link} title={article.title} description={article.content} link={article.link}/>
   ))}

  </FlexLayout>
);


}
