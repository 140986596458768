import { createStore } from 'redux';
import defaultState from './defaultState';
import resetState from './resetState';
import { localStorageProp } from '../data/initialValues';

let stateFromLocalStorage = JSON.parse(localStorage.getItem(localStorageProp)) || {};
if (!stateFromLocalStorage.v || stateFromLocalStorage.v !== defaultState.v) {
  localStorage.removeItem(localStorageProp);
  stateFromLocalStorage = {};
}
const initialState = Object.assign({}, defaultState, stateFromLocalStorage, resetState);

function appReducer(state = initialState, action) {
  switch (action.type) {
    case 'SET_THEME':
      return Object.assign({}, state, {
        theme: action.theme
      });
    case 'SET_PAGE':
        return Object.assign({}, state, {
          page: action.value
      });
   case 'SET_TASK':
          return Object.assign({}, state, {
            task: action.value
      });
   case 'LAST_TASK':
         return Object.assign({}, state, {
            isLastTask: action.value
         });
    case 'SET_MAINTENANCE':
      return Object.assign({}, state, {
        inMaintenance: action.maintenance,
        isLoading: false
      });
    case 'SET_DATA':
      return Object.assign({}, state, {
        data: action.data
      });
    case 'LOCK_MENU':
      return Object.assign({}, state, {
        isLocked: action.isLocked
      });
    case 'SET_MENU_TYPE':
      return Object.assign({}, state, {
        menuType: action.value
      });
    case 'SET_STUDY_LOADING':
      return Object.assign({}, state, {
        studyLoading: action.loading
      });
    case 'SET_STUDY_ACTIVE':
      return Object.assign({}, state, {
        studyActive: action.active
      });
    case 'SET_STUDY_ACTIVE_LEVELS':
      return Object.assign({}, state, {
        studyActiveLevels: Object.assign({}, state.studyActiveLevels, {
          [action.level]: action.active
        })
      });
    case 'INCREMENT_STUDY_BACKED':
      return Object.assign({}, state, {
        study: Object.assign({}, state.study, {
          backed: state.study.backed + 1
        })
      });
    case 'SET_STUDY_ID':
      return Object.assign({}, state, {
        study: Object.assign({}, state.study, {
          id: action.id
        })
      });
    case 'SET_STUDY_TIMESTAMP':
      return Object.assign({}, state, {
        study: Object.assign({}, state.study, {
          timestamps: Object.assign({}, state.study.timestamps, {
            [action.timestamp.name]: action.timestamp.value
          })
        })
      });
    case 'SET_STUDY_EMAIL':
      return Object.assign({}, state, {
        study: Object.assign({}, state.study, {
          email: action.email
        })
      });
    case 'SET_STUDY_FIRST_NAME':
      return Object.assign({}, state, {
        study: Object.assign({}, state.study, {
          firstName: action.firstName
        })
      });
    case 'SET_STUDY_LAST_NAME':
      return Object.assign({}, state, {
        study: Object.assign({}, state.study, {
          lastName: action.lastName
        })
      });
    case 'SET_STUDY_STEPS_INDEX':
      return Object.assign({}, state, {
        studyActiveLevels: Object.assign({}, state.studyActiveLevels, {
          stepIndex: action.stepIndex
        })
      });
    case 'SET_STUDY_STEPS_REDUCED':
      return Object.assign({}, state, {
        studyActiveLevels: Object.assign({}, state.studyActiveLevels, {
          stepsReduced: action.stepsReduced
        })
      });
    case 'SET_STUDY_SURVEY_PAGE_INDEX':
      return Object.assign({}, state, {
        studyActiveLevels: Object.assign({}, state.studyActiveLevels, {
          surveyPageIndex: action.surveyPageIndex
        })
      });
    case 'INCREMENT_STUDY_STEPS_HINT':
      const { hintIndex } = action;
      const currentHintAnswer = state.study.stepsAnswers[hintIndex];

      return Object.assign({}, state, {
        study: Object.assign({}, state.study, {
          stepsAnswers: [
            ...state.study.stepsAnswers.slice(0, hintIndex),
            Object.assign({}, currentHintAnswer, {
              hint: currentHintAnswer.hint + 1
            }),
            ...state.study.stepsAnswers.slice(hintIndex + 1)
          ]
        })
      });
    case 'SET_STUDY_STEPS_ANSWER':
      const { stepIndex } = action;
      const currentAnswer = state.study.stepsAnswers[stepIndex];

      return Object.assign({}, state, {
        study: Object.assign({}, state.study, {
          stepsAnswers: [
            ...state.study.stepsAnswers.slice(0, stepIndex),
            Object.assign({}, currentAnswer, {
              answer: action.answerIndex
            }),
            ...state.study.stepsAnswers.slice(stepIndex + 1)
          ]
        })
      });
    case 'SET_STUDY_SURVEY_ANSWER':
      const surveyAnswers = state.study.surveyAnswers;
      const { surveyPageIndex } = state.studyActiveLevels;
      const { questionIndex } = action;
      const answersGroup = surveyAnswers[surveyPageIndex];
      const firstSurveySlice = answersGroup.slice(0, questionIndex);
      const currentStudyAnswer = answersGroup[questionIndex];

      return Object.assign({}, state, {
        study: Object.assign({}, state.study, {
          surveyAnswers: [
            ...surveyAnswers.slice(0, surveyPageIndex),
            [
              ...firstSurveySlice,
              Object.assign({}, currentStudyAnswer, {
                answer: action.answerIndex
              }),
              ...answersGroup.slice(questionIndex + 1)
            ],
            ...surveyAnswers.slice(surveyPageIndex + 1)
          ]
        })
      });
    case 'INCREMENT_COUNTER':
       localStorage.setItem("workflowCount", initialState.workflowCount + 1);
       return {
           ...state,
           workflowCount: initialState.workflowCount + 1 // Increment the counter from the previous state
         };

    case 'RESET':
      initialState.workflowCount = localStorage.getItem('workflowCount');
      return Object.assign({}, initialState, defaultState);
    default:
      return state;
  }
}

const store = createStore(appReducer);

export default store;
