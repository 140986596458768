import React, {useState, useEffect} from 'react';
import '../App.css';
import $ from 'jquery';
import GamesImage from '../images/games.svg';
import RecognitionImage from '../images/recognition.svg';
import InspirationImage from '../images/inspiration.svg';
import UpskillingImage from '../images/upskilling.svg';
import CocreationImage from '../images/cocreation.svg';
import {
  Link,
  FlexItem,
  FlexLayout,
  Accordion,
  ChevronDownIcon,
  Paragraph,
  Title
} from '@nutanix-ui/prism-reactjs';


export default function MobileGrowth() {


return (
  <FlexLayout flexDirection="column" itemSpacing="20px">
    <Title className="darkSectionTitle">Growth comes from within</Title>
    <Paragraph className="darkSectionDescription">While external sources and inspiration is essential for our work, there is nothing quite like supporting and fostering the growth of our fellow designers.
   </Paragraph>
   <FlexLayout flexDirection="column" itemSpacing="10px">
   <Accordion
  title=""
  className="mobileGrowthAccordion"
  titleProps={ { className: 'sectionDescriptionBold' } }
  renderToggleItem={ (props) => (
  <FlexLayout alignItems="center">
  <img src={GamesImage}/>
  <Title className="basicCardTitle"> Fun & Games</Title>
  <Link { ...props }>
  <ChevronDownIcon style={{ color: '#AC9BFD'}} className="chevronDown"/>
  </Link>

  </FlexLayout>
) }
>
  <Paragraph forceMultiLineHeight={ true } className="darkSectionDescription growthAnswers">
  We frequently meet to unwind with music, games, and laughter that keeps us energized and connected in our hybrid work environment
  </Paragraph>
</Accordion>
<Accordion
title=""
className="mobileGrowthAccordion"
titleProps={ { className: 'sectionDescriptionBold' } }
renderToggleItem={ (props) => (
<FlexLayout alignItems="center">
<img src={RecognitionImage}/>
<Title className="basicCardTitle"> Recognizing Peers </Title>
<Link { ...props }>
<ChevronDownIcon style={{color: '#AC9BFD'}}/>
</Link>

</FlexLayout>
) }
>
<Paragraph forceMultiLineHeight={ true } className="darkSectionDescription growthAnswers">
 Expressing gratitude is a big part of how we collaborate. We appreciate our colleagues with excellence awards that are often given across teams.
</Paragraph>
</Accordion>
<Accordion
title=""
className="mobileGrowthAccordion"
titleProps={ { className: 'sectionDescriptionBold' } }
renderToggleItem={ (props) => (
<FlexLayout alignItems="center">
<img src={InspirationImage}/>
<Title className="basicCardTitle"> Sharing Inspiration </Title>
<Link { ...props }>
<ChevronDownIcon style={{ color: '#AC9BFD'}}/>
</Link>

</FlexLayout>
) }
>
<Paragraph forceMultiLineHeight={ true } className="darkSectionDescription growthAnswers">
We enjoy sharing stories, anecdotes, and our inspirations with each other. These are catalysts for engaging conversations and keeping that creative spark flaring.
</Paragraph>
</Accordion>
<Accordion
title=""
className="mobileGrowthAccordion"
titleProps={ { className: 'sectionDescriptionBold' } }
renderToggleItem={ (props) => (
<FlexLayout alignItems="center">
<img src={CocreationImage}/>
<Title className="basicCardTitle"> Co-creation sessions </Title>
<Link { ...props }>
<ChevronDownIcon style={{ color: '#AC9BFD'}}/>
</Link>

</FlexLayout>
) }
>
<Paragraph forceMultiLineHeight={ true } className="darkSectionDescription growthAnswers">
We meet every week to brainstorm, ideate, and think out loud about a particular problem statement presented by any designer on the team

</Paragraph>
</Accordion>
<Accordion
title=""
className="mobileGrowthAccordion"
titleProps={ { className: 'sectionDescriptionBold' } }
renderToggleItem={ (props) => (
<FlexLayout alignItems="center">
<img src={UpskillingImage}/>
<Title className="basicCardTitle"> Workshops </Title>
<Link { ...props }>
<ChevronDownIcon style={{ color: '#AC9BFD'}}/>
</Link>

</FlexLayout>
) }
>
<Paragraph forceMultiLineHeight={ true } className="darkSectionDescription growthAnswers">
 Along with learning on the job, we are encouraged to hone our skills with formal courses and workshops
</Paragraph>
</Accordion>


</FlexLayout>

  </FlexLayout>
);


}
