import React from 'react';
import './Main.css';
import {
  Link,
  FlexLayout,
  Paragraph,
  Button,
  Title
} from '@nutanix-ui/prism-reactjs';

import ArtefactsImage from './images/artefactsImage.svg';
import IllustrationsImage from './images/illustrationsArtefacts.svg';
import VisualImage from './images/visualArtefacts.svg';
import ContentImage from './images/contentArtefacts.svg';
import ArrowImage from '../images/lightPurpleArrow.svg';


export default function MobileArtefacts() {



return (
  <FlexLayout flexDirection="column" itemSpacing="20px">
    <Title className="darkSectionTitle"> Our Libraries</Title>

    <FlexLayout flexDirection="column" className="artefactMobileCards">
      <img src={ArtefactsImage}/>
      <FlexLayout flexDirection="column">
      <Title className="darkSectionDescriptionBoldLarge" style={{color: '#FFFFFF'}}>UI Component Library</Title>
      <Paragraph className="darkSectionDescription">Our UI components are the building blocks we use to create a consistent, high-quality experience across our 20+ product portfolio.
They are solid yet flexible, constantly evolving, and allow us to iterate fast.</Paragraph>
      <FlexLayout itemSpacing="10px">
      <Link href="https://ntnx-intranet--simpplr.vf.force.com/apex/simpplr__app?u=/site/a0x5d00000B4VTCAA3/content" target="_blank" className="linkLarge">User Interface Guidelines</Link>
      <img src={ArrowImage} style={{width: '16px', height: '16px'}}/>
      </FlexLayout>
      </FlexLayout>
    </FlexLayout>
    <FlexLayout flexDirection="column" className="artefactMobileCards">
      <img src={IllustrationsImage}/>
      <FlexLayout flexDirection="column">
      <Title className="darkSectionDescriptionBoldLarge" style={{color: '#FFFFFF'}}>Illustration System</Title>
      <Paragraph className="darkSectionDescription">Illustrations can simplify complex ideas and add an element of delight for customers. Our flexible illustration library allows us to build complex custom illustrations and caters to both, product and marketing purposes.</Paragraph>
      <FlexLayout itemSpacing="10px">
      <Link href="https://nutanix.design/illustrations/#/home/product/empty_states" target="_blank" className="linkLarge">Illustration Guidelines</Link>
      <img src={ArrowImage} style={{width: '16px', height: '16px'}}/>
      </FlexLayout>
      </FlexLayout>
    </FlexLayout>
    <FlexLayout flexDirection="column" className="artefactMobileCards">
      <img src={VisualImage}/>
     <FlexLayout flexDirection="column">
      <Title className="darkSectionDescriptionBoldLarge"  style={{color: '#FFFFFF'}}>Dataviz System</Title>
      <Paragraph className="darkSectionDescription">Displaying data helps our customers better understand their infrastructure and therefore is an integral part of our work. Our customisable library helps us meet our requirements by allowing us to display data in various formats.</Paragraph>
      <Button style={{alignSelf: 'flex-start'}} className="artefactsButton">Coming Soon !</Button>
      </FlexLayout>
    </FlexLayout>
    <FlexLayout flexDirection="column" className="artefactMobileCards">
      <img src={ContentImage}/>
      <FlexLayout flexDirection="column">
      <Title className="darkSectionDescriptionBoldLarge" style={{color: '#FFFFFF'}}>Content System</Title>
      <Paragraph className="darkSectionDescription">Our content is the stepping stone for helping users understand a problem and accomplish a task.
As designers, these guidelines help us shape experiences with our brand voice and also ensure uniformity in terminology, structure.</Paragraph>
      <FlexLayout itemSpacing="10px">
      <Link href="https://ntnx-intranet--simpplr.vf.force.com/apex/simpplr__app?u=/site/a0x5d00000B4VTCAA3/page/a0v5d00000Jx0ksAAB" target="_blank" className="linkLarge">Content Guidelines</Link>
      <img src={ArrowImage} style={{width: '16px', height: '16px'}}/>
      </FlexLayout>
      </FlexLayout>
    </FlexLayout>




  </FlexLayout>
);


}
